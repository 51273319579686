import "./form_input.css"

function FormHint({hide = false, warning = false, children}) {
    if (hide) {
        return (<></>);
    }
    else {
        return (
            <div className={"depr-form-input-validation-hint" + (warning? " warning" : "")}>
                 {children}
            </div>
        );
    }
}

function CheckboxHint({hide = false, warning = false, children}) {
    if (hide) {
        return (<></>);
    }
    else {
        return (
            <div className={"depr-form-input-checkbox-invalid-hint" + (warning? " warning" : "")}>
                 {children}
            </div>
        );
    }
}

export const DefaultInput = ({
    type, 
    style, 
    id,  
    validationOverwrite = null,
    validationMethod = null, 
    validationHint = null, 
    validationShowAsWarning = false, 
    autoComplete, 
    onChange, 
    value, 
    placeholder}) => 
{
    const valid = validationOverwrite === null ?
          validationMethod !== null ? validationMethod(value) : true
        : validationOverwrite;
    const hint = valid ? 
          (<></>)
        : (<FormHint warning={validationShowAsWarning}>{validationHint(value)}</FormHint>);
    return( 
    <div style={{width: '100%', ...style}}>
        <input
            className={ valid ? "depr-default" : (validationShowAsWarning ? "depr-default depr-invalid warning" : "depr-default depr-invalid") }
            type={type} 
            style={{width: '100%', boxSizing: 'border-box'}}
            id={id}
            autoComplete={autoComplete? autoComplete : "on"}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            required/>
        {hint}
    </div>)
}

export const SlimInput = ({
    type, 
    style, 
    id,  
    validationOverwrite = null,
    validationMethod = null, 
    validationHint = null, 
    validationShowAsWarning = false, 
    autoComplete, 
    onChange, 
    value, 
    placeholder}) => 
{
    const valid = validationOverwrite === null ?
          validationMethod !== null ? validationMethod(value) : true
        : validationOverwrite;
    const hint = valid ? 
          (<></>)
        : (<FormHint warning={validationShowAsWarning}>{validationHint(value)}</FormHint>);
    return( 
    <div style={{width: '100%', ...style}}>
        <input
            className={ valid ? "depr-slim" : (validationShowAsWarning ? "depr-slim depr-invalid warning" : "depr-slim depr-invalid") }
            type={type} 
            style={{width: '100%', boxSizing: 'border-box'}}
            id={id}
            autoComplete={autoComplete? autoComplete : "on"}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            required/>
        {hint}
    </div>)
}

export const CheckboxInput = ({
    style, 
    id, 
    name, 
    checked, 
    invalid = false, 
    invalidText = null, 
    invalidWarning = false, 
    onChange, 
    children,
    hideLabel = false
}) => {
    const hint = (!invalid || !invalidText) ? 
          (<></>)
        : (<CheckboxHint warning={invalidWarning}>{invalidText}</CheckboxHint>);
    const label = hideLabel ? <></> : 
        <label style={{userSelect: "none", width: "100%", marginTop: "0.1em"}} htmlFor={id}>
            {children}
            {hint}
        </label>;
    return(
        <div className="depr-form-input-checkbox" style={{width: '100%', ...style}}>
            <input 
                type="checkbox"
                className={(checked ? "checked" : "") + (invalid ? " depr-invalid" + (invalidWarning ? " warning" : "") : "")}
                id={id}
                name={name}
                checked={checked}
                onChange={onChange}/>
            {label}
        </div>
    )
}