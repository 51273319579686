import { postTrackingEvent, postTrackingHeartbeat, postTrackingEventTimer } from "../api/analytics";
import { AB_TEST_PLANS_VARIATIONS, AB_TEST_VERSION_MAP } from "../constants/analytics";
import { uuid4 } from "../utils/uuid4";

class AnalyticsContext {
    constructor(){
        this._session_id = uuid4();
        this._ab_testing = { plans: Math.floor(Math.random() * AB_TEST_PLANS_VARIATIONS.length) };
        this._heartbeats = {};
    }

    #heartbeat(event_id, timestamp) {
        const event = {
            session_id: this._session_id,
            event_id:  event_id,
            timestamp: timestamp
        }
        const id = event_id + timestamp.toString();
        const close = () => {
            if(this._heartbeats.hasOwnProperty(id)){
                clearInterval(this._heartbeats[id].interval)
                delete this._heartbeats[id];
            }
        };
        postTrackingHeartbeat(event, () => {}, close);
    }

    #stop_event_timer(id){
        if(this._heartbeats.hasOwnProperty(id)){
            if(this._heartbeats[id].hasOwnProperty("interval")){
                clearInterval(this._heartbeats[id].interval)
                this._heartbeats[id].heartbeat();
                delete this._heartbeats[id];
            }
            else
                this._heartbeats[id] = false;
        }
    }

    event(event_id, custom_data = null, responseHandler = () => {})
    {
        postTrackingEvent(
            {
                session_id: this._session_id,
                event_id,
                timestamp: new Date().toISOString(),
                custom_data
            }, () => {}, () => {}, responseHandler);
    }

    get_event_timer(interval, event_id, custom_data = null, add_ab_version = false)
    {
        const timestamp = new Date().toISOString();
        const id = event_id + timestamp;
        const ab_version = add_ab_version ? { ABTestVersion: this._ab_testing.plans } : null
        const start = () => {
            if(this._heartbeats[id])
                return;
            const event = {
                session_id: this._session_id,
                event_id,
                timestamp: timestamp,
                custom_data: { ...custom_data, ...ab_version, interval: interval, }
            }
            const interval_func = () => this.#heartbeat(event_id, timestamp);    
            const start_timer = () => {
                if(!this._heartbeats[id]){
                    delete this._heartbeats[id];
                    return;
                }
                this._heartbeats[id] = {
                        heartbeat: interval_func,
                        interval: setInterval(interval_func, 1000 * interval)
                    };
            };
            this._heartbeats[id] = true;
            postTrackingEventTimer(event, start_timer);
        }
        const stop = () => this.#stop_event_timer(id);
        return { start, stop };
    }

    get_AB_testing_id(){
        return this._ab_testing;
    }

    set_AB_testing_id(id){
        if(AB_TEST_VERSION_MAP.hasOwnProperty(id))
            this._ab_testing = AB_TEST_VERSION_MAP[id];
    }
}

export const analyticsContext = new AnalyticsContext();