import { store } from "../store/store";
import { setAccessToken, setFocusedContext, setOpenContexts, setPermissions, setRequiredActions } from "../store/slices/session";
import { PAGE_ROUTES } from "../constants/page_routes";
import { CONTEXT_DESC, CONTEXT_ID } from "../constants/context";
import { resetHub } from "../store/slices/hub";
import { webPlayerContext } from "./web_player";
import { resetWebPlayer } from "../store/slices/web_player";
import { apiContext } from "./api";
import { resetMaintenance } from "../store/slices/maintenance";

class SessionContext {
    constructor() {
        this._navigate = null;
    }

    setNavigate(navigate) {
        this._navigate = navigate;
    }

    getNavigate() {
        return this._navigate;
    }

    expireSession() {
        this.loggedOut(this.getNavigate());
    }

    fatalFailure() {
        const navigate = this.getNavigate();
        if (navigate) {
            this.loggedOut(navigate);
            navigate(PAGE_ROUTES.status503);
        }
    }

    openContext(context_id, target = null) {
        const openContexts = structuredClone(store.getState().session.openContexts);
        openContexts[context_id] = {
            id: context_id,
            name: CONTEXT_DESC[context_id].name,
            title: CONTEXT_DESC[context_id].defaultTitle,
            target: target === null ? CONTEXT_DESC[context_id].defaultNav : target
        };
        store.dispatch(setOpenContexts(openContexts));
    }

    openContextIfNotOpened(context_id, target = null) {
        const openContexts = structuredClone(store.getState().session.openContexts);
        if (context_id in openContexts) {
            return;
        }
        openContexts[context_id] = {
            id: context_id,
            name: CONTEXT_DESC[context_id].name,
            title: CONTEXT_DESC[context_id].defaultTitle,
            thumbnail: '',
            target: target === null ? CONTEXT_DESC[context_id].defaultNav : target
        };
        store.dispatch(setOpenContexts(openContexts));
    }

    #setTarget(context_id, target) {
        const openContexts = structuredClone(store.getState().session.openContexts);
        openContexts[context_id].target = target === null ? CONTEXT_DESC[context_id].defaultNav : target;
        store.dispatch(setOpenContexts(openContexts));
    }

    setContextTitle(context_id, title) {
        const openContexts = structuredClone(store.getState().session.openContexts);
        if (!(context_id in openContexts))
            return;
        openContexts[context_id].title = title;
        store.dispatch(setOpenContexts(openContexts));
    }

    setContextThumbnail(context_id, thumbnail) {
        const openContexts = structuredClone(store.getState().session.openContexts);
        if (!(context_id in openContexts))
            return;
        openContexts[context_id].thumbnail = thumbnail;
        store.dispatch(setOpenContexts(openContexts));
    }

    focusContext(context_id, target = null) {
        if (context_id !== null) {
            const openContexts = structuredClone(store.getState().session.openContexts);
            if (!(context_id in openContexts)) {
                this.openContext(context_id, target);
            }
            else {
                this.#setTarget(context_id, target);
            }
        }
        store.dispatch(setFocusedContext(context_id));
    }

    #exitContext(context) {
        if (context.id === CONTEXT_ID.hub) {
            store.dispatch(resetHub());
        }
        else if (context.id === CONTEXT_ID.maintainance) {
            store.dispatch(resetMaintenance());
        }
        else if (context.id === CONTEXT_ID.webPlayer) {
            webPlayerContext.stop();
            store.dispatch(resetWebPlayer());
        }
    }

    closeContext(context_id) {
        const openContexts = structuredClone(store.getState().session.openContexts);
        if (context_id in openContexts) {
            this.#exitContext(openContexts[context_id]);
            delete openContexts[context_id];
        }
        store.dispatch(setOpenContexts(openContexts));
    }

    closeAllContexts() {
        const openContexts = structuredClone(store.getState().session.openContexts);
        for (let i in openContexts) {
            this.#exitContext(openContexts[i]);
        }
        store.dispatch(setOpenContexts({ }));
    }
    
    login(access_token, required_actions) {
        const pl = this.#decodeAccessTokenPayload(access_token);
        store.dispatch(setAccessToken(access_token));
        store.dispatch(setPermissions(pl.perm));
        store.dispatch(setRequiredActions(required_actions));
        apiContext.beginHeartbeat(this.expireSession);
    }

    loggedOut(navigate) {
        this.closeAllContexts();
        store.dispatch(setAccessToken(""));
        store.dispatch(setPermissions([]));
        store.dispatch(setRequiredActions([]));
        apiContext.stopHeartbeat();
        navigate(PAGE_ROUTES.login);
    }

    refresh(access_token, required_actions) {
        const pl = this.#decodeAccessTokenPayload(access_token);
        store.dispatch(setAccessToken(access_token));
        store.dispatch(setPermissions(pl.perm));
        store.dispatch(setRequiredActions(required_actions));
    }
    
    upgradeToken(access_token) {
        const pl = this.#decodeAccessTokenPayload(access_token);
        store.dispatch(setAccessToken(access_token));
        store.dispatch(setPermissions(pl.perm));
    }

    #decodeAccessTokenPayload(access_token) {
        const payload_part = access_token.split('.')[1];
        return JSON.parse(atob(payload_part));
    }
}

export const sessionContext = new SessionContext();