export function compareRule(a, b) {
    for (let i = 0; i < a.state.length; ++i) {
        const va = a.state[i];
        const vb = b.state[i];
        if (va === null && vb === null)
            continue;
        else if (va === null)
            return 1;
        else if (vb === null)
            return -1;
        else if (va[0] !== vb[0])
            return va[0] - vb[0];
        else if (va.length === 1 && vb.length === 1)
            continue;
        else if (va.length === vb.length) {
            const x = va[1] - vb[1];
            if (x === 0)
                continue;
            else
                return x;
        }
        else if (va.length === 1)
            return 1;
        else
            return -1;
    }
    return 0;
}

export function validateState(s, categories) {
    if (s.length !== categories.length)
        return false;
    for (let i in s) {
        if (s[i] === null)
            continue;
        if (s[i].length < 1 || s[i].length > 2)
            return false;
        if (s[i][0] < 0 || s[i][0] >= categories[i].elements.length)
            return false;
        if (s[i].length === 2) {
            if (s[i][1] < 0 || s[i][1] >= categories[i].elements[s[i][0]].length)
                return false;
        }
    }
    return true;
}

export function validateRule(r, playgraphs, categories) {
    if (r.playgraph < 0 || r.playgraph >= playgraphs.length)
            return false;
        return validateState(r.state, categories);
}

export function matchesRule(state, rule) {
    for (let i = 0; i < state.length; ++i) {
        if (rule[i] === null)
            continue;
        if (rule[i][0] !== state[i][0])
            return false;
        if (rule[i].length === 2 && rule[i][1] !== state[i][1])
            return false;
    }
    return true;
}

/*
function test() {
    let rules = [
        { playgraph: 0, state: [ [1], [1], [0] ] },
        { playgraph: 1, state: [ [1], [0], [0] ] },
        { playgraph: 2, state: [ [0], [0], null ] },
        { playgraph: 3, state: [ [0], [1], null ] },
        { playgraph: 4, state: [ [0,1], [1], [0] ] },
        { playgraph: 5, state: [ [0], [1,1], [0] ] },
        { playgraph: 6, state: [ [0,1], [1,1], null ] },
    ];
    rules.sort(compareRule);
    console.log(rules);
}
test();
*/