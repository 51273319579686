import { compareRule, validateState, validateRule } from "../../atmosphere/rules";

function validate(state) {
    for (let i in state.categories) {
        for (let e in state.categories[i].elements) {
            if (state.categories[i].elements[e].length < 1) {
                throw new Error("invalid category");
            }
        }
    }
    if (!validateState(state.currentState, state.categories)) {
        throw new Error("invalid state");
    }
    for (let i in state.currentState) {
        if (state.currentState[i] === null) {
            throw new Error("state cannot be null");
        }
    }
    for (let i in state.scenes) {
        if (!validateState(state.scenes[i].state, state.categories)) {
            throw new Error("invalid scene");
        }
    }
    if (state.rules.length !== state.playbacks.length) {
        throw new Error("invalid rule set");
    }
    for (let i in state.rules) {
        for (let r in state.rules[i])
        {
            const rule = state.rules[i][r];
            if (!validateRule(rule, state.playgraphs, state.categories)) {
                throw new Error("invalid rule");
            }
        }
    }
}

export function loadAtm1(state, atm) {
    try {
        for (let i in atm.playbacks) {
            state.rules.push([]);
            state.playbacks.push({
                name: atm.playbacks[i],
                volume: 1.0,
                muted: false,
                currentPlaygraph: null,
                metaData: null
            });
        }
        for (let i in atm.categories) {
            const elements = [];
            for (let e in atm.categories[i].elements) {
                elements.push([atm.categories[i].elements[e]]);
            }
            state.categories.push({
                name: atm.categories[i].name,
                elements: elements
            });
        }
        for (let i in atm.initialState) {
            state.currentState.push([atm.initialState[i], 0]);
        }
        for (let i in atm.scenes) {
            const sceneState = [];
            for (let s in atm.scenes[i].state) {
                sceneState.push(atm.scenes[i].state[s] === null ? 
                    null : 
                    [atm.scenes[i].state[s], 0]);
            }
            state.scenes.push({
                name: atm.scenes[i].name,
                state: sceneState
            });
        }
        state.playgraphs = atm.playgraphs;
        for (let i in atm.rules) {
            const rule = atm.rules[i];
            const rs = [];
            for (let s in rule.state) {
                if (rule.state[s] === null)
                    rs.push(null);
                else
                    rs.push([rule.state[s]]);
            }
            state.rules[rule.playback].push({
                playgraph: rule.playgraph,
                state: rs
            });
        }
        validate(state);
        for (let i in state.rules) {
            state.rules[i].sort(compareRule);
        }
    }
    catch (e) {
        state.valid = false;
    }
}

export function loadAtm2(state, atm) {
    try {
        for (let i in atm.pb) {
            state.rules.push([]);
            state.playbacks.push({
                name: atm.pb[i],
                volume: 1.0,
                muted: false,
                currentPlaygraph: null,
                metaData: null
            });
        }
        for (let i in atm.ct) {
            const elements = [];
            for (let e in atm.ct[i].e) {
                if (typeof(atm.ct[i].e[e]) === "string") {
                    elements.push([atm.ct[i].e[e]]);
                }
                else {
                    elements.push(atm.ct[i].e[e]);
                }
            }
            state.categories.push({
                name: atm.ct[i].n,
                elements: elements
            });
        }
        for (let i in atm.is) {
            if (typeof(atm.is[i]) === "number") {
                state.currentState.push([atm.is[i], 0]);
            }
            else {
                state.currentState.push(atm.is[i]);
            }
        }
        for (let i in atm.sc) {
            const sceneState = [];
            for (let s in atm.sc[i].s) {
                if (atm.sc[i].s[s] === null)
                    sceneState.push(null);
                else if (typeof(atm.sc[i].s[s]) === "number")
                    sceneState.push([atm.sc[i].s[s], 0]);
                else 
                    sceneState.push(atm.sc[i].s[s]);
            }
            state.scenes.push({
                name: atm.sc[i].n,
                state: sceneState
            });
        }
        state.playgraphs = atm.pg;
        for (let i in atm.r) {
            const rule = atm.r[i];
            const rs = [];
            for (let s in rule.s) {
                if (rule.s[s] === null)
                    rs.push(null);
                else if (typeof(rule.s[s]) === "number")
                    rs.push([rule.s[s]]);
                else
                    rs.push(rule.s[s]);
            }
            state.rules[rule.pb].push({
                playgraph: rule.pg,
                state: rs
            });
        }
        validate(state);
        for (let i in state.rules) {
            state.rules[i].sort(compareRule);
        }
    }
    catch (e) {
        state.valid = false;
    }
}