import './loading_spinner.css';

export default function LoadingSpinner({ size, thickness = "medium", color = "var(--col-fg-icon)" }) {
    const borderColor = color + " transparent transparent " + color;
    return(
        <div className="loading-spinner" style={{height: size, width: size}}>
            <div style={{borderWidth: thickness, borderColor: borderColor}}/>
            <div style={{borderWidth: thickness, borderColor: borderColor}}/>
        </div>
    );
}
