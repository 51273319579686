import { Info as InfoIcon } from '@phosphor-icons/react';
import { DropdownElement, DropdownBox } from '../dropdown/dropdown';
import { useEffect, useState } from 'react';
import { CheckboxInput } from '../form-input/form_input';
import { DefaultButton } from '../button/button';
import './survey.css';

function InfoElement({elm}) {
    return (
        <div style={{
            color: 'var(--color-foreground-hinted)', 
            font: 'var(--font-body-medium)',
            display: 'flex'
        }}>
            <span><InfoIcon size={16} style={{marginTop: '2px', marginRight: '4px'}}/></span>
            <span>{elm.val}</span>
        </div> 
    );
}

function SelectElement({ elm, vDict, setVDict }) {
    const value = (elm.id in vDict) ? vDict[elm.id] : null;
    const placeholder = value !== null ?
    <span>{value}</span> :
    elm?.placeholder ?
            <span style={{color: 'var(--color-foreground-hinted)'}}>{elm.placeholder}</span> :
            <span style={{color: 'var(--color-foreground-hinted)'}}>Select One</span>;
    const  [open, setOpen] = useState(false);
    const onChildClick = (v) => {
        const newDict = structuredClone(vDict);
        newDict[elm.id] = v;
        setVDict(newDict);    
        setOpen(false);
    };
    const children = elm.val.map((v, i) => (
        <DropdownElement 
            key={i}
            onClick={() => onChildClick(v)}
        >
            {v}
        </DropdownElement>
    ));
    return (
        <DropdownBox text={placeholder} open={open} setOpen={setOpen}>
            {children}
        </DropdownBox>
    );
}

function MultiSelectElement({ elm, vDict, setVDict }) {
    const value = (elm.id in vDict) ? vDict[elm.id] : [];
    const onChildClick = (v) => {
        const nv = value.includes(v) ? 
            value.filter(item => item !== v) :
            [...value];
        if (!value.includes(v)) {
            nv.push(v);
        }        
        const newDict = structuredClone(vDict);
        newDict[elm.id] = nv;
        setVDict(newDict);
    };
    const children = elm.val.map((v, i) =>
        <CheckboxInput
            id={elm.id + i.toString()}
            key={i}
            checked={value.includes(v)}
            onChange={() => onChildClick(v)}
        >
            {v}
        </CheckboxInput>
    );
    return (
        <div>
            {children}
        </div>
    );
}

function SurveyElement({ elm, vDict, setVDict }) {
    if (elm?.elm === 'header' && elm?.val) {
        return <h2 style={{width: '100%', textAlign: 'center'}}>{elm.val}</h2>;
    }
    if (elm?.elm === 'info' && elm?.val) {
        return <InfoElement elm={elm}/>;
    }
    if (elm?.elm === 'separator') {
        return <div style={{flexShrink: 0, width: '100%', height: '1px', margin: '8px 0px', background: 'var(--color-bg-elem-dp2)'}}/>
    }
    if (elm?.elm === 'spacer') {
        return <div style={{flexShrink: 0, height: '10px'}}/>;
    }
    if (elm?.elm === 'text' && elm?.val) {
        return <span>{elm.val}</span>;
    }
    if (elm?.elm === 'select' && elm?.val && elm?.id) {
        return <SelectElement elm={elm} vDict={vDict} setVDict={setVDict}/>;
    }
    if (elm?.elm === 'multi-select' && elm?.val && elm?.id) {
        return <MultiSelectElement elm={elm} vDict={vDict} setVDict={setVDict}/>;
    }

    return <></>;
}

function checkFilled(skeleton, dict) {
    var t = true;
    skeleton.forEach(item => {
        if (item?.elm === 'select' && item?.id && item?.val) {
            if (!(item.id in dict) || !(item.val.includes(dict[item.id]))) {
                t = false;
            }
        }
        else if (item?.elm === 'multi-select' && item?.id && item?.val) {
            if (!(item.id in dict) || dict[item.id].length === 0) {
                t = false;
            }
        }
    });
    return t; 
}

export default function Survey({ skeleton, onSubmit }) {
    const [valueDict, setValueDict] = useState({});
    const [filledOut, setFilledOut] = useState(false);
    const children = skeleton.map((e, i) => <SurveyElement key={i} elm={e} vDict={valueDict} setVDict={setValueDict}/>);
    useEffect(() => {
        const filled = checkFilled(skeleton, valueDict);
        setFilledOut(filled);
    }, [skeleton, valueDict]);
    return (
        <div className='survey custom-scrollbar'>
            {children}
            <div style={{flexGrow: 1}}/>
            <div style={{flexShrink: 0, width: '100%', height: '1px', margin: '8px 0px', background: 'var(--color-bg-elem-dp2)'}}/>
            <span>When you filled out the form and are satisfied with your choices, click on the button below to submit your answers.</span>
            <div style={{flexShrink: 0, height: '10px'}}/>
            <DefaultButton deactivated={!filledOut} onClick={() => onSubmit(valueDict)}>Submit</DefaultButton>
        </div>
    );
}