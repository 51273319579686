import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { setAppSettings, setAppSettingsQueried } from "../../store/slices/session";
import { useEffect } from "react";

export function PersistentClientSideAppSettings() {
    const queried = useSelector(state => state.session.appSettingsQueried);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!queried) {
            const settings = window.localStorage.getItem('appSettings');
            dispatch(setAppSettingsQueried(true));
            if (settings) {
                dispatch(setAppSettings(JSON.parse(settings)));
            }
        }
    }, [queried, dispatch]);
    return <Outlet/>;
}