export function encodePassword(decodedPassword) {
    const bytes = new TextEncoder().encode(decodedPassword);
    const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join("");
    return btoa(binString);
}

export function validateEmail(email) {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) ? true : false;
}

export function validatePasswordRuleConformity(password) {
    return password.length >= 8;
}

export function passwordRuleConformityHint(password) {
    return "Your password must have at least 8 characters.";
}