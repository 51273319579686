import { postHeartbeat } from "../api/account";
import { postPlaytimeToken } from "../api/user";
import { API_HEARTBEAT_INTERVAL, API_POST_PLAYTIME_TOKEN_INTERVAL } from "../constants/api";

class ApiContext {
    constructor() {
        this._heartbeatInterval = null;
        this._playtimeInterval = null;
    }

    #heartbeat(failCallback) {
        postHeartbeat(failCallback);
    }

    beginHeartbeat(failCallback) {
        if (this._heartbeatInterval === null) {
            const interval = this.#heartbeat.bind(failCallback);
            this._heartbeatInterval = setInterval(interval, 1000 * 60 * API_HEARTBEAT_INTERVAL);
            // Instantly send first heartbeat
            interval();
        }
    }

    stopHeartbeat() {
        if (this._heartbeatInterval !== null) {
            clearInterval(this._heartbeatInterval);
            this._heartbeatInterval = null;
        }
    }

    #postPlaytimeToken() {
        postPlaytimeToken();
    }

    beginPostPlaytimeToken() {
        if (this._playtimeInterval === null) {
            const interval = this.#postPlaytimeToken.bind();
            this._playtimeInterval = setInterval(interval, 1000 * 60 * API_POST_PLAYTIME_TOKEN_INTERVAL);
            // Instantly post first playtime token
            interval();
        }
    }

    stopPostPlaytimeToken() {
        if (this._playtimeInterval !== null) {
            clearInterval(this._playtimeInterval);
            this._playtimeInterval = null;
        }
    }
}

export const apiContext = new ApiContext();