import './tab_registers.css';

export function SimpleTabRegister({ 
    style, 
    selected,
    select,
    tabs 
}) {
    const header = tabs.map((e, i) => (
        <div 
            key={i} 
            className={'simple-tab-register-head text-ellipsis' + (selected === i ? ' active' : '')}
            onClick={selected === i ? () => {} : () => { select(i); }}
        >
            { e.header }
        </div>
    ));
    const body = selected !== null && selected >= 0 && selected < tabs.length ?
        tabs[selected].body :
        <></>;
    return (
        <div style={style}>
            <div className='simple-tab-register-header' style={{
                gridTemplateColumns: 'repeat(' + tabs.length + ',1fr)'
            }}>
                {header}
            </div>
            { body }
        </div>
    );
}

const TabRegisters = {
    Simple: SimpleTabRegister
};
export default TabRegisters;