import Header from './deprecated/components/header/header';
import RoutesRoot from './routes/root';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sessionContext } from './contexts/session';
import './app.css';
import FeedbackForm from './components/feedback-form/feedback_form';
import { useSelector } from 'react-redux';

export default function App() {
    const navigate = useNavigate();
    const show_feedback = useSelector((state => state.session.overlayFeedbackForm));
    const form = show_feedback ? <FeedbackForm/> : <></>;

    useEffect(() => {
        // Setup global context
        sessionContext.setNavigate(navigate);
    }, [navigate]);
    
    return (
        <div className='app-client'>
            <div className='header-container'>
                <Header/>
            </div>
            <div className='page-container'>
                {form}            
                <RoutesRoot/>
            </div>
        </div>
    );
}