import { createSlice } from "@reduxjs/toolkit";
import { HUB_PAGES } from "../../constants/hub";

const initialState = {
    'openPage': HUB_PAGES.home.id,
    'atmosphereMeta': {
        'loaded': 0,
        'normal': [],
        'mockup': []
    },
    'newsMeta': null,
    'surveyMeta': null
}

export const hubSlice = createSlice({
    name: 'hub',
    initialState,
    reducers: {
        resetHub: {
            reducer: (state, action) => {
                state.openPage = initialState.openPage;
                state.atmosphereMeta = initialState.atmosphereMeta;
                state.newsMeta = initialState.newsMeta;
                state.surveyMeta = initialState.surveyMeta;
            }
        },
        setOpenPage: {
            reducer: (state, action) => {
                state.openPage = action.payload.id;
            }
        },
        setAtmosphereMeta: {
            reducer: (state, action) => {
                const real = [];
                const mockups = [];
                for(let i in action.payload) {
                    const atm = action.payload[i]
                    if (atm.atm_meta.types.includes('mockup')) {
                        mockups.push(atm);
                    }
                    else {
                        real.push(atm);
                    }
                }
                state.atmosphereMeta = {
                    'loaded': action.payload.length,
                    'normal': real,
                    'mockup': mockups
                };
            }
        },
        setNewsMeta: {
            reducer: (state, action) => {
                state.newsMeta = action.payload;
            }
        },
        setSurveyMeta: {
            reducer: (state, action) => {
                const surveyMeta = { };
                for (let i in action.payload) {
                    const survey = action.payload[i];
                    surveyMeta[survey.survey_id] = survey;
                }
                state.surveyMeta = surveyMeta;
            }
        }
    }
});

export const { 
    resetHub, 
    setOpenPage, 
    setAtmosphereMeta, 
    setNewsMeta,
    setSurveyMeta
} = hubSlice.actions;
export default hubSlice.reducer;
