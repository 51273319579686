import { BACKEND_API_BASE_URL } from "../constants/api";
import { backendApiLoggedIn, backendApiPublic } from "./shared/instances";

export function getImageURL(imagePath) {
    return BACKEND_API_BASE_URL + '/dat/image/' + imagePath;
}

export function getThumbnailURL(imagePath) {
    return BACKEND_API_BASE_URL + '/dat/thumbnail/' + imagePath;
}

export function getAudioMeta(
    sourceId,
    successHandler = (content) => { },
    fileNotFoundHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/dat/audioMeta/' + sourceId,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'file_not_found') {
                    fileNotFoundHandler();
                    return;
                }
                else if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getAudioChunk(
    chunkId,
    successHandler = (content) => { },
    fileNotFoundHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/dat/audioChunk/' + chunkId,
        {
            signal: controller.signal,
            responseType: 'arraybuffer'
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'file_not_found') {
                    fileNotFoundHandler();
                    return;
                }
                else if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getPlaygraph(
    playgraphId,
    successHandler = (content) => { },
    fileNotFoundHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/dat/playgraph/' + playgraphId,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'file_not_found') {
                    fileNotFoundHandler();
                    return;
                }
                else if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getAtmosphereMetaList(
    successHandler = (atmospheres) => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/dat/atmosphereMetaList',
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data.atmospheres); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getAtmosphereMeta(
    atmosphereId,
    successHandler = (atmosphere) => { },
    fileNotFoundHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/dat/atmosphereMeta/' + atmosphereId,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'file_not_found') {
                    fileNotFoundHandler();
                    return;
                }
                else if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getAtmosphere(
    atmosphereId,
    successHandler = (atmosphere) => { },
    fileNotFoundHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/dat/atmosphere/' + atmosphereId,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'file_not_found') {
                    fileNotFoundHandler();
                    return;
                }
                else if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getNewsList(
    timeframeDays,
    successHandler = (news) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiPublic.get(
        '/dat/newsList',
        {
            signal: controller.signal,
            params: { timeframeDays: timeframeDays }
        })
        .then((r) => { successHandler(r.data.news); })
        .catch((error) => {
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function getNews(
    newsId,
    successHandler = (news) => { },
    fileNotFoundHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiPublic.get(
        '/dat/news/' + newsId,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'file_not_found') {
                    fileNotFoundHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}