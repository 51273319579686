import { Route } from "react-router-dom";
import { PAGE_ROUTES } from "../constants/page_routes";
import { AnimatedBackground } from "../components/shared/animated-background/animated_background";
import Footer from "../components/shared/footer/footer";
import LoginPage from "../pages/auth/login/login";
import BasicComponentsPage from "./dev/basic_components";

function Lorem() {
    return (
        <div style={{
            width: 'calc(2 * var(--lay-column-width))',
            margin: 'var(--lay-space-v) auto'
        }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut tellus elementum sagittis vitae et leo duis. Orci sagittis eu volutpat odio facilisis mauris. Sed augue lacus viverra vitae congue eu consequat. Tortor consequat id porta nibh venenatis cras sed felis. Maecenas volutpat blandit aliquam etiam erat velit scelerisque. Ultrices vitae auctor eu augue. Fames ac turpis egestas maecenas pharetra convallis posuere. Risus in hendrerit gravida rutrum quisque. Sem viverra aliquet eget sit amet tellus. Dui nunc mattis enim ut. Dignissim cras tincidunt lobortis feugiat vivamus at augue eget arcu. Penatibus et magnis dis parturient montes. At augue eget arcu dictum varius duis at. Commodo nulla facilisi nullam vehicula. Lobortis mattis aliquam faucibus purus in massa tempor nec feugiat. Proin fermentum leo vel orci porta non pulvinar. In massa tempor nec feugiat nisl. Turpis egestas pretium aenean pharetra. Aliquam purus sit amet luctus venenatis lectus magna fringilla urna.
            Tempus egestas sed sed risus pretium quam. Praesent semper feugiat nibh sed pulvinar proin gravida. Ultricies tristique nulla aliquet enim tortor at. Et ultrices neque ornare aenean. Enim facilisis gravida neque convallis. Adipiscing commodo elit at imperdiet dui accumsan sit amet nulla. Risus in hendrerit gravida rutrum quisque non. In massa tempor nec feugiat nisl. Lacus vestibulum sed arcu non odio euismod. Id eu nisl nunc mi ipsum faucibus. Dui faucibus in ornare quam. Amet massa vitae tortor condimentum lacinia quis vel. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque. Nisl rhoncus mattis rhoncus urna. Libero justo laoreet sit amet cursus sit amet dictum. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl. Ac orci phasellus egestas tellus rutrum tellus pellentesque eu tincidunt.
            Vel orci porta non pulvinar. Facilisis gravida neque convallis a cras. Est ante in nibh mauris. Nec ullamcorper sit amet risus nullam eget. Diam phasellus vestibulum lorem sed risus ultricies tristique. Donec enim diam vulputate ut. Magna fermentum iaculis eu non diam phasellus vestibulum. Sapien pellentesque habitant morbi tristique senectus et netus et malesuada. Eu mi bibendum neque egestas congue quisque egestas diam in. Venenatis cras sed felis eget velit. Dignissim convallis aenean et tortor at risus viverra adipiscing at. Massa tempor nec feugiat nisl pretium fusce id velit ut.
            Eu mi bibendum neque egestas. Nec ultrices dui sapien eget mi proin sed. Tempor commodo ullamcorper a lacus vestibulum. In est ante in nibh mauris cursus mattis molestie a. Maecenas pharetra convallis posuere morbi leo urna. Venenatis cras sed felis eget velit aliquet. Cursus sit amet dictum sit amet justo donec enim diam. Sit amet mauris commodo quis. Sed tempus urna et pharetra pharetra. Dui sapien eget mi proin sed. Odio tempor orci dapibus ultrices. In ornare quam viverra orci sagittis. Tristique senectus et netus et.
            Dictumst quisque sagittis purus sit. A iaculis at erat pellentesque adipiscing commodo elit at. Ut pharetra sit amet aliquam id diam maecenas ultricies. Accumsan lacus vel facilisis volutpat est velit egestas dui. Lorem dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Porta nibh venenatis cras sed. Integer malesuada nunc vel risus commodo. Arcu dictum varius duis at consectetur lorem donec massa. Facilisi morbi tempus iaculis urna id. Nec dui nunc mattis enim. Eget est lorem ipsum dolor sit amet. Sed cras ornare arcu dui vivamus arcu felis bibendum. Consequat interdum varius sit amet. In eu mi bibendum neque egestas. Diam vel quam elementum pulvinar etiam non quam.
        </div>
    );
}

function TestPage() {   
    return (
        <>
            <AnimatedBackground filter='blur(1vh) opacity(20%)' vignette={false}/>
            <div 
                className='custom-scrollbar'
                style={{
                    position: 'absolute', 
                    inset: 0, 
                    display: 'flex', 
                    flexDirection: 'column',
                    overflow: 'auto'
            }}>
                <Lorem/>            
                <Footer/>
            </div>
        </>
    )
}

export const DevRoutes = process.env.NODE_ENV === 'production' ?
    <></>
    :
    (
        <Route path={PAGE_ROUTES.devPrefix}>
            <Route path={'test'} element={<TestPage/>}/>
            <Route path={'login'} element={<LoginPage/>}/>
            <Route path={'components'} element={<BasicComponentsPage/>}/>
        </Route>
    );