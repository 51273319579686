import './tooltip.css';
import { Overlay } from '../overlay/overlay';
import { useState } from 'react';

export function Tooltip({ style, tip, children, position}){
    const [hover, setHover] = useState(false);

    const  anchor = <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => e.preventDefault()}>{children}</div>

    return( 
        <Overlay style={style} anchor={anchor} show={hover} position={position}>
            {tip}
        </Overlay>
    )
}