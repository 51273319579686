import { cmsApi } from "./shared/instances";

export function cmsGetItem(
    model,
    successHandler = (data) => { },
    unknownerrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    cmsApi.get(
        'api/content/item/' + model,
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data); })
        .catch((error) => { unknownerrorHandler(); })
        .finally(finallyHandler);
    return controller;
}