import { CaretRight, Trash } from '@phosphor-icons/react';
import './scene_button.css';

function ElementTag({ element, variation }) {
    return (
        <span className='scene-element-tag'>
            { element + variation}
        </span>
    );
}

export default function SceneButton({ scene, categories, onClick }) {
    const sceneState = scene.state.map((s, i) => {
        if (s === null)
            return null;
        else {
            const element = categories[i].elements[s[0]][0];
            const variation = s[1] === 0 ? '' : ' (' + categories[i].elements[s[0]][s[1]] + ')';
            return <ElementTag key={i} element={element} variation={variation}/>
        }
    }).filter(e => e !== null);
    return (
        <div className='scene-button' onClick={() => onClick(scene.state)}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CaretRight size={18} weight='fill' color='var(--color-primary)'/>
                {scene.name}
            </div>
            <div className='scene-elements'>
                {sceneState}
            </div>
        </div>
    );
}

export function CustomSceneButton({ scene, categories, onClick, idx, onRemove }) {
    const sceneState = scene.state.map((s, i) => {
        if (s === null)
            return null;
        else {
            const element = categories[i].elements[s[0]][0];
            const variation = s[1] === 0 ? '' : ' (' + categories[i].elements[s[0]][s[1]] + ')';
            return <ElementTag key={i} element={element} variation={variation}/>
        }
    }).filter(e => e !== null);

    const removeClick = (e) => {
        e.stopPropagation();
        onRemove(idx);
    };
    const removeButton = (
        <div className='scene-button-remove' onClick={removeClick}>
            <Trash size={18} weight='regular'/>
        </div>
    );

    return (
        <div className='scene-button' onClick={() => onClick(scene.state)}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CaretRight size={18} weight='fill' color='var(--color-primary)'/>
                {scene.name}
                <div style={{flexGrow: 1}}/>
                {removeButton}
            </div>
            <div className='scene-elements'>
                {sceneState}
            </div>
        </div>
    );
}