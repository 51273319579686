import NewsCard from '../news-card/news_card';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './news_container.css';

export default function NewsContainer({news}) {
    const navigate = useNavigate();
    const newsCards = news.map((n, i) => {
        const onClick = (e) => { navigate('/news/' + i); }
        return <NewsCard key={i} news={n} onClick={onClick}/>;
    });
    const scrollRef = useRef();
    const containerRef = useRef();
    const mouseWheelScroll = (e) => {
        if (containerRef.current.clientWidth > scrollRef.current.clientWidth)
            return;
        e.preventDefault();
        if (e.deltaY === 0) {
            return;
        }
        containerRef.current.scrollTo({
            left: containerRef.current.scrollLeft + e.deltaY,
        });
    };
    useEffect(() => {
        const target = scrollRef.current;
        target.addEventListener('wheel', mouseWheelScroll, { passive: false });
        return () => {
            target.removeEventListener('wheel', mouseWheelScroll, { passive: false });
        }
    }, []);
    return (
        <div className='news-container custom-scrollbar-horizontal' ref={containerRef}>
            <div style={{margin: 0, display: 'flex', gap: '16px'}} ref={scrollRef}>
                { newsCards }
            </div>
        </div>
    );
}