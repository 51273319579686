import { store } from "../store/store";
import { resetWebPlayer, setAtmosphereMeta, setAtmosphere, setCurrentState, setVolume, setAudioMetaData, setMuted, setPaused, setStarted, setUserDefinedScenes } from "../store/slices/web_player";
import { PlaybackContext } from "./private/web_playback";
import { findPlaygraphIdx } from "./private/playgraph_selection";
import { audioContext } from "./private/audio_context";
import { apiContext } from "./api";

class WebPlayerContext {
    constructor() {
        this._playbackCtx = null;
    }

    reset() {
        this.stop();
        store.dispatch(resetWebPlayer());
    }
    
    setPaused(paused) {
        if (paused) {
            audioContext.suspend();
        }
        else {
            audioContext.resume();
        }
        store.dispatch(setPaused(paused));
    }

    openAtmosphere(atm_meta, navigate) {
        const webPlayerState = store.getState().webPlayer;
        if (webPlayerState?.atmosphereMeta?.id !== atm_meta.atm_id) {
            this.reset();
            store.dispatch(setAtmosphereMeta(atm_meta));
        }
        navigate('/atmosphere/' + atm_meta.atm_id);
    }

    openAtmosphereFromWebPlayer(atm_meta) {
        this.reset();
        store.dispatch(setAtmosphereMeta(atm_meta));
    }

    loadCustomScenes() {
        const id = store.getState().webPlayer.atmosphere.id;
        if (!id)
            return null;
        const scenes = JSON.parse(window.localStorage.getItem('atmosphere/' + id + '/customScenes'));
        if (scenes === null)
        {
            store.dispatch(setUserDefinedScenes([]));
            return null;
        }
        store.dispatch(setUserDefinedScenes(scenes));
        return scenes;
    }

    setCustomScenes(scenes) {
        const id = store.getState().webPlayer.atmosphere.id;
        if (!id)
            return;
        store.dispatch(setUserDefinedScenes(scenes));
        window.localStorage.setItem('atmosphere/' + id + '/customScenes', JSON.stringify(scenes));
    }

    setAtmosphere(atm) {
        store.dispatch(setAtmosphere(atm));
        const playbacks_length = store.getState().webPlayer.atmosphere.playbacks.length;
        if (this._playbackCtx) {
            this._playbackCtx.reset(playbacks_length);
        }
        else {
            this._playbackCtx = new PlaybackContext();
            this._playbackCtx.setMetaDataChangeCallback(this.#metaDataChangeCallback.bind(this));
            this._playbackCtx.reset(playbacks_length);
        }
    }

    #metaDataChangeCallback(playbackIndex, metaData) {
        store.dispatch(setAudioMetaData({playback: playbackIndex, meta: metaData}));
    }
    
    async start() {
        apiContext.beginPostPlaytimeToken();
        store.dispatch(setStarted(true));
        if (this._playbackCtx) {
            const atmosphere = store.getState().webPlayer.atmosphere;
            for (var i = 0; i < atmosphere.playbacks.length; ++i) {
                const pi = findPlaygraphIdx(atmosphere.currentState, atmosphere.rules[i]);
                this._playbackCtx.setPlaygraph(i, pi !== null ? atmosphere.playgraphs[pi] : null);
            }
            await this._playbackCtx.start();
            this.setPaused(false);
        }
    }

    stop() {
        apiContext.stopPostPlaytimeToken();
        store.dispatch(setStarted(false));
        if (this._playbackCtx) {
            this._playbackCtx.reset(0);
            this._playbackCtx = null;
        }
    }

    updateState(categoryIdx, elementIdx, variationIdx) {
        if (this._playbackCtx) {
            const atmosphere = store.getState().webPlayer.atmosphere;
            const state = structuredClone(atmosphere.currentState);
            if (state[categoryIdx][0] !== elementIdx || state[categoryIdx][1] !== variationIdx) {
                state[categoryIdx][0] = elementIdx;
                state[categoryIdx][1] = variationIdx;
                for (var i = 0; i < atmosphere.playbacks.length; ++i) {
                    const pi = findPlaygraphIdx(state, atmosphere.rules[i]);
                    this._playbackCtx.setPlaygraph(i, pi !== null ? atmosphere.playgraphs[pi] : null);
                }
                store.dispatch(setCurrentState(state));
            }
        }
    }

    setScene(scene_state) {
        if (this._playbackCtx) {
            const atmosphere = store.getState().webPlayer.atmosphere;
            const state = structuredClone(atmosphere.currentState);
            let change = false;
            for (var i = 0; i < scene_state.length; ++i) {
                if (scene_state[i] !== null && 
                    (scene_state[i][0] !== state[i][0] ||
                     scene_state[i][1] !== state[i][1])) {
                    change = true;
                    state[i] = scene_state[i];
                }
            }
            if (change) {
                for (i = 0; i < atmosphere.playbacks.length; ++i) {
                    const pi = findPlaygraphIdx(state, atmosphere.rules[i]);
                    this._playbackCtx.setPlaygraph(i, pi !== null ? atmosphere.playgraphs[pi] : null);
                }
                store.dispatch(setCurrentState(state));
            }
        }
    }

    setVolume(playbackIndex, volume) {
        if (this._playbackCtx === null) {
            return;
        }
        if (playbackIndex === null) {
            this._playbackCtx.setMasterVolume(volume);
        }
        else {
            this._playbackCtx.setPlaybackVolume(playbackIndex, volume);
        }
        store.dispatch(setVolume({ playback: playbackIndex, volume: volume }));
    }

    setMuted(playbackIndex, muted) {
        if (this._playbackCtx === null) {
            return;
        }
        if (playbackIndex === null) {
            this._playbackCtx.setMasterVolume(muted ? 0 : store.getState().webPlayer.mainPlayback.volume);
        }
        else {
            this._playbackCtx.setPlaybackVolume(playbackIndex, muted ? 0 : store.getState().webPlayer.atmosphere.playbacks[playbackIndex].volume);
        }
        store.dispatch(setMuted({ playback: playbackIndex, muted: muted }));
    }
}

export const webPlayerContext = new WebPlayerContext();