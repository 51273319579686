export function extractDateFromDatetime(datetimeStr) {
    let date = new Date(datetimeStr);
    return date.toLocaleDateString();
}

// Encodes URI components and additionally the + sign as well
export function encodeURIComponentEx(str) {
    const encoded = encodeURIComponent(str);
    encoded.replaceAll('+', '%20');
    return encoded;
}

export function encodeBase64Utf8(text) {
    const bytes = new TextEncoder().encode(text);
    const binString = Array.from(bytes, (x) => String.fromCharCode(x)).join("");
    return btoa(binString);
}

// Generates an array containing all trigrams of a given input string.
// A trigram is a substring consisting of three consecutive code points.
// Analyses a simple pattern (simple as in not RegEx) for trigram serach.
//
// Second parameter enables separation by whitespace. Otherwise whitespace
// is regarded as a valid part of trigrams.
//
// Third parameter allows for n-grams with n<3, where words shorter than 3
// code points form one n-gram.
export function generateTrigrams1(str, separateWhitespace = true, enableShortened = true) {
    const words = separateWhitespace ? str.trim().split(/\s+/) : [str];
    const trigrams = [];
    words.forEach(word => {
        if (word.length < 3) {
            if (enableShortened)
                trigrams.push(word);
            return;
        }
        else if (word.length === 3) {
            trigrams.push(word);
            return;
        }
        else {
            for (let i = 0; i < word.length - 2; ++i) {
                trigrams.push(word.substr(i, 3));
            }
        }
    });
    return trigrams;
}

// New generate trigrams function, ignoring whitespace to evaluate order
export function generateTrigrams(str) {
    if (str.length < 3) {
        return [];
    }    
    const trigrams = [];
    for (let i = 0; i < str.length - 2; ++i) {
        trigrams.push(str.substr(i, 3));
    }
    return trigrams;
}

export function generateTrigramPattern(str) {
    return generateTrigrams(str.toLowerCase().replace(/\s+/g, ''));
}