import { SpeakerHigh, SpeakerLow, SpeakerNone, SpeakerX } from '@phosphor-icons/react';
import { useCallback, useEffect, useRef } from 'react';
import './volume_slider.css';

export default function VolumeSlider({ style, muted, setMuted, volume, min=0.0, max=1.0, setVolume }) {
    const trackRef = useRef();
    const sliderRef = useRef();
    const icon = (()=>{
        if (muted)
            return <SpeakerX size={24}/>;
        if (volume === 0)
            return <SpeakerNone size={24}/>;
        if ((volume - min) < (max - volume))
            return <SpeakerLow size={24}/>;
        return <SpeakerHigh size={24}/>;
    })();
    useEffect(() => {
        trackRef.current.style.setProperty('--progress', `${(volume - min) / (max - min) * 100}%`);
    }, [volume, min, max]);
    const onClick = useCallback((e) => {
        setMuted(!muted);
    }, [muted, setMuted]);
    const onChange = useCallback((e) => {
        const v = min + (e.target.value / 100) * (max - min);
        setVolume(v);
    }, [min, max, setVolume]);
    return (
        <div className='volume-slider' style={style}>
            <div className='volume-icon' onClick={onClick}>
                {icon}
            </div>
            <div style={{width: '100%', height: '24px', position: 'relative', display: 'flex'}}>
                <div className='track-container'>
                    <div className={'track' + (muted ? ' muted' : '')} ref={trackRef}/>
                </div>
                <input 
                    type='range' 
                    className={'slider' + (muted ? ' muted' : '')}
                    min='0'
                    max='100'
                    value={(volume - min) / (max - min) * 100}
                    onChange={onChange}
                    ref={sliderRef}
                />
            </div>
        </div>
    ); 
}