import './button.css'

export function DefaultButton({onClick, style, deactivated = false, children}){
    if (deactivated) {
        return (
            <button className="depr-button deactivated" style={style} type="button">{children}</button>
        );
    }
    return (
        <button className="depr-button default" style={style} onClick={onClick}>
            {children}
        </button>
    );
}

export function DefaultButtonDeactivated({style, children}){
    return (
        <button className="depr-button deactivated" style={style} type="button">{children}</button>
    );
}

export function OutlinedButton({onClick, style, deactivated = false, children}){
    if (deactivated)
        return (<button className="depr-button outlined deactivated" style={style} type="button">{children}</button>);
    return (<button className="depr-button outlined" style={style} onClick={onClick}>{children}</button>);
}

export function DangerOutlinedButton({onClick, style, deactivated = false, children}){
    if (deactivated)
        return (<button className="depr-button outlined-err deactivated" style={style} type="button">{children}</button>);
    return (<button className="depr-button outlined-err" style={style} onClick={onClick}>{children}</button>);
}

export function IconButton({onClick, icon, alt}){
    return(
        <div className="depr-button round" onClick={onClick}>
            <img className="icon" src={icon} alt={alt}/>
        </div>
    )
}