import './buttons.css'

function BaseButton({ className, style, onClick, children }) {
    return (
        <button className={className} style={style} onClick={onClick} type="button">
            { children }
        </button>
    );
}

export function PrimaryButton({ style, onClick, active=true, children}) {
    const className = 'base-button primary' + (!active ? ' deactivated' : '');
    return (
        <BaseButton className={className} style={style} onClick={active ? onClick : () => {}}>
            { children }
        </BaseButton>
    ); 
}

export function SecondaryButton({ style, onClick, active=true, children}) {
    const className = 'base-button outlined' + (!active ? ' deactivated' : '');
    return (
        <BaseButton className={className} style={style} onClick={active ? onClick : () => {}}>
            { children }
        </BaseButton>
    ); 
}

export function SubmitButton({ style, onClick, active=true, children}) {
    const className = 'base-button primary' + (!active ? ' deactivated' : '');
    return (
        <button className={className} style={style} onClick={active ? onClick : () => {}} type="submit">
            { children }
        </button>
    ); 
}

const Buttons = {
    Primary: PrimaryButton,
    Secondary: SecondaryButton,
    Submit: SubmitButton
}
export default Buttons;