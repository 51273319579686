import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxInput } from '../../../components/form-input/form_input';
import { DefaultButton } from '../../../components/button/button';
import { setIgnoreDisclaimer } from '../../../../store/slices/maintenance';
import { AnimatedBackground } from '../../../../components/shared/animated-background/animated_background';
import Footer from '../../../../components/shared/footer/footer';
import './maintenance_disclaimer.css';

export default function MaintenanceDisclaimer() {
    const [ignore, setIgnore] = useState(false);
    const ignoreDisclaimer = useSelector(state => state.maintenance.ignoreDisclaimer);
    const dispatch = useDispatch();
    return ignoreDisclaimer ? <Outlet/> :
    (
        <>
            <AnimatedBackground filter='opacity(60%) blur(8px)'/>
            <div className='maintenance-disclaimer-page-container'>
                <div style={{flexGrow: 1}}/>
                <div className='maintenance-disclaimer'>
                    <h4>Beware</h4>
                    <p>
                        You are about to enter maintenance mode.
                        Remember that with great power comes great responsibility.
                    </p>
                    <p style={{color: 'var(--color-warning)'}}>
                        The maintenance interface might allow access to protected data. 
                        Make sure to not share it with unauthorized persons.
                    </p>
                    <div style={{width: '100%', height: '1px', margin: '8px 0px', background: 'var(--color-bg-elem-dp2)'}}/>
                    <form onSubmit={(e) => { e.preventDefault(); dispatch(setIgnoreDisclaimer(ignore))}}>
                        <CheckboxInput id='ignore'
                            checked={ignore}
                            onChange={(e) => setIgnore(!ignore)}
                        >
                            I hereby promise responsibility.
                        </CheckboxInput>
                        <DefaultButton
                            style={{margin: '4px', marginTop: '8px', width: '100%'}}
                            deactivated={!ignore}
                        >
                            Enter
                        </DefaultButton>
                    </form>
                </div>
                <div style={{flexGrow: 2}}/>
                <Footer/>
            </div>
        </>
    );
}