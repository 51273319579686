import './checkbox.css';

export default function Checkbox({
    style,
    id,
    name,
    checked,
    onChange,
    active = true,
    hideLabel = false,
    invalid = false,
    invalidText = null,
    children
}) {
    const invalidHint = !invalid || invalidText === null ? <></> : (
        <div className='checkbox-invalid-label'>
            {invalidText}
        </div>
    ); 
    const label = hideLabel ? <></> : (
        <label className={'checkbox-label' + (active ? '' : ' deactivated')} htmlFor={id}>
            {children}
        </label>
    );
    const checkboxClassName = 
        'checkbox' + (checked ? ' checked' : '') +
        (active ? 
            (invalid ? ' invalid' : '') : 
            ' deactivated');
    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', ...style}}>
                <input
                    className={checkboxClassName}
                    type='checkbox'
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={active ? onChange : () => {}}
                />
                {label}
            </div>
            {invalidHint}
        </div>
    );
}