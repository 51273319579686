import { createSlice } from "@reduxjs/toolkit";
import { loadAtm1, loadAtm2 } from "../../utils/store/web_player/atmosphere_loading";
import { convertCustomSceneToCurrent } from "../../utils/store/web_player/user_defined_scene_conversion";

const initialState = {
    'atmosphereMeta': { },
    'atmosphere': {
        'id': null,
        'valid': null,
        'currentState': [],
        'playbacks': [],
        'categories': [],
        'playgraphs': [],
        'rules': [],
        'scenes': [],
        'userDefinedScenes': []
    },
    'guiState': {
        'openSideMenu': null,
        'showSplash': true,
        'scenesSearchString': '',
        'customScenesSearchString': ''
    },
    'playerState': {
        'started': false,
        'paused': true
    },
    'mainPlayback': {
        'volume': 1.0,
        'muted': false
    }
}

export const webPlayerSlice = createSlice({
    name: 'webPlayer',
    initialState,
    reducers: {
        resetWebPlayer: {
            reducer: (state, action) => {
                state.atmosphereMeta = initialState.atmosphereMeta;
                state.atmosphere = initialState.atmosphere;
                state.guiState = initialState.guiState;
                state.mainPlayback = initialState.mainPlayback;
                state.playerState = initialState.playerState;
            }
        },
        setAtmosphereMeta: {
            reducer: (state, action) => {
                state.atmosphereMeta = {
                    id: action.payload.atm_id,
                    ...action.payload.atm_meta
                };
            }
        },
        setAtmosphere: {
            reducer: (state, action) => {
                state.atmosphere.id = action.payload.id;
                state.atmosphere.valid = true;
                state.atmosphere.currentState = [];
                state.atmosphere.playbacks = [];
                state.atmosphere.categories = [];
                state.atmosphere.playgraphs = [];
                state.atmosphere.rules = [];
                state.atmosphere.scenes = [];
                state.atmosphere.paused = true;
                if (!('sv' in action.payload))
                    loadAtm1(state.atmosphere, action.payload);
                else if (action.payload.sv === 2)
                    loadAtm2(state.atmosphere, action.payload);
                else
                    state.atmosphere.valid = false;
            }    
        },
        setCurrentState: {
            reducer: (state, action) => {
                state.atmosphere.currentState = action.payload;
            }
        },
        setOpenSideMenu: {
            reducer: (state, action) => {
                state.guiState.openSideMenu = action.payload;
            }
        },
        setShowSplash: {
            reducer: (state, action) => {
                state.guiState.showSplash = action.payload;
            }
        },
        setSceneSearchString: {
            reducer: (state, action) => {
                state.guiState.scenesSearchString = action.payload;
            }
        },
        setCustomSceneSearchString: {
            reducer: (state, action) => {
                state.guiState.customScenesSearchString = action.payload;
            }
        },
        setVolume: {
            reducer: (state, action) => {
                if (action.payload.playback !== null) {
                    state.atmosphere.playbacks[action.payload.playback].volume = action.payload.volume;
                    state.atmosphere.playbacks[action.payload.playback].muted = false;
                }
                else {
                    state.mainPlayback.volume = action.payload.volume;
                    state.mainPlayback.muted = false;
                }
            }
        },
        setMuted: {
            reducer: (state, action) => {
                if (action.payload.playback !== null) {
                    state.atmosphere.playbacks[action.payload.playback].muted = action.payload.muted;
                }
                else {
                    state.mainPlayback.muted = action.payload.muted;
                }
            }
        },
        setAudioMetaData: {
            reducer: (state, action) => {
                if (action.payload.playback !== null) {
                    const metaArray = JSON.parse(action.payload.meta).active_meta;
                    state.atmosphere.playbacks[action.payload.playback].metaData = metaArray.length === 0 ? null : metaArray;
                }
            }
        },
        setStarted: {
            reducer: (state, action) => {
                state.playerState.started = action.payload;
            }
        },
        setPaused: {
            reducer: (state, action) => {
                state.playerState.paused = action.payload;
            }
        },
        setUserDefinedScenes: {
            reducer: (state, action) => {
                state.atmosphere.userDefinedScenes = [];
                for (let i in action.payload) {
                    state.atmosphere.userDefinedScenes.push(convertCustomSceneToCurrent(action.payload[i]));
                }
            }
        }
    }
});

export const { 
    resetWebPlayer, 
    setAtmosphereMeta, 
    setAtmosphere,
    setCurrentState,
    setOpenSideMenu,
    setShowSplash,
    setSceneSearchString,
    setCustomSceneSearchString,
    setVolume,
    setMuted,
    setAudioMetaData,
    setStarted,
    setPaused,
    setUserDefinedScenes
} = webPlayerSlice.actions;
export default webPlayerSlice.reducer; 