import React, { Suspense } from 'react';
import { Route } from "react-router-dom";
import { CondRouteMissingPermission, CondRouteLoggedIn } from "./shared/conditional_auth";
import { EnsurePermanentContexts } from "./shared/session";
import { PERMISSIONS } from "../constants/permissions";
import { PAGE_ROUTES } from "../constants/page_routes";
import MaintenanceDisclaimer from "../deprecated/pages/misc/maintenance-disclaimer/maintenance_disclaimer";
import LoadingWidget from '../deprecated/components/loading-widget/loading_widget';

const MaintenanceHubPageLazy = React.lazy(() => import("../deprecated/pages/misc/maintenance/maintenance_hub"));
const MaintenanceHubPage = () => (
    <Suspense fallback={<LoadingWidget/>}>
        <MaintenanceHubPageLazy/>
    </Suspense>
);

export default function RoutesMaintenance() {
    return (
        <Route element={<CondRouteLoggedIn alternative_target={PAGE_ROUTES.login}/>}>
            <Route element={<EnsurePermanentContexts/>}>
                <Route element={<CondRouteMissingPermission permission={PERMISSIONS.access_maintenance} alternative_target={PAGE_ROUTES.main}/>}>
                    <Route element={<MaintenanceDisclaimer/>}>
                        <Route path={PAGE_ROUTES.maintenance} element={<MaintenanceHubPage/>}/>
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}