import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { sessionContext } from "../../contexts/session";
import { CONTEXT_ID } from "../../constants/context";
import { PERMISSIONS } from "../../constants/permissions";
import RequiredActionsPage from "../../deprecated/pages/account/required-actions/required_actions";

export function EnsureNoRequiredActions() {
    const requiredActions = useSelector(state => state.session.requiredActions);
    if (requiredActions.length > 0)
        return <RequiredActionsPage/>;
    else
        return <Outlet/>;
}

export function EnsurePermanentContexts() {
    const loggedIn = useSelector(state => state.session.loggedIn);
    const permissions = useSelector(state => state.session.permissions);
    useEffect(() => {
        if (loggedIn) {
            sessionContext.openContextIfNotOpened(CONTEXT_ID.hub);
            if (permissions.includes(PERMISSIONS.access_maintenance)) {
                sessionContext.openContextIfNotOpened(CONTEXT_ID.maintenance);
            }
        }
    }, [loggedIn, permissions]);
    return <Outlet/>
}