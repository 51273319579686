function convertSv1(scene) {
    const converted = {};
    converted["sv"] = 3;
    converted["name"] = scene["name"];
    converted["state"] = [];
    for (let i in scene["state"]) {
        const s = scene["state"][i];
        if (s === null)
            converted["state"].push(null);
        else
            converted["state"].push([s, 0]);
    }
    return converted;
}

function convertSv2(scene) {
    const converted = structuredClone(scene);
    converted["sv"] = 3;
    return converted;
}

export function convertCustomSceneToCurrent(scene) {
    if (!("sv" in scene)) {
        if (Array.isArray(scene["state"][0]))
            return convertSv2(scene);
        else
            return convertSv1(scene);
    }
    return scene;
}