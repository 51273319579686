import React from 'react';
import RoutesAuth from './auth';
import RoutesStatus from './status';
import RoutesContent from './content';
import RoutesMaintenance from './maintenance';
import { Routes, Route, Navigate } from "react-router-dom";
import { PAGE_ROUTES } from "../constants/page_routes";
import { EnsurePersistence } from './shared/conditional_auth';
import { DevRoutes } from './dev';
import { PersistentClientSideAppSettings } from './shared/settings';

export default function RoutesRoot() {
    return (
        <Routes>
            <Route element={<PersistentClientSideAppSettings/>}>
                { DevRoutes }
                { RoutesStatus() }
                <Route element={<EnsurePersistence/>}>
                    { RoutesAuth() }
                    { RoutesContent() }
                    { RoutesMaintenance() }
                    <Route path='*' element={<Navigate to={PAGE_ROUTES.status404} replace={true}/>}/>
                </Route>
            </Route>
        </Routes>
    )
}