import { Newspaper as NewsIcon } from '@phosphor-icons/react';
import { extractDateFromDatetime } from '../../../utils/string';
import './news_card.css';

function NewsCardNoThumb() {
    return (
        <div className='news-thumbnail'>
            <div className='no-thumbnail'>
                <div className='icon-container'>
                    <NewsIcon size={96}/>
                </div>
            </div>
        </div>
    );
}

export default function NewsCard({ news, onClick }) {
    const thumbnail = (news.news_thumbnail_url !== null && news.news_thumbnail_url !== '') ?
        <img className='news-thumbnail' style={{filter: 'opacity(70%)'}} src={news.news_thumbnail_url} alt=""/> :
        <NewsCardNoThumb/>;
    return (
        <div className='news-card' onClick={onClick}>
            {thumbnail}
            <div className='news-date'>{extractDateFromDatetime(news.news_timestamp)}</div>
            <div className='news-title'>{news.news_headline}</div>
        </div>
    );
}