import { configureStore } from "@reduxjs/toolkit";
import sessionReducer from "./slices/session";
import hubReducer from './slices/hub';
import webPlayerReducer from './slices/web_player';
import maintenanceReducer from './slices/maintenance';

export const store = configureStore({
    reducer: {
        session: sessionReducer,
        hub: hubReducer,
        webPlayer: webPlayerReducer,
        maintenance: maintenanceReducer
    },
    devTools: (process.env.NODE_ENV !== 'production')
})