import * as Icons from '@phosphor-icons/react';
import AccountSettings from './subpages/account';
import VisualSettings from './subpages/visuals';
import { NavContainer } from '../../../components/navbar/navbar';
import { useState } from 'react';
import { AnimatedBackground } from '../../../../components/shared/animated-background/animated_background';
import { FooterStacked } from '../../../components/footer/footer';
import './settings.css';

const SETTINGS_SUBPAGE_ID = {
    account: 0,
    visual: 1
}

const SETTINGS_SUBPAGES = [
    { id: SETTINGS_SUBPAGE_ID.account, title: 'Account', icon: <Icons.User size={18}/>},
    { id: SETTINGS_SUBPAGE_ID.visual, title: 'Visuals', icon: <Icons.Eye size={18}/>}
];

export default function SettingsPage() {
    const [openSubpage, setOpenSubpage] = useState(SETTINGS_SUBPAGE_ID.account);
    const [retracted, setRetracted] = useState(false);
    const subpage = ((subpageId) => {
        if (subpageId === SETTINGS_SUBPAGE_ID.account)
            return <AccountSettings/>;
        else
            return <VisualSettings/>;
    })(openSubpage);
    return (
        <>
            <AnimatedBackground filter='blur(8px) opacity(33%)'/>
            <NavContainer
                pages={SETTINGS_SUBPAGES}
                openPageId={openSubpage}
                onOpen={(p) => setOpenSubpage(p.id)}
                retracted={retracted}
                setRetracted={setRetracted}
                footer={<FooterStacked/>}
            >
                {subpage}
            </NavContainer>
        </>
    );
}