import { createSlice } from "@reduxjs/toolkit";
import { MAINTENANCE_PAGES } from "../../constants/maintenance";

const initialState = {
    'openPage': MAINTENANCE_PAGES.metrics.id,
    'ignoreDisclaimer': false,
    'metrics': {
        'requestState': null,
        'widgets': [],
        'atmosphereMetrics': [],
        'userCount': {
            'perRole': [],
            'accumulated': 0,
            'deleted': 0
        },
        'usageMetrics': {
            'activeUserCount': 0,
            'accumulatedPlaytime': 0, 
        },
        'websiteViewCount': {
            "homepageViews": 0,
            "heroClicks": 0,
            "videoVolumeOn": 0,
            "signinButtonClicks": 0,
            "subscribeClicks": 0,
            "plansPageViews": 0,
            "plansSectionViews": 0,
            "plansFreeAccountClicks" : 0
        },
    },
    'userManagement': {
        'users': null,
        'selected': [],
        'filter': {
            'email': '',
            'role': [],
            'survey': []
        }
    },
    'news': {
        'title': '',
        'thumbnail': '',
        'markdown': ''
    }
}

export const maintenanceSlice = createSlice({
    name: 'maintenance',
    initialState,
    reducers: {
        resetMaintenance: {
            reducer: (state, action) => {
                state.openPage = initialState.openPage;
                state.ignoreDisclaimer = initialState.ignoreDisclaimer;
                state.metrics = initialState.metrics;
                state.userManagement = initialState.userManagement;
                state.news = initialState.news;
            }
        },
        resetMetrics: {
            reducer: (state, action) => {
                state.metrics.atmosphereMetrics = initialState.metrics.atmosphereMetrics;
                state.metrics.userCount = initialState.metrics.userCount;
            }
        },
        setOpenPage: {
            reducer: (state, action) => {
                state.openPage = action.payload;
            }
        },
        setIgnoreDisclaimer: {
            reducer: (state, action) => {
                state.ignoreDisclaimer = action.payload;
            }
        },
        setMetricsRequestState: {
            reducer: (state, action) => {
                state.metrics.requestState = action.payload;
            }
        },
        setMetricsWidgets: {
            reducer: (state, action) => {
                state.metrics.widgets = action.payload;
            }
        },
        setAtmosphereMetrics: {
            reducer: (state, action) => {
                state.metrics.atmosphereMetrics = action.payload;
            }
        },
        setUserMetrics: {
            reducer: (state, action) => {
                state.metrics.userCount.perRole = action.payload.perRole;
                state.metrics.userCount.accumulated = action.payload.accumulated;
                state.metrics.userCount.deleted = action.payload.deleted;
            }
        },
        setUsageMetrics:{
            reducer: (state, action) => {
                state.metrics.usageMetrics.activeUserCount = action.payload.activeUserCount;
                state.metrics.usageMetrics.accumulatedPlaytime = action.payload.accumulatedPlaytime;
            }
        },
        setWebsiteViewCount:{
            reducer: (state, action) => {
                state.metrics.websiteViewCount.homepageViews = action.payload.homepageViews;
                state.metrics.websiteViewCount.heroClicks = action.payload.heroClicks;
                state.metrics.websiteViewCount.videoVolumeOn = action.payload.videoVolumeOn;
                state.metrics.websiteViewCount.signinButtonClicks = action.payload.signinButtonClicks;
                state.metrics.websiteViewCount.subscribeClicks = action.payload.subscribeClicks;
                state.metrics.websiteViewCount.plansPageViews = action.payload.plansPageViews;
                state.metrics.websiteViewCount.plansSectionViews = action.payload.plansSectionViews;
                state.metrics.websiteViewCount.plansFreeAccountClicks = action.payload.plansFreeAccountClicks;
            }
        },
        setUsers: {
            reducer: (state, action) => {
                state.userManagement.users = action.payload;
            }
        },
        setUserMgmSelected: {
            reducer: (state, action) => {
                state.userManagement.selected = action.payload;
            }
        },
         setUserMgmFilter: {
            reducer: (state, action) => {
                state.userManagement.filter = action.payload;
            }
         },
         setNewsMgmTitle: {
            reducer: (state, action) => {
                state.news.title = action.payload;
            }
         },
         setNewsMgmThumbnail: {
            reducer: (state, action) => {
                state.news.thumbnail = action.payload;
            }
         },
         setNewsMgmMarkdown: {
            reducer: (state, action) => {
                state.news.markdown = action.payload;
            }
         }
    }
});

export const {
    resetMaintenance,
    resetMetrics,
    setOpenPage,
    setIgnoreDisclaimer,
    setMetricsRequestState,
    setMetricsWidgets,
    setAtmosphereMetrics,
    setUserMetrics,
    setUsageMetrics,
    setUsers,
    setUserMgmSelected,
    setUserMgmFilter,
    setNewsMgmTitle,
    setNewsMgmThumbnail,
    setNewsMgmMarkdown,
    setWebsiteViewCount
} = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
