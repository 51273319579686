import AtmosphereContentWrapper from '../../../../components/hub/atmosphere_content_wrapper';
import LoadingWidget from '../../../../components/loading-widget/loading_widget';
import useAtmosphereMetaList from '../../../../../hooks/hub/useAtmosphereMetaList';
import NewsContentWrapper from '../../../../components/hub/news_content_wrapper';
import { Info as InfoIcon } from '@phosphor-icons/react';
import './home.css';
import useNewsMetaList from '../../../../../hooks/hub/useNewsMetaList';
import BANNER_IMAGE from '../../../../../assets/images/banner.webp';
import { PrimaryButton } from '../../../../../components/shared/buttons/buttons';
import useEventTracker from '../../../../../hooks/analytics/useEventTracker';
import { HOMEPAGE_LINKS } from '../../../../../constants/links';

export default function HubSubpageHome() {
    /*
    const newsLoading = false;
    const news = [
        {title: 'Newsletter #3 | New updates to Ambient Sphere', date: '19.12.2021', thumbnail: ''},
        {title: 'Newsletter #2 | Design Update is finally here', date: '1.5.2017', thumbnail: 'https://cms.ambientsphere.com/storage/uploads/2023/04/18/product_image_uid_63dbd247d8873_uid_643e7f736df5e.webp'},
        {title: 'Newsletter #1 | Alpha launch', date: '11.11.2011', thumbnail: ''}
    ];
    const newsError = null;
    */
   const [atmLoading, loadedAtmospheres, atmError] = useAtmosphereMetaList();
   const [newsLoading, news, newsError] = useNewsMetaList();
   const order_now_click = useEventTracker({id: "app_order_now_click"});
    if (atmLoading && newsLoading) {
        return <LoadingWidget/>;
    }
    const normalAtms = <AtmosphereContentWrapper loading={atmLoading} atmospheres={loadedAtmospheres?.normal} error={atmError}/>
    const mockupAtms = <AtmosphereContentWrapper loading={atmLoading} atmospheres={loadedAtmospheres?.mockup} error={atmError}/>
    const newsContainer = <NewsContentWrapper loading={newsLoading} news={news} error={newsError}/>;
   
    return (
        <div className='hub-subpage-home'>
            <div className='premium-banner' 
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundImage: "url(" + BANNER_IMAGE + ")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover", 
                    height:"300px",
                    borderRadius: "var(--lay-border-radius)",
                    marginBottom: "var(--lay-space-v)"}}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "var(--lay-gap-v-connect)",
                    alignItems: "center",
                    textAlign: "center",
                    width: "400px"}}>
                    <h1>
                        Get more with Premium
                    </h1>
                    <span>Get full access to the entire library and create Atmospheres for your stories with Ambient Sphere Premium.</span>
                    <a onClick={() => order_now_click()} href={HOMEPAGE_LINKS.plans + "#p"} target='_blank' rel="noreferrer">
                        <PrimaryButton>Check it out now!</PrimaryButton>
                    </a>
                </div>
            </div>
            <h3>Atmospheres</h3>
            <div className='atm-container'>
                { normalAtms }  
            </div>

            <h3>News</h3>
            { newsContainer }

            <h3>Mockup Atmospheres</h3>
            <div className='info-box'>
                <div className='info-icon'>
                    <InfoIcon size={20}/>
                </div>
                <div className='info-desc'>
                    <span>
                        Mockup atmospheres do not actually function.
                        Their only purpose is to showcase how different atmospheres can look.
                    </span>
                </div>
            </div>
            <div className='atm-container'>
                { mockupAtms }  
            </div>
        </div>
    );
}