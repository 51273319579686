import * as Icons from '@phosphor-icons/react';
import { DropdownClick, DropdownElement, DropdownIcon } from '../dropdown/dropdown';
import { useNavigate } from 'react-router-dom';
import { sessionContext } from '../../../contexts/session';
import { postLogout } from '../../../api/account';
import { useState } from 'react';
import { PAGE_ROUTES } from '../../../constants/page_routes';
import './user_control.css';

function UserIcon() {
    return (
        <div className="user-icon">
            <Icons.User size={'100%'}/>
        </div>
    );
}

export default function UserControl() {
    const [busy, setBusy] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const onLogout = () => {
        if (busy)
            return;
        setBusy(true);
        const finallyHandler = () => {
            setBusy(false);
            sessionContext.loggedOut(navigate);
        }
        postLogout(finallyHandler);
    }
    const onSettings = () => {
        navigate(PAGE_ROUTES.settings);
        setDropdownOpen(false);
    }
    return (
        <DropdownClick anchor={<UserIcon/>} align="right" open={dropdownOpen} setOpen={setDropdownOpen}>
            <DropdownElement onClick={onSettings}>
                <DropdownIcon icon={<Icons.GearSix weight='fill'/>}/>
                Settings&nbsp;
            </DropdownElement>
            <DropdownElement onClick={onLogout}>
                <DropdownIcon icon={<Icons.SignOut weight='fill'/>}/>
                Log Out&nbsp;
            </DropdownElement>
        </DropdownClick>
    );
}