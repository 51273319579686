import { backendApiPublic, backendApiCredentials, backendApiLoggedIn } from "./shared/instances";
import { encodePassword } from "../utils/security";

export function postSignup(
    email,
    password,
    acceptTerms,
    acceptPrivacy,
    origin,
    successHandler = () => { },
    invalidEmailHandler = (messages) => { },
    invalidPasswordHandler = (messages) => { },
    accountCollisionHandler = (messages) => { },
    termsNotAcceptedHandler = (messages) => { },
    privacyNotAcceptedHandler = (messages) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const encodedPassword = encodePassword(password);
    const promise = backendApiPublic.post(
        '/acc/signup',
        {
            email: email,
            encoded_password: encodedPassword,
            accept_terms: acceptTerms,
            accept_privacy: acceptPrivacy,
            origin: origin
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(); })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 422) {
                    if (error?.response?.data?.detail?.type === 'invalid_email') {
                        invalidEmailHandler(error.response.data.detail.message);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'invalid_password') {
                        invalidPasswordHandler(error.response.data.detail.message);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'create_account_collision') {
                        accountCollisionHandler(error.response.data.detail.message);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'terms_not_excepted') {
                        termsNotAcceptedHandler(error.response.data.detail.message);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'privacy_not_excepted') {
                        privacyNotAcceptedHandler(error.response.data.detail.message);
                        return;
                    }
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postLogin(
    username,
    password,
    remember_me,
    successHandler = (access_token, required_actions) => { },
    incorrectEmailOrPasswordHandler = (messages) => { },
    unverifiedEmailHandler = (messages) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const encodedPassword = encodePassword(password);
    const scope = remember_me ? 'extended_lifetime' : '';
    const promise = backendApiCredentials.post(
        '/acc/login', 
        {
            username: username,
            password: encodedPassword,
            scope: scope
        },
        {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/x-www-form-urlencoded'
            },
            signal: controller.signal 
        })
        .then((r) => {
            successHandler(r.data.access_token, r.data.required_actions);
        })
        .catch((error) => {
            if (error.response) {
                if (error?.response?.status === 422 && error?.response?.data?.detail?.type === 'incorrect_email_or_password') {
                    incorrectEmailOrPasswordHandler(error.response.data.detail.message);
                    return;
                }
                else if (error?.response?.status === 423 && error?.response?.data?.detail?.type === 'unverified_email') {
                    unverifiedEmailHandler(error.response.data.detail.message);
                    return;
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postLogout(finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiCredentials.post(
        '/acc/logout',
        { },
        {
            signal: controller.signal
        })
        .then((r) => { })
        .catch((e) => { })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postRefresh(
    successHandler = (access_token, required_actions) => { },
    invalidRefreshTokenHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiCredentials.post(
        '/acc/refresh',
        { },
        {
            signal: controller.signal
        })
        .then((r) => {
            successHandler(r.data.access_token, r.data.required_actions);
        })
        .catch((error) => {
            if (error.response) {
                if (error?.response?.status === 401) {
                    invalidRefreshTokenHandler();
                    return;
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postHeartbeat(
    errorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiCredentials.post(
        '/acc/heartbeat',
        { },
        {
            signal: controller.signal
        })
        .then((r) => { })
        .catch((e) => {
            errorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postSendVerificationEmail(
    email,
    successHandler = () => { },
    alreadyVerifiedHandler = () => { },
    userNotFoundHandler = (messages) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiPublic.post(
        '/acc/emailVerification',
        {
            request_type: 'send',
            email: email,
            verification_code: ''
        },
        {
            signal: controller.signal
        })
        .then((response) => {
            if (response.data.action_completed === true)
                successHandler();
            else
                alreadyVerifiedHandler();
        })
        .catch((error) => {
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'user_not_found') {
                    userNotFoundHandler(error.response.data.detail.message);
                    return;
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postVerifyEmail(
    email,
    verificationCode,
    successHandler = () => { },
    userNotFoundHandler = (messages) => { },
    verificationCodeExpiredHandler = (messages) => { },
    verificationCodeInvalidHandler = (messages) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiPublic.post(
        '/acc/emailVerification',
        {
            request_type: 'verify',
            email: email,
            verification_code: verificationCode
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(); })
        .catch((error) => {
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'user_not_found') {
                    userNotFoundHandler(error.response.data.detail.message);
                    return;
                }
                else if (error?.response?.status === 423 && error?.response?.data?.detail?.type === 'code_expired') {
                    verificationCodeExpiredHandler(error.response.data.detail.message);
                    return;
                }
                else if (error?.response?.status === 422 && error?.response?.data?.detail?.type === 'code_invalid') {
                    verificationCodeInvalidHandler(error.response.data.detail.message);
                    return;
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postResetPasswordSend(
    email,
    successHandler = () => { },
    userNotFoundHandler = (messages) => { },
    lockedHandler = (messages, seconds_left) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { }) 
{
    const controller = new AbortController();
    const promise = backendApiPublic.post(
        '/acc/passwordReset',
        {
            request_type: 'send',
            email: email,
            encoded_password: '',
            auth_code: ''
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler() })
        .catch((error) => {
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'user_not_found') {
                    userNotFoundHandler(error.response.data.detail.message);
                    return;
                }
                else if (error?.response?.status === 423 && error?.response?.data?.detail?.type === 'locked') {
                    lockedHandler(error.response.data.detail.message, error.response.data.detail.arg);
                    return;
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postResetPasswordSet(
    email,
    password,
    authCode,
    successHandler = () => { },
    userNotFoundHandler = (messages) => { },
    lockedHandler = (messages, seconds_left) => { },
    invalidPasswordHandler = (messages) => { },
    resetCodeExpired = (messages) => { },
    resetCodeInvalid = (messages) => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { }) 
{
    const controller = new AbortController();
    const encodedPassword = encodePassword(password);
    const promise = backendApiPublic.post(
        '/acc/passwordReset',
        {
            request_type: 'set',
            email: email,
            encoded_password: encodedPassword,
            auth_code: authCode
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler() })
        .catch((error) => {
            if (error.response) {
                if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'user_not_found') {
                    userNotFoundHandler(error.response.data.detail.message);
                    return;
                }
                else if (error?.response?.status === 422) {
                    if (error?.response?.data?.detail?.type === 'invalid_password') {
                        invalidPasswordHandler(error.response.data.detail.message);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'invalid_code') {
                        resetCodeInvalid(error.response.data.detail.message);
                        return;
                    }
                }
                else if (error?.response?.status === 423) {
                    if (error?.response?.data?.detail?.type === 'locked') {
                        lockedHandler(error.response.data.detail.message, error.response.data.detail.arg);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'expired') {
                        resetCodeExpired(error.response.data.detail.message);
                        return;
                    }
                }
            }
            unknownErrorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postAcceptTerms(
    successHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.post(
        '/acc/acceptTerms',
        { },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postAcceptPrivacy(
    successHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.post(
        '/acc/acceptPrivacy',
        { },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 401) {
                    return;
                }
                else if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postUpgradeAccessToken(
    upgradedHandler = (access_token) => { },
    notUpgradedHandler = () => { },
    errorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.post(
        '/acc/upgradeAccessToken',
        { },
        {
            signal: controller.signal
        })
        .then((r) => {
            if (r.data.upgraded) {
                upgradedHandler(r.data.access_token);
            }
            else {
                notUpgradedHandler();
            }
        })
        .catch((e) => {
            errorHandler();
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postUpdatePassword(
    currentPassword,
    newPassword,
    successHandler = () => { },
    userNotFoundHandler = () => { },
    invalidCurrentPassword = () => { },
    invalidNewPassword = (messages) => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const encodedPasswordCurrent = encodePassword(currentPassword);
    const encodedPasswordNew = encodePassword(newPassword);
    const promise = backendApiLoggedIn.post(
        '/acc/updatePassword',
        { 
            encoded_password_current: encodedPasswordCurrent,
            encoded_password_new: encodedPasswordNew
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
                else if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'user_not_found') {
                    userNotFoundHandler(error.response.data.detail.message);
                    return;
                }
                else if (error?.response?.status === 422) {
                    if (error?.response?.data?.detail?.type === 'invalid_password') {
                        invalidNewPassword(error.response.data.detail.message);
                        return;
                    }
                    else if (error?.response?.data?.detail?.type === 'incorrect_email_or_password') {
                        invalidCurrentPassword(error.response.data.detail.message);
                        return;
                    }   
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postAccountDelete(
    currentPassword,
    successHandler = () => { },
    userNotFoundHandler = () => { },
    invalidPasswordHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const encodedPassword = encodePassword(currentPassword);
    const promise = backendApiLoggedIn.post(
        '/acc/delete',
        { 
            encoded_password: encodedPassword
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(); })
        .catch((error) => { 
            if (error.response) {
                if (error?.response?.status === 403) {
                    permissionDeniedHandler();
                    return;
                }
                else if (error?.response?.status === 404 && error?.response?.data?.detail?.type === 'user_not_found') {
                    userNotFoundHandler();
                    return;
                }
                else if (error?.response?.status === 422 && error?.response?.data?.detail?.type === 'incorrect_email_or_password') {
                    invalidPasswordHandler();
                    return;
                }
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}