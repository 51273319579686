import { backendApiLoggedIn } from "./shared/instances";

export function getSurveyList(
    successHandler = (surveys) => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.get(
        '/usr/surveyList',
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data.surveys); })
        .catch((error) => {
            if (error?.response?.status === 403) {
                permissionDeniedHandler();
                return;
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postSurvey(
    surveyId,
    data,
    successHandler = () => { },
    permissionDeniedHandler = () => { },
    unknownErrorHandler = () => { },
    finallyHandler = () => { })
{
    const controller = new AbortController();
    const promise = backendApiLoggedIn.post(
        '/usr/survey',
        {
            survey_id: surveyId,
            data: JSON.stringify(data)
        },
        {
            signal: controller.signal
        })
        .then((r) => { successHandler(r.data.surveys); })
        .catch((error) => {
            if (error?.response?.status === 403) {
                permissionDeniedHandler();
                return;
            }
            unknownErrorHandler(); 
        })
        .finally(finallyHandler);
    return { 
        abortController: controller,
        requestPromise: promise
    };
}

export function postPlaytimeToken(
    errorHandler = () => { },
    finallyHandler = () => { }
) {
    const controller = new AbortController();
    const promise = backendApiLoggedIn.post(
        '/usr/playtimeToken',
        { },
        {
            signal: controller.signal
        })
        .then((r) => { })
        .catch((e) => {
            errorHandler(); 
        })
        .finally(finallyHandler);;
    return { 
        abortController: controller,
        requestPromise: promise
    };
}