import { Waveform, MusicNotes } from '@phosphor-icons/react';
import './audio_meta_info.css';

function MetaIcon({ type, thumbnail }) {
    if (thumbnail && thumbnail !== '') {
        return (
            <div className='audio-meta-icon'>
                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={thumbnail} alt=""/>
            </div>
        );
    }
    else {
        const icon = type === 'music' ?
            <MusicNotes size={24}/> :
            <Waveform size={24}/>;
        return (
            <div className='audio-meta-icon'>
                {icon}
            </div>
        );
    }
}

function MetaText({ type, name, nameUrl, artist, artistUrl, album, albumUrl }) {
    const primaryStr = name && name !== '' ? name : 'Unnamed ' + (type === 'music' ? 'Music' : 'Sound');
    const primaryStyle = { font: 'var(--font-body)', lineHeight: '1.25em' };
    const primaryComp = nameUrl ?
        <a style={primaryStyle} href={nameUrl} target='_blank' rel='noreferrer'>{primaryStr}</a> :
        <div style={primaryStyle}>{primaryStr}</div>;
    const secondaryStyle = { color: 'var(--color-foreground-hinted)', font: 'var(--font-body-small)' };
    const artistComp = !artist ? null : (artistUrl ?
            <a href={artistUrl} target='_blank' rel='noreferrer'>{artist}</a> :
            <span>{artist}</span>
        );
    const albumComp = !album ? null : (albumUrl ?
            <a href={albumUrl} target='_blank' rel='noreferrer'>{album}</a> :
            <span>{album}</span>
        );
    const secondaryComp = 
        artistComp && albumComp ? <div style={secondaryStyle}>{artistComp}&nbsp;&#8226;&nbsp;{albumComp}</div> :
        artistComp ? <div style={secondaryStyle}>{artistComp}</div> :
        albumComp ? <div style={secondaryStyle}>{albumComp}</div> :
        null;
    return (
        <div className='audio-meta-text'>
            {primaryComp}
            {secondaryComp}
        </div>
    );

}

function MetaCard({ meta }) {
    const icon = 
        <MetaIcon 
            type={meta.hasOwnProperty("track_type") ? meta.track_type : null}
            thumbnail={meta.hasOwnProperty("thumbnail_url") ? meta.thumbnail_url : null}
        />;
    const text =
        <MetaText
            type={meta.hasOwnProperty("track_type") ? meta.track_type : null}
            name={meta.hasOwnProperty("track_name") ? meta.track_name : null}
            nameUrl={meta.hasOwnProperty("url") ? meta.url : null}
            artist={meta.hasOwnProperty("interpret_name") ? meta.interpret_name : null}
            artistUrl={null}
            album={meta.hasOwnProperty("album_name") ? meta.album_name : null}
            albumUrl={null}
        />;
    return (
        <div style={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center'
        }}>
            {icon}
            {text}   
        </div>
    );

    /*
    const type = !meta.hasOwnProperty("track_type") ? 
        <></> :
        meta.track_type === "music" ?
            <span style={{font: 'var(--font-body-tiny)', margin: '1px 0px', color: "skyblue"}}>MUSIC</span> :
            <span style={{font: 'var(--font-body-tiny)', margin: '1px 0px', color: "springgreen"}}>SFX</span>;        
    const name = !meta.hasOwnProperty("track_name") ?
        <></> :
        <InfoTag tag='Track' info={meta.track_name}/>;
    const album = !meta.hasOwnProperty("album_name") ?
        <></> :
        <InfoTag tag='Album' info={meta.album_name}/>;
    const interpret = !meta.hasOwnProperty("interpret_name") ? 
        <></> : 
        <InfoTag tag='Interpret' info={meta.interpret_name}/>;
    
    const thumbnail = !meta.hasOwnProperty("thumbnail_url") ?
        <></> :
        <div className='info-thumbnail-container'>
            <img className='info-thumbnail' src={meta.thumbnail_url} alt=""/>
        </div>;
    
    const info = 
        <div style={{ display: 'flex' }}>
            {thumbnail}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {name}
                {album}
                {interpret}
            </div>
        </div>

    const metaComp = (
        <div className='audio-meta-info-card'>
            {type}
            {info}
        </div>
    );
        
    return !meta.hasOwnProperty("url") ?
        metaComp :
        (
            <a href={meta.url} target='_blank' rel='noreferrer'>
                {metaComp}
                <span className='url-tag'>Link</span>
            </a>
        );
    */
}

export default function AudioMetaInfo({ meta }) {
    return meta === null ?
        (<div style={{color: 'var(--color-foreground-hinted)'}}>No meta data available</div>) :
        (<div className='audio-meta-info-container'>{meta.map((mo, i) => <MetaCard meta={mo} key={i}/>)}</div>);
}