import { PAGE_ROUTES } from "./page_routes"

export const CONTEXT_ID = {
    maintenance: 0,
    hub: 1,
    webPlayer: 2,
    webEditor: 3
}

export const CONTEXT_DESC = {
    0: { 
        name: 'MAINTENANCE', 
        defaultTitle: 'Maintainer interface',
        defaultNav: PAGE_ROUTES.maintenance
    },
    1: { 
        name: 'HUB', 
        defaultTitle: 'Home',
        defaultNav: PAGE_ROUTES.main
    },
    2: { 
        name: 'PLAYER', 
        defaultTitle: 'Unnamed Atmosphere',
        defaultNav: '/500'
    },
    3: { 
        name: 'EDITOR', 
        defaultTitle: 'Unnamed Atmosphere',
        defaultNav: '/500'
    }
}