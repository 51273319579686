import { store } from '../../store/store';
import { sessionContext } from '../../contexts/session';

export function addAuth(axoisApi, logginApi, credentialApi){
    axoisApi.interceptors.request.use(
        (config) => {
            if (!config.headers['Authorization']){
                config.headers['Authorization']  = `Bearer ${store.getState().session.accessToken}`;
            }
            return config;
        },
        (error) => { 
            // Use Promise.resolve() to propagate error to the caller
            Promise.resolve(error);
        }
    );
    
    axoisApi.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const prevRequestConfig = error?.config;
    const refresh = async () => {
        try {
            const refreshResponse = await credentialApi.post('/acc/refresh');
            sessionContext.refresh(
                refreshResponse.data.access_token, 
                refreshResponse.data.required_actions);
            prevRequestConfig.headers['Authorization']  = `Bearer ${refreshResponse.data.access_token}`;
            return axoisApi(prevRequestConfig);
        }
        catch (e) {
            // Session expired
            sessionContext.expireSession();
            return Promise.resolve(e);
        }
    };
    // Workaround for chunk requests, as those get their data object in form of an arraybuffer
    var type = null;
    if (error?.config?.responseType === 'arraybuffer' && error?.response?.data) {
        try {
            const obj = JSON.parse(new TextDecoder().decode(error?.response?.data));
            type = obj?.detail?.type;
        }
        catch (e) { }
    }
    else {
        type = error?.response?.data?.detail?.type;
    }
    // /workaround
    if (error?.response?.status === 403 && type === 'unauthorized') {
        try {
            const upgradeResponse = await logginApi.post('/acc/upgradeAccessToken');
            if (upgradeResponse?.data?.upgraded === true) {
                sessionContext.upgradeToken(upgradeResponse.data.access_token);
                prevRequestConfig.headers['Authorization']  = `Bearer ${upgradeResponse.data.access_token}`;
                return axoisApi(prevRequestConfig);
            }
            else {
                // Use Promise.resolve() to propagate error to the caller
                Promise.resolve(error);
            }
        }   
        catch (e) {
            if (e?.response?.status === 401 && type === 'invalid_access_token') {
                return await refresh();
            }
            // Use Promise.resolve() to propagate error to the caller
            return Promise.resolve(e);
        } 
    }
    else if (error?.response?.status === 401 && type === 'invalid_access_token') {
        return await refresh();
    }
    return Promise.reject(error);
        }
    );
}