import axios from "axios";
import { ANALYTICS_API_BASE_URL } from "../constants/api";
import { backendAnalytics }  from "./shared/instances";

export function postTrackingEvent(event, 
    successHandler = () => {},
    errorHandler = () => {},
    finallyHanlder = () => {}){
    //navigator.sendBeacon(ANALYTICS_API_BASE_URL + "/t/event_e",
    //    JSON.stringify(event))
    const controller = new AbortController();
    const promise = axios.post(
        ANALYTICS_API_BASE_URL + "/t/event_e",
        event,
        {
            signal: controller.signal,
            headers: {
                'accept': '*/*',
                'content-type': 'application/json'
            }
        }
    ).then(successHandler)
    .catch(errorHandler)
    .finally(finallyHanlder);
    return {
        abortController: controller,
        requestPromise: promise
    };
}

export function postTrackingEventTimer(event, 
    successHandler = () => {}){
    const controller = new AbortController();
    const promise = axios.post(
        ANALYTICS_API_BASE_URL + "/t/event_t",
        event,
        {
            signal: controller.signal,
            headers: {
                'accept': '*/*',
                'content-type': 'application/json'
            }
        }
    ).then((r) => successHandler())
    .catch((e) => {});
    return {
        abortController: controller,
        requestPromise: promise
    };
}

export function postTrackingHeartbeat(
    event,
    errorHandler = () => { },
    finallyHandler = () => { }){
    const controller = new AbortController();
    const promise = axios.post(
        ANALYTICS_API_BASE_URL + "/t/heartbeat",
        event,
        {
            signal: controller.signal,
            headers: {
                'accept': '*/*',
                'content-type': 'application/json'
            }
        }
    ).then((r) => { })
    .catch((e) => errorHandler())
    .finally(finallyHandler);
    return {
        abortController: controller,
        requestPromise: promise
    };
}

export function postFeedbackForm(
    feedback,
    errorHandler = () => { },
    finallyHandler = () => { }){
    const controller = new AbortController();
    const promise = backendAnalytics.post(
        ANALYTICS_API_BASE_URL + "/m/feedback",
        { 
            data: feedback
        },
        {
            signal: controller.signal,
            headers: {
                'accept': '*/*',
                'content-type': 'application/json'
            }
        }
    ).then((r) => { })
    .catch((e) => errorHandler())
    .finally(finallyHandler);
    return {
        abortController: controller,
        requestPromise: promise
    };
}