import { audioContext } from "./audio_context";
import { AUDIO_CONFIG } from "../../constants/audio";

export class AudioQueue {
    constructor(cbQueryBuffer = () => {}) {
        //console.log('AudioQueue.constructor');
        this.queryBufferCallback = cbQueryBuffer;
        
        this.scheduleOffset = 0;
        this.gainNode = audioContext.createGain();
        this.gainNode.gain.value = 1;
        
        this.stopped = true;
        this.queriedBuffers = 0;
        
        this.queueHead = 0;
        this.queueSize = 0;
        this.bufferQueue = [];
        for (let i = 0; i < AUDIO_CONFIG.bufferQueueLength; ++i) {
            this.bufferQueue.push({
                length: 0,
                node: null,
                data: audioContext.createBuffer(
                    AUDIO_CONFIG.bufferChannels, 
                    AUDIO_CONFIG.bufferLength * AUDIO_CONFIG.sampleRate, 
                    AUDIO_CONFIG.sampleRate)
            });
        }
    }

    // ========================================================================
    // Public interface
    setVolume(volume) {
        this.gainNode.gain.value = volume;
    }

    insertBuffer(buffer) {
        //console.log('AudioQueue.insertBuffer');
        if (this.queueSize >= AUDIO_CONFIG.bufferQueueLength || this.queriedBuffers === 0) {
            return;
        }
        this.queriedBuffers = this.queriedBuffers - 1;
        const numSamplesPerChannel = buffer.byteLength / AUDIO_CONFIG.frameSize;
        this.bufferQueue[this.queueHead].length = numSamplesPerChannel / AUDIO_CONFIG.sampleRate;
        for (let channel = 0; channel < AUDIO_CONFIG.bufferChannels; ++channel) {
            let inputChannel = new Float32Array(buffer, channel * AUDIO_CONFIG.channelSize, numSamplesPerChannel);
            this.bufferQueue[this.queueHead].data.copyToChannel(inputChannel, channel);
        }
        this.queueSize = this.queueSize + 1;
        this.queueHead = (this.queueHead + 1) % AUDIO_CONFIG.bufferQueueLength;
        this.#scheduleBuffer(this.queueHead);
        if (this.queueSize < AUDIO_CONFIG.bufferQueueLength) {
            this.#queryNext();
        }
    }

    async start() {
        //console.log('AudioQueue.start');
        if (!this.stopped) {
            return;
        }
        this.stopped = false;
        if (this.queriedBuffers === 0) {
            this.#queryNext();
        }
        if (audioContext.state === 'suspended') {
            await audioContext.resume();
        }
    }

    stop() {
        //console.log('AudioQueue.stop');
        this.stopped = true;
    }

    terminate() {
        this.stop();
        this.queriedBuffers = 0;
        for (let i = 0; i < this.queueSize; ++i) {
            const idx = this.queueHead - i + 
                (i > this.queueHead ? AUDIO_CONFIG.bufferQueueLength : 0);
            this.bufferQueue[idx].node.disconnect();
        }
    }

    // ========================================================================
    // Private interface
    #queryNext() {
        //console.log('AudioQueue.queryNext');
        if (this.stopped) {
            return;
        }
        this.queriedBuffers = this.queriedBuffers + 1;
        this.queryBufferCallback();
    }

    #playbackEnded() {
        //console.log('AudioQueue.playbackEnded');
        this.queueSize = this.queueSize - 1;
        if (this.queueSize === AUDIO_CONFIG.bufferQueueLength - 1) {
            this.#queryNext();
        }
    }

    #scheduleBuffer(idx) {
        //console.log('AudioQueue.scheduleBuffer');
        this.bufferQueue[idx].node = audioContext.createBufferSource();
        this.bufferQueue[idx].node.buffer = this.bufferQueue[idx].data;
        this.bufferQueue[idx].node.onended = this.#playbackEnded.bind(this);
        this.bufferQueue[idx].node.connect(this.gainNode).connect(audioContext.destination);
        if (this.queueSize <= 1) {
            this.bufferQueue[idx].node.start();
            this.scheduleOffset = audioContext.currentTime + this.bufferQueue[idx].length;
        }
        else {
            this.bufferQueue[idx].node.start(this.scheduleOffset);
            this.scheduleOffset += this.bufferQueue[idx].length;
        }
    }
}