import { Smiley, SmileyMeh, SmileySad, X } from '@phosphor-icons/react';
import './feedback_form.css';
import { useState } from 'react';
import { TextBox } from '../shared/textboxes/textboxes';
import { PrimaryButton } from '../shared/buttons/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { getThumbnailURL } from '../../api/data';
import NullImg from '../../assets/images/null.jpg';
import Checkbox from '../shared/checkbox/checkbox';
import { setFeedbackOverlay } from '../../store/slices/session';
import { postFeedbackForm } from '../../api/analytics';

export default function FeedbackForm(){
    const meta = useSelector((state) => state.webPlayer.atmosphereMeta);
    const categories = useSelector(state => state.webPlayer.atmosphere.categories);
    const currentState = useSelector(state => state.webPlayer.atmosphere.currentState);
    const disptach = useDispatch();
    const [experiance, setExperiance] = useState();
    const [message, setMessage] = useState();
    const [feedbackType, setFeedbackType] = useState();
    const [feedbackTopic, setFeedbackTopic] = useState();
    const experiance_class = (id) => {
        return experiance === id ? 'feedback-rating selected' : 'feedback-rating';
    }

    const tags = currentState.map((e, i) => {
        const name = categories[i].elements[e[0]][0];
        return <div key={i} style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'var(--color-foreground)',
                    font: 'var(--font-body-medium)',
                    padding: '2px 8px',
                    backgroundColor: 'var(--color-bg-elem-dp1-dk)',
                    borderRadius: '100px'}}
                >
                    {name}
                </div>
        }
    );

    const rate_atm = meta.name ?      
        <div className='atmosphere-title-container'>
            <img className='atmosphere-title-thumb' 
                src={getThumbnailURL(meta.thumbnail_path)} 
                alt=''
                onError={(e) => {
                    e.target.src = NullImg
                }}/>
            <div className='atmosphere-title-info'>
                <h3>{meta.name}</h3>
                <span style={{display: "flex", flexWrap: "wrap" , gap:'var(--lay-gap-h-connect)', color: 'var(--color-foreground-hinted)'}}>
                    {tags}
                </span>
            </div>
        </div>
        :
        <></>
    
    const vaildInput = 
        (experiance === "sad" || experiance === "meh" || experiance === "happy") &&
        (feedbackType === "atm" || feedbackType === "general") &&
        (feedbackTopic === "bug" || feedbackTopic === "suggestion" || feedbackTopic === "other");

    const onSumbit = () => {
        const feedback = {
            atm: meta?.id,
            state: currentState.map((e, i) => {
                return categories[i].elements[e[0]][0];}),
            experiance_rating: experiance,
            type: feedbackType,
            topic: feedbackTopic,
            message: message,
        }
        postFeedbackForm(feedback);
        disptach(setFeedbackOverlay(false));
    }

    return(
        <div className="feedback-splash-container">
            <div style={{flexGrow: 1}}/>
            <div className="feedback-splash-widget">
                <X size={24} className='feedback-close'
                    onClick={() => disptach(setFeedbackOverlay(false))}/>
                <div className='feedback-header'>
                    <h3>Send us your feedback!</h3>
                    Do you have a suggestion or found some bugs? <br/>
                    Let us know in the fields below.
                </div>
                <div className='feedback-body custom-scrollbar'>
                    <div style={{display: "flex", flexDirection:"column", gap:"var(--lay-gap-v-connect)"}}>
                        {rate_atm}
                        <div style={{display: "flex", flexWrap: "wrap", gap:"var(--lay-gap-h-separate)"}}>
                            <Checkbox style={{userSelect: "none"}} id={"feedback-type-atm"} checked={feedbackType === "atm"} onChange={() => setFeedbackType("atm")}> Comment what you hear</Checkbox>
                            <Checkbox style={{userSelect: "none"}} id={"feedback-type-general"} checked={feedbackType === "general"} onChange={() => setFeedbackType("general")}> General Feedback</Checkbox>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection:"column", gap:"var(--lay-gap-v-connect)"}}>
                    <h4>How would you rate your experience?</h4>
                        <div style={{display: "flex", gap:"var(--lay-gap-h-connect)"}}>
                            <SmileySad size={45}
                                onClick={() => setExperiance("sad")}
                                className={experiance_class("sad")}/>
                            <SmileyMeh size={45} 
                                onClick={() => setExperiance("meh")}
                                className={experiance_class("meh")}/>
                            <Smiley size={45}
                                onClick={() => setExperiance("happy")}
                                className={experiance_class("happy")}/>
                        </div>
                    </div>
                    <TextBox 
                        mulitline={true}
                        value={message}
                        placeholder={"Tell us about your experiance"}
                        onChange={(e) => setMessage(e.target.value)}/>
                    <div style={{display: "flex", flexDirection:"column", gap:"var(--lay-gap-v-connect)"}}>
                        <div style={{display: "flex", flexWrap: "wrap", gap:"var(--lay-gap-h-separate)"}}>
                            <Checkbox style={{userSelect: "none"}} id={"feedback-topic-bug"} checked={feedbackTopic === "bug"} onChange={() => setFeedbackTopic("bug")}>Bug</Checkbox>
                            <Checkbox style={{userSelect: "none"}} id={"feedback-topic-suggestion"} checked={feedbackTopic === "suggestion"} onChange={() => setFeedbackTopic("suggestion")}>Suggestion</Checkbox>
                            <Checkbox style={{userSelect: "none"}} id={"feedback-topic-other"} checked={feedbackTopic === "other"} onChange={() => setFeedbackTopic("other")}>Other</Checkbox>
                        </div>
                    </div>
                </div>
                <div className='feedback-footer'>
                    <PrimaryButton active={vaildInput} onClick={onSumbit}>Send</PrimaryButton>
                </div>
            </div>
            <div style={{flexGrow: 1}}/>
        </div>
    );
}