import Footer from '../../../../components/shared/footer/footer';
import LoadingSpinner from '../../../../components/shared/loading-spinner/loading_spinner';
import HTMLContent from '../../../components/html-content/html_content';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PAGE_ROUTES } from '../../../../constants/page_routes';
import { useEffect, useState } from 'react';
import { CheckboxInput } from '../../../components/form-input/form_input';
import { DefaultButton } from '../../../components/button/button';
import { cmsGetItem } from '../../../../api/cms';
import { postAcceptPrivacy, postAcceptTerms, postUpgradeAccessToken } from '../../../../api/account';
import { setRequiredActions } from '../../../../store/slices/session';
import './required_actions.css';
import { sessionContext } from '../../../../contexts/session';

function getContent(requiredActions) {
    if (requiredActions.includes('accept_updated_terms')) {
        return {
            actionID: 'accept_updated_terms',
            inTextRef: 'terms of service',
            inHeadlineRef: 'Terms of Service',
            documentTitle: 'Ambient Sphere | Terms of Service Update',
            header: 'Terms of Serivce Update',
            cmsModel: 'terms',
            apiMethod: postAcceptTerms
        };
    }
    else if (requiredActions.includes('accept_updated_privacy')) {
        return {
            actionID: 'accept_updated_privacy',
            inTextRef: 'privacy policy',
            inHeadlineRef: 'Privacy Policy',
            documentTitle: 'Ambient Sphere | Privacy Policy Update',
            header: 'Privacy Policy Update',
            cmsModel: 'privacy',
            apiMethod: postAcceptPrivacy
        };
    }
    return null;
}

export default function RequiredActionsPage() {
    const requiredActions = useSelector(state => state.session.requiredActions);
    const content = getContent(requiredActions);
    const [acceptUpdate, setAcceptUpdate] = useState(false);
    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [updatedText, setUpdatedText] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        if (content === null) {
            navigate(PAGE_ROUTES.status500);
        }
        document.title = content.documentTitle;
        if (loading) {
            cmsGetItem(
                content.cmsModel,
                (t) => setUpdatedText(t.body),
                () => navigate(PAGE_ROUTES.status500),
                () => setLoading(false)
            );
        }
    }, [loading, content, navigate]);

    const readerComp = loading ? 
    (
        <div style={{
            width: '100%', 
            height: '100%', 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "center" }}
        >
            <span>Loading {content.inHeadlineRef}&nbsp;&nbsp;</span>
            <LoadingSpinner size={16}/>
        </div>
    )
    :
    (<HTMLContent html={updatedText}/>);

    const dispatch = useDispatch();
    const accept = (e) => {
        e.preventDefault();
        setApiLoading(true);
        setLoading(true);
        const successHandler = () => {
            const newRequiredActions = requiredActions.filter((item) => item !== content.actionID);
            dispatch(setRequiredActions(newRequiredActions));
            if (newRequiredActions.length === 0) {
                const upgradedHandler = (access_token) => sessionContext.refresh(access_token, []);
                postUpgradeAccessToken(upgradedHandler);
            }
        };
        const permissionDeniedHandler = () => {
            navigate(PAGE_ROUTES.status500);
        }
        const unknownErrorHandler = () => {
            navigate(PAGE_ROUTES.status500);
        };
        const finallyHandler = () => {
            setApiLoading(false);
            setAcceptUpdate(false);
            setUpdatedText('');
            setLoading(false);
        };
        content.apiMethod(
            successHandler, 
            permissionDeniedHandler,
            unknownErrorHandler, 
            finallyHandler);
    }

    const apiLoadingComp = apiLoading ?
          <div style={{padding: '2px'}}><LoadingSpinner size={16}/></div>
        : <></>;

    return (
        <div className='required-action-page-container '>
            <div style={{flexGrow: 1}}/>
            <div className='required-actions-background'>
                <form className='required-actions-form' onSubmit={accept}>
                    <h2>{content.header}</h2>
                    <p className='required-actions-description'>
                        There has been an update to our {content.inTextRef}.
                        Please read the updated {content.inTextRef} posted below carefully.
                        In order to proceed you have to accept the updated {content.inTextRef}.
                    </p>
                    <div className='required-actions-reader custom-scrollbar'>
                        { readerComp }
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <CheckboxInput
                            id='accept'
                            checked={acceptUpdate}
                            onChange={() => setAcceptUpdate(!acceptUpdate)}
                        >
                            I have read and accept the updated {content.inTextRef}.
                        </CheckboxInput>
                        { apiLoadingComp }
                    </div>
                    <DefaultButton
                        style={{width: "100%"}}
                        deactivated={!acceptUpdate}
                    >
                        Continue
                    </DefaultButton>
                </form>
            </div>
            <div style={{flexGrow: 1}}/>
            <Footer/>
        </div>
    );
}