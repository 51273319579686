import React from "react";
//import { SocialIcons } from "../social-icons/social-icons";
import { APP_FULL_ID } from "../../../constants/app";
import { LEGAL_LINKS } from "../../../constants/links";
import './footer.css';

const LegalLinks = [
    <a key={0} href={LEGAL_LINKS.imprint} target='_blank' rel='noreferrer'>Imprint</a>,
    <a key={1} href={LEGAL_LINKS.terms_of_service} target='_blank' rel='noreferrer'>Terms of Service</a>,
    <a key={2} href={LEGAL_LINKS.privacy_policy} target='_blank' rel='noreferrer'>Privacy Policy</a>
];

/*
const SocialLinks = [
    <SocialIcons.Discord key={0}/>,
    <SocialIcons.Instagram key={1}/>,
    <SocialIcons.YouTube key={2}/>,
    <SocialIcons.Facebook key={3}/>
];
*/

function LegalLinkSection() {
    return (
        <div className='legal-links'>
            <div>{APP_FULL_ID}</div>
            <div style={{
                display: 'flex',
                gap: 'var(--lay-gap-h-separate)'
            }}>
                {LegalLinks}
            </div>
        </div>
    );
}

/*
function SocialLinkSection() {
    return (
        <div className='social-links'>
            <div>Join the Community</div>
            <div style={{
                display: 'flex',
                gap: 'var(--lay-gap-h-separate)',
                alignItems: 'center'
            }}>
                {SocialLinks}
            </div>
        </div>
    );
}
*/

export default function FooterSlim() {
    return (
        <div className='footer'>
            <LegalLinkSection/>
        </div>
    );
}