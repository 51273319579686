import rightEdge from '../../../assets/icons/right-edge.svg';
import leftEdge from '../../../assets/icons/left-edge.svg';
import nullImg from '../../../assets/images/null.jpg';
import { Pause as PauseIcon, Play as PlayIcon } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getThumbnailURL } from '../../../api/data';
import { webPlayerContext } from '../../../contexts/web_player';
import { CONTEXT_ID } from '../../../constants/context';
import './tabs.css';

function WebPlayerControl({ thumb }) {
    const thumbnailSrc = (!thumb || thumb === '') ? nullImg : getThumbnailURL(thumb);
    const playerState = useSelector(state => state.webPlayer.playerState);
    const controlIcon = (!playerState.started || playerState.paused) ? 
        <PlayIcon weight='fill'/> : 
        <PauseIcon weight='fill'/>;
    const onClick = (e) => {
        e.stopPropagation();
        webPlayerContext.setPaused(!playerState.paused);
    }
    return (
        <div className='header-tab-thumb-container'>
            <img className='header-tab-thumbnail' src={thumbnailSrc} alt=''/>
            <div className='header-tab-player-control' onClick={onClick}>
                {controlIcon}
            </div>
        </div>
    );    
}

function Tab({ tab }) {
    const navigate = useNavigate();
    const leftEdgeComp = tab.active ? <img src={leftEdge} className='header-edge' alt=''/> : <></>;
    const rightEdgeComp = tab.active ? <img src={rightEdge} className='header-edge' alt=''/> : <></>;
    const webPlayerControl = tab.isPlayer ? <WebPlayerControl thumb={tab.tn}/> : <></>;
    return (
        <>
            <div className={'header-tab' + (tab.active ? ' active' : '')} onClick={() => {
                navigate(tab.target);
            }}>
                {leftEdgeComp}
                {webPlayerControl}
                <div className='header-tab-info-container'>
                    <div className='header-tab-info'>
                        <div style={{flexGrow: 1}}/>
                        <div className='header-tab-head'>{tab.h}</div>
                        <div className='header-tab-title'>{tab.t}</div>
                    </div>
                </div>
                {rightEdgeComp}
            </div>
        </>
    );
}

export default function Tabs() {
    const openContexts = useSelector((state) => state.session.openContexts);
    const focusedTab = useSelector((state) => state.session.focusedContext);
    const openTabs = Object.keys(openContexts).sort((a, b) => Number.parseInt(b) - Number.parseInt(a)).map(k => {
        return {
            active: openContexts[k].id === focusedTab,
            isPlayer: openContexts[k].id === CONTEXT_ID.webPlayer,
            target: openContexts[k].target,
            h: openContexts[k].name,
            t: openContexts[k].title,
            tn: openContexts[k].thumbnail
        };
    });
    if (openTabs.length === 0)
        return <></>;
    const tabs = openTabs.map((t, i) => (<Tab key={i} tab={t}/>));
    return (
        <div className='header-tab-container'>
            { tabs }
        </div>
    );
}