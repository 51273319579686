import Footer from '../../../../components/shared/footer/footer';
import { Outlet } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { CheckboxInput } from '../../../components/form-input/form_input';
import { DefaultButton } from '../../../components/button/button';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIgnoreMobileDisclaimer } from '../../../../store/slices/session';
import './mobile_disclaimer.css';

export default function MobileDisclaimer() {
    const [ignore, setIgnore] = useState(false);
    const ignoreMobileDisclaimer = useSelector(state => state.session.ignoreMobileDisclaimer);
    const ignoreDisclaimer = isMobile ? ignoreMobileDisclaimer : true;
    const dispatch = useDispatch();
    return ignoreDisclaimer ?
        <Outlet/> :    
        (
            <div className='mobile-disclaimer-page-container'>
                <div style={{flexGrow: 1}}/>
                <div className='mobile-disclaimer'>
                    <h4>No Mobile Support</h4>
                    <p>
                        Unfortunately the Ambient Sphere WebApp currently does not support mobile devices.
                    </p>
                    <div style={{width: '100%', height: '1px', margin: '8px 0px', background: 'var(--color-bg-elem-dp2)'}}/>
                    <form onSubmit={(e) => { e.preventDefault(); dispatch(setIgnoreMobileDisclaimer(true)); } }>
                        <CheckboxInput id='ignoremobile'
                            checked={ignore}
                            onChange={(e) => { setIgnore(!ignore); }}
                        >
                            I want to enter the site anyways.
                        </CheckboxInput>
                        <DefaultButton 
                            style={{margin: '4px', marginTop: '8px', width: '100%'}}
                            deactivated={!ignore}
                        >
                            Enter
                        </DefaultButton>
                    </form>
                </div>
                <div style={{flexGrow: 2}}/>
                <Footer/>
            </div>
        );
}