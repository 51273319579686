import { List as ListIcon } from '@phosphor-icons/react';
import './navbar.css';

function NavButton({onClick, clicked, icon, children}) {    
    return (
        <button className={'navbar-button' + (clicked ? ' clicked' : '')} onClick={onClick}>
            <div style={{
                height: '100%', 
                width: '24px',
                display: 'flex',
                alignItems: 'center'
            }}>
                {icon}
            </div>
            {children}
        </button>
    );
}

export function Navbar({ pages, openPageId, onOpen, retracted, setRetracted, footer=null }) {
    const navButtons = pages.map((page, i) => 
        <NavButton 
            key={i}
            onClick={() => onOpen(page)}
            clicked={openPageId === page.id}
            icon={page.icon}
        >
            { page.title }
        </NavButton>
    );
    const navbarClass = retracted ? ' retracted' : '';
    const navbarFooter = footer === null ? <></> : (
        <>
            <div style={{flexGrow: 1}}/>
            { footer }
        </>
    );
    return (
        <>
            <div className={'navbar-area' + navbarClass}/>
            <div className={'navbar' + navbarClass}>
                <div className='navbar-content'>
                    {navButtons}
                </div>
                {navbarFooter}
            </div>
            <div className='navbar-retract-button' onClick={() => setRetracted(!retracted)}>
                <ListIcon size={24}/>
            </div>
        </>
    );
}

export function NavContainer({ pages, openPageId, onOpen, retracted, setRetracted, footer=null, children }) {
    return (
        <div className='nav-container'>
            <Navbar pages={pages} openPageId={openPageId} onOpen={onOpen} retracted={retracted} setRetracted={setRetracted} footer={footer}/>
            <div className={'custom-scrollbar nav-container-content' + (retracted ? '' : ' non-exclusive')}>
                { children }
            </div>            
        </div>
    );
}