import React, { useState } from "react";
import { APP_FULL_ID } from "../../../constants/app";
import { LEGAL_LINKS as LINKS } from "../../../constants/links";
import * as Icons from '@phosphor-icons/react';
import './footer.css';

function Links() {
    // <a href="mailto:feedback@ambientsphere.com?subject=Ambient Sphere Demo Feedback">Report Feedback</a>
    return (
        <>
            <a href={LINKS.imprint} target='_blank' rel='noreferrer'>Imprint</a>
            <a href={LINKS.terms_of_service} target='_blank' rel='noreferrer'>Terms of Service</a>
            <a href={LINKS.privacy_policy} target='_blank' rel='noreferrer'>Privacy Policy</a>
        </>
    );
}

export function FooterStacked({style}){
    const [collapsed, setCollapsed] = useState(true);
    const collapseButton = collapsed ?
        <div className='depr-collapse-button'><Icons.CaretRight size={14}/></div> :
        <div className='depr-collapse-button'><Icons.CaretDown size={14}/></div>;
    const children = collapsed ?
        <div className='depr-collapsable-link-list collapsed'><div className='depr-footer-link-list'><Links/></div></div> :
        <div className='depr-collapsable-link-list stacked'><div className='depr-footer-link-list'><Links/></div></div>
    return (
        <div className='depr-footer stacked' style={style}>
            <div className='depr-head'>
                <div> { APP_FULL_ID } </div>
                <div className='depr-collapsable-link-list-header' onClick={() => { setCollapsed(!collapsed) }}>
                    { collapseButton }
                    <span>Legal links</span>
                </div>
            </div>
            <div className='depr-body'>
                {children}
            </div>
        </div>
    );
}