import LoadingSpinner from '../../../../components/shared/loading-spinner/loading_spinner';
import Footer from '../../../../components/shared/footer/footer';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APP_BASE_URL } from "../../../../constants/app";
import { postSendVerificationEmail, postVerifyEmail } from '../../../../api/account';
import { DefaultButton, OutlinedButton } from '../../../components/button/button';
import { PAGE_ROUTES } from '../../../../constants/page_routes';
import './email_verification.css';

function ApiConnect({children}) {
    return (
        <div className='email-verification-widget horizontal'>
            <span>{children}...</span> 
            <LoadingSpinner size={16}/>
        </div>
    );
}

function VerifySuccess() {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    return (
        <div className='email-verification-widget vertical'>
            <h4>Verification successful</h4>
            <span>
                Your email address has been successfully verified.
            </span>
            <OutlinedButton onClick={backCb} style={{width: '50%', marginTop: '8px'}}>Back to log in</OutlinedButton>
        </div>
    )
}

function VerifyCodeError({resendCb, expired=false}) {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    const reason = expired ? 
        <>The verification code you used has expired.</> :
        <>You have used an invalid verification code.</>;
    return (
        <div className='email-verification-widget vertical'>
            <h4>Verification failed</h4>
            <span>{reason}</span>
            <span>
                To gain a new code, click the button below.
                We will send you another verification mail right away.
            </span>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px', gap: '12px'}}>
                <DefaultButton onClick={resendCb} style={{width: '50%'}}>Resend email</DefaultButton>
                <OutlinedButton onClick={backCb} style={{width: '50%'}}>Back to log in</OutlinedButton>
            </div>
        </div>
    );
}

function UserNotFound({email}) {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    return (
        <div className='email-verification-widget vertical'>
            <h4>User not found</h4>
            <span>
                Could not find a registered account with your email address <span className='email-verification-email'>({email})</span>.
            </span>
            <OutlinedButton onClick={backCb} style={{width: '50%', marginTop: '8px'}}>Back to log in</OutlinedButton>
        </div>
    );
}

function SignupSplash({resendCb}) {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    return (
        <div className='email-verification-widget vertical'>
            <h4>Signup successful</h4>
            <span>
                In order to verify your email address we sent you an email containing a verification link.
                Please check your inbox.
            </span>
            <span>
                If you have not received an email from us, click the button below and we will resend the verification mail.
            </span>
            <span style={{font: 'var(--font-body-small)', color: 'var(--color-foreground-hinted)'}}>
                Don't forget to check your spam folder, just in case.
            </span>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px', gap: '12px'}}>
                <DefaultButton onClick={resendCb} style={{width: '50%'}}>Resend email</DefaultButton>
                <OutlinedButton onClick={backCb} style={{width: '50%'}}>Back to log in</OutlinedButton>
            </div>
        </div>
    );
}

function ResendSplash({resendCb}) {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    return (
        <div className='email-verification-widget vertical'>
            <h4>Email verification required</h4>
            <span>
                We have already sent you an email containing a verification link when you signed up.
                Please check your inbox.
            </span>
            <span>
                If you have not received an email from us, click the button below and we will resend the verification mail.
            </span>
            <span style={{font: 'var(--font-body-small)', color: 'var(--color-foreground-hinted)'}}>
                Don't forget to check your spam folder, just in case.
            </span>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px', gap: '12px'}}>
                <DefaultButton onClick={resendCb} style={{width: '50%'}}>Resend email</DefaultButton>
                <OutlinedButton onClick={backCb} style={{width: '50%'}}>Back to log in</OutlinedButton>
            </div>
        </div>
    );
}

function ResendSuccess({resendCb}) {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    return (
        <div className='email-verification-widget vertical'>
            <h4>Verification mail sent</h4>
            <span>
                If you have not received an email from us, click the button below and we will resend the verification mail.
            </span>
            <span style={{font: 'var(--font-body-small)', color: 'var(--color-foreground-hinted)'}}>
                Don't forget to check your spam folder, just in case.
            </span>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '8px', gap: '12px'}}>
                <DefaultButton onClick={resendCb} style={{width: '50%'}}>Resend email</DefaultButton>
                <OutlinedButton onClick={backCb} style={{width: '50%'}}>Back to log in</OutlinedButton>
            </div>
        </div>
    );
}

function UnknownError() {
    const navigate = useNavigate();
    const backCb = () => {
        navigate(PAGE_ROUTES.login);
    }
    return (
        <div className='email-verification-widget vertical'>
            <h4>Unknown Error</h4>    
            <span>
                An unknown error occured. Please try again later.
            </span>
            <OutlinedButton onClick={backCb} style={{width: '50%', marginTop: '8px'}}>Back to log in</OutlinedButton>
        </div>
    );
}

const PROCESS_STATE = {
    initial: 0,
    initialized: 1,
    api_connect: 2,
    success: 3,
    no_action: 4,
    err_user_not_found: 5,
    err_code_expired: 6,
    err_code_invalid: 7,
    err_unknown: 8
}

export default function EmailVerificationPage() {
    const [searchParams] = useSearchParams();
    const [pState, setPState] = useState(PROCESS_STATE.initial);
    
    const navigate = useNavigate();

    const signupToken = searchParams.get("signupSuccess");
    const email = searchParams.get("email");
    const authCode = searchParams.get("authcode");

    const signupSuccessMode = (signupToken !== null) && (email !== null) && !authCode;
    const resendMode = (email !== null) && !signupToken && !authCode;
    const verifyMode = (email !== null) && (authCode !== null) && !signupToken;
    const invalid = !(signupSuccessMode || resendMode || verifyMode);

    useEffect(() => {
        document.title = "Ambient Sphere | Email verification"
        if (pState === PROCESS_STATE.initial) {
            if (invalid) {
                navigate(APP_BASE_URL);
            }
            else if (verifyMode) {
                setPState(PROCESS_STATE.api_connect);
                const successHandler = () => { setPState(PROCESS_STATE.success) };
                const userNotFoundHandler = (m) => { setPState(PROCESS_STATE.err_user_not_found) };
                const codeExpiredHandler = (m) => { setPState(PROCESS_STATE.err_code_expired) };
                const codeInvalidHandler = (m) => { setPState(PROCESS_STATE.err_code_invalid) };
                const unknownErrorHandler = () => { setPState(PROCESS_STATE.err_unknown) };
                postVerifyEmail(
                    email, 
                    authCode, 
                    successHandler, 
                    userNotFoundHandler, 
                    codeExpiredHandler, 
                    codeInvalidHandler, 
                    unknownErrorHandler);
            }
            else {
                setPState(PROCESS_STATE.initialized);
            }
        }
    }, [authCode, email, invalid, navigate, pState, verifyMode]);

    const resendCb = () => {
        setPState(PROCESS_STATE.api_connect);
        const successHandler = () => { setPState(PROCESS_STATE.success); };
        const alreadyVerifiedHandler = () => { setPState(PROCESS_STATE.no_action); };
        const userNotFoundHandler = (m) => { setPState(PROCESS_STATE.err_user_not_found); };
        const unknownErrorHandler = () => { setPState(PROCESS_STATE.err_unknown); };
        postSendVerificationEmail(
            email,
            successHandler,
            alreadyVerifiedHandler,
            userNotFoundHandler,
            unknownErrorHandler);
    }

    const selectComponent = () => {
        if (verifyMode) {
            if (pState === PROCESS_STATE.initial || pState === PROCESS_STATE.initialized || pState === PROCESS_STATE.api_connect)
                return <ApiConnect>Verifying your email</ApiConnect>;
            else if (pState === PROCESS_STATE.success)
                return <VerifySuccess/>;
            else if (pState === PROCESS_STATE.err_code_expired)
                return <VerifyCodeError resendCb={resendCb} expired={true}/>;
            else if (pState === PROCESS_STATE.err_code_invalid)
                return <VerifyCodeError resendCb={resendCb} expired={false}/>;
        }
        else if (resendMode) {
            if (pState === PROCESS_STATE.initial || pState === PROCESS_STATE.initialized)
                return <ResendSplash resendCb={resendCb}/>;
            else if (pState === PROCESS_STATE.api_connect)
                return <ApiConnect>Sending verification email</ApiConnect>;
            else if (pState === PROCESS_STATE.success)
                return <ResendSuccess resendCb={resendCb}/>;
        }
        else if (signupSuccessMode) {
            if (pState === PROCESS_STATE.initial || pState === PROCESS_STATE.initialized)
                return <SignupSplash resendCb={resendCb}/>;
            else if (pState === PROCESS_STATE.api_connect)
                return <ApiConnect>Sending verification email</ApiConnect>;
            else if (pState === PROCESS_STATE.success)
                return <ResendSuccess resendCb={resendCb}/>;
        }
        if (pState === PROCESS_STATE.no_action)
            return <VerifySuccess/>;
        else if (pState === PROCESS_STATE.err_user_not_found)
            return (<UserNotFound email={email}/>);
        return <UnknownError/>;
    }
    const component = selectComponent();

    return (
        <div className='email-verification-page-container'>
            <div style={{flexGrow: 1}}/>
            {component}
            <div style={{flexGrow: 1}}/>
            <Footer/>
        </div>
    );
}