import { useEffect } from "react";
import { sessionContext } from "../contexts/session";

export default function useContextTab(context_id, additional_arg = undefined) {
    useEffect(() => {
        sessionContext.focusContext(context_id, additional_arg);
        return () => { sessionContext.focusContext(null); };
    }, [context_id, additional_arg]);
    return {
        setTitle: (t) => {
            sessionContext.setContextTitle(context_id, t);
        },
        setThumbnail: (t) => {
            sessionContext.setContextThumbnail(context_id, t);
        }
    }
}