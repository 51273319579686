import Checkbox from "../../../components/shared/checkbox/checkbox";
import { SubmitButton } from "../../../components/shared/buttons/buttons";
import { AnimatedBackground } from "../../../components/shared/animated-background/animated_background";
import { useEffect, useState } from "react";
import { TextBoxWide } from "../../../components/shared/textboxes/textboxes";
import { encodeURIComponentEx } from "../../../utils/string";
import { Link, useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "../../../constants/page_routes";
import { sessionContext } from "../../../contexts/session";
import { postLogin } from "../../../api/account";
import { Tooltip } from "../../../components/shared/tooltip/tooltip";
import { Question } from "@phosphor-icons/react";
import { FullLogoAlpha } from "../../../components/shared/logo/logo";
import LoadingSpinner from "../../../components/shared/loading-spinner/loading_spinner";
import FooterSlim from "../../../components/shared/footer/footer";
import './login.css';

export default function LoginPage() {
    useEffect(() => {
        document.title = "Ambient Sphere | Sign in"
    }, []);

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [rememberMe, setRememberMe] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordResetURL, setPasswordResetURL] = useState(PAGE_ROUTES.passwordReset);

    useEffect(() => {
        if (!user) {
            setPasswordResetURL(PAGE_ROUTES.passwordReset);
        }
        else {
            setPasswordResetURL(PAGE_ROUTES.passwordReset + '?email=' + encodeURIComponentEx(user));
        }
    }, [user])

    const clearErrorMsg = () => { setErrorMsg('') }
    const navigate = useNavigate();
    const onSubmitCb = (e) => {
        e.preventDefault();
        clearErrorMsg();
        if (loading) {
            return;
        }
        setLoading(true);
        const successHandler = sessionContext.login.bind(sessionContext);
        const incorrectEmailOrPasswordHandler = (m) => {
            setErrorMsg("Invalid email address or password.");
        };
        const unverifiedEmailHandler = (m) => {
            navigate(PAGE_ROUTES.verifyEmail + '?email=' + encodeURIComponentEx(user));
        };
        const unknownErrorHandler = () => {
            setErrorMsg("An unknown error occured. Please try again later.");
        };
        postLogin(
            user, 
            pwd, 
            rememberMe,
            successHandler, 
            incorrectEmailOrPasswordHandler, 
            unverifiedEmailHandler, 
            unknownErrorHandler,
            () => { setLoading(false) });
    }

    const errorMsgLabel = errorMsg !== '' ? 
        (<span style={{color: 'var(--col-attention)'}}>{errorMsg}</span>) : (<></>)
    const loadingLabel = loading ?
        (<div className="login-button-loading"><LoadingSpinner size={16}/></div>) : (<>Sign in</>);

    const vaildEntry = (user !== '' && pwd !== '');
    
    return (
        <>
            <AnimatedBackground filter='blur(1vh) opacity(33%)' vignette={false}/>
            <div className="login-page-container">
                <div style={{flexGrow: 1}}/>
                <div className="login-form-background">
                    <div>
                        <FullLogoAlpha style={{height: "40px", marginBottom:"8px"}}/>
                        <span style={{color: 'var(--col-fg-text-hint)'}}>
                            The software is work in progress. 
                            Please let us know if something breaks.
                        </span>
                    </div>
                    <form className="login-form" onSubmit={(e) => {if(vaildEntry){onSubmitCb(e)}} }>
                        <TextBoxWide 
                            id="username" 
                            value={user} 
                            labelText={"Email"} 
                            type="email" onChange={(e) => { setUser(e.target.value); clearErrorMsg(); }} 
                            required={true}
                        />
                        <div>
                            <TextBoxWide 
                                id="password" 
                                value={pwd} 
                                labelText={"Password"} 
                                type="password" 
                                onChange={(e) => { setPwd(e.target.value); clearErrorMsg(); }} 
                                required={true}
                            />
                            <Link className="login-reset-link" to={passwordResetURL}>Forgot your password?</Link>
                        </div>
                        <div style={{marginTop: 'var(--lay-space-h-short)'}}>
                            <SubmitButton style={{width: "100%"}} active={vaildEntry}>{loadingLabel}</SubmitButton>
                            <div style={{height: 'var(--lay-gap-v-connect)'}}/>
                            <Checkbox id="remember-me" checked={rememberMe} onChange={() => {setRememberMe(!rememberMe)}}> 
                                <div style={{display: 'flex'}}>
                                    <span>Remember me</span>
                                    <Tooltip style={{marginTop: "2px", marginLeft: "4px"}} tip={"Automatic logout after 90 days of inactivity."}>
                                        <Question size={16}></Question>
                                    </Tooltip>
                                </div>
                            </Checkbox>
                        </div>
                        {errorMsgLabel}
                    </form>
                    <div className="login-form-separator"/>
                    <div className="login-form-help">
                        <span style={{color: 'var(--col-fg-text-hint)'}}>Not signed up yet?</span>
                        <Link to={PAGE_ROUTES.signup}>Sign up</Link>
                    </div>
                </div>
                <div style={{flexGrow: 1}}/>
                <FooterSlim/>
            </div>
        </>
    );
}
