import bgPortal from '../../../assets/images/bg_portal_alt_colors.webp';
import { useSelector } from 'react-redux';
import { BACKGROUND_OPTIONS, BACKGROUND_OPTION_KEY } from '../../../constants/settings';
import './animated_background.css';

function ActualBackground({ src, filter, animated, vignette }) {
    const filterStyle = filter === '' ? {} : { filter: filter };
    const animatedStyle = animated ? { animationPlayState: 'running' } : { animationPlayState: 'paused' };
    const vignetteComp = vignette ? <div className="animated-background-shadow"/> : <></>;
    return (
        <div className="animated-background-container" style={ filterStyle }>
            {vignetteComp}
            <img src={src} alt="" className="animated-background-image" style={animatedStyle}/>
        </div>
    );
}

export function AnimatedBackgroundURL({ imageUrl, filter = '', animated = true, vignette = true }) {
    const setting = useSelector(state => state.session.appSettings[BACKGROUND_OPTION_KEY]);
    if (setting === BACKGROUND_OPTIONS.deactivated.val)
        return <></>;
    if (setting === BACKGROUND_OPTIONS.static.val)
        return <ActualBackground src={imageUrl} filter={filter} animated={false} vignette={vignette}/>; 
    return <ActualBackground src={imageUrl} filter={filter} animated={animated} vignette={vignette}/>;
}

export function AnimatedBackground({ filter = '', animated = true, vignette = true }) {
    const setting = useSelector(state => state.session.appSettings[BACKGROUND_OPTION_KEY]);
    if (setting === BACKGROUND_OPTIONS.deactivated.val)
        return <></>;
    if (setting === BACKGROUND_OPTIONS.static.val)
        return <ActualBackground src={bgPortal} filter={filter} animated={false} vignette={vignette}/>; 
    return <ActualBackground src={bgPortal} filter={filter} animated={animated} vignette={vignette}/>;
}