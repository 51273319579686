import Checkbox from "../../../components/shared/checkbox/checkbox";
import { SubmitButton } from "../../../components/shared/buttons/buttons";
import { AnimatedBackground } from "../../../components/shared/animated-background/animated_background";
import { useEffect, useState } from "react";
import { TextBoxWide } from "../../../components/shared/textboxes/textboxes";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { PAGE_ROUTES } from "../../../constants/page_routes";
import { FullLogoAlpha } from "../../../components/shared/logo/logo";
import { LEGAL_LINKS } from '../../../constants/links';
import { postSignup } from '../../../api/account';
import { newsletterPostSignup } from '../../../api/misc';
import { passwordRuleConformityHint, validateEmail, validatePasswordRuleConformity } from '../../../utils/security';
import Footer from '../../../components/shared/footer/footer';
import './signup.css';

function TermsOfServiceLink() {
    return (
        <a 
            href={LEGAL_LINKS.terms_of_service} 
            target='_blank' 
            rel='noreferrer'>
            Terms of Service
        </a>
    );
}

function PrivacyPolicyLink() {
    return (
        <a 
            href={LEGAL_LINKS.privacy_policy} 
            target='_blank' 
            rel='noreferrer'>
            Privacy Policy
        </a>
    );
}

function RequiredTag() {
    return (
        <span style={{
            color: "var(--col-fg-text-hint)",
            font: "var(--font-body-sm)",
            marginLeft: "var(--lay-gap-h-connect)"}}>
            (required)
        </span>
    );
}

function OptionalTag() {
    return (
        <span style={{
            color: "var(--col-fg-text-hint)",
            font: "var(--font-body-sm)",
            marginLeft: "var(--lay-gap-h-connect)"}}>
            (optional)
        </span>
    );
}

const SIGNUP_STATE = {
    ok: 0,
    err_invalid_email: 1,
    err_invalid_pwd: 2,
    err_account_collision: 3,
    err_unaccepted_terms_or_privacy: 4,
    err_unknown: 5
};

export default function SignUpPage() {
    useEffect(() => {
        document.title = "Ambient Sphere | Sign up"
    }, []);

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [repeatPwd, setRepeatPwd] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [newsletterChecked, setNewsletterChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [signupState, setSignupState] = useState(SIGNUP_STATE.ok);
    const [searchParams] = useSearchParams();
    const [pwdHints, setPwdHints] = useState([]);

    useEffect(() => {
        setSignupState(SIGNUP_STATE.ok);
    }, [email, pwd, repeatPwd, termsChecked, newsletterChecked])

    const formValidateEmail = (vEmail) => {
        if (signupState === SIGNUP_STATE.err_invalid_email ||
            signupState === SIGNUP_STATE.err_account_collision)
            return false;
        if (!vEmail)
            return true
        return validateEmail(vEmail);
    }

    const formValidationHintEmail = (vEmail) => {
        if (signupState === SIGNUP_STATE.err_invalid_email)
            return "Server signalled invalid email address.";
        else if (signupState === SIGNUP_STATE.err_account_collision)
            return "An account with this email address already exists.";
        return "Please enter a valid email address.";
    }

    const formValidatePassword = (vPwd) => {
        if (signupState === SIGNUP_STATE.err_invalid_pwd)
            return false;
        if (!vPwd)
            return true;
        return validatePasswordRuleConformity(vPwd);
    }

    const formValidationHintPassword = (vPwd) => {
        if (signupState === SIGNUP_STATE.err_invalid_pwd) {
            return (
                <>
                    {pwdHints.map((m, i) => <span key={i}>{m}&nbsp;</span>)}
                </>
            );
        }
        return passwordRuleConformityHint(vPwd);
    }

    const formValidateRepeatPassword = (vPwd) => {
        if (!vPwd)
            return true;
        return vPwd === pwd;
    }

    const formValidationHintRepeatPassword = (vPwd) => {
        return "Please enter the same password as above.";
    }

    const emailValidationOverwrite = formValidateEmail(email);
    const pwdValidationOverwrite = formValidatePassword(pwd);
    const repeatPwdValidationOverwrite = formValidateRepeatPassword(repeatPwd);
    const formInputValid = 
        emailValidationOverwrite && email !== '' &&
        pwdValidationOverwrite && pwd !== '' &&
        repeatPwdValidationOverwrite && repeatPwd !== '' &&
        termsChecked;

    const signupErrorMessage = () => {
        if (signupState === SIGNUP_STATE.err_unknown)
            return "An unknown error occured. Please try again later.";
        else
            return "";
    }
    const errorMsgLabel = signupState === SIGNUP_STATE.err_unknown ? 
          (<span style={{color: 'var(--col-attention)'}}>{signupErrorMessage()}</span>)
        : (<></>);

    const navigate = useNavigate();

    const onSubmitCb = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        const successUrl = PAGE_ROUTES.verifyEmail + '?signupSuccess&email=' + email;
        const successHandler = () => { 
            if (newsletterChecked) {
                newsletterPostSignup(email);
            }
            navigate(successUrl); 
        };
        const invalidEmailHandler = (m) => { setSignupState(SIGNUP_STATE.err_invalid_email); }
        const invalidPwdHandler = (m) => { setPwdHints(m); setSignupState(SIGNUP_STATE.err_invalid_pwd); }
        const accountCollisionHandler = (m) => { setSignupState(SIGNUP_STATE.err_account_collision); }
        const termsPrivacyNotAcceptedHandler = (m) => { setSignupState(SIGNUP_STATE.err_unaccepted_terms_or_privacy); }
        const unknownErrorHandler = () => { setSignupState(SIGNUP_STATE.err_unknown); }
        const finallyHandler = () => { setLoading(false); }
        const origin = searchParams.get('origin');
        postSignup(
            email,
            pwd,
            termsChecked,
            termsChecked,
            !origin ? "" : origin,
            successHandler,
            invalidEmailHandler,
            invalidPwdHandler,
            accountCollisionHandler,
            termsPrivacyNotAcceptedHandler,
            termsPrivacyNotAcceptedHandler,
            unknownErrorHandler,
            finallyHandler);
    }

    return (
        <>
            <AnimatedBackground filter='blur(1vh) opacity(33%)' vignette={false}/>
            <div className="signup-page-container">
                <div style={{flexGrow: 1}}/>
                <div className="signup-form-background">
                    <FullLogoAlpha style={{height: "40px", marginBottom:"8px"}}/>
                    <form className="signup-form" onSubmit={onSubmitCb}>
                        <TextBoxWide type="email" 
                            id="email" 
                            labelText="Email"
                            onChange={(e) => {setEmail(e.target.value)}}
                            value={email}
                            invalid={!emailValidationOverwrite}
                            invalidText={formValidationHintEmail(email)}
                            required={true}/>
                        <TextBoxWide id="password" 
                            type="password" 
                            labelText="Password"
                            onChange={(e) => { setPwd(e.target.value); }}
                            value={pwd} 
                            invalid={!pwdValidationOverwrite}
                            invalidText={formValidationHintPassword(pwd)}
                            placeholder="Requires 8+ characters"
                            required={true}/>
                        <TextBoxWide id="repeat-password" 
                            type="password" 
                            labelText="Verify Password"
                            onChange={(e) => { setRepeatPwd(e.target.value); }}
                            value={repeatPwd} 
                            invalid={!repeatPwdValidationOverwrite}
                            invalidText={formValidationHintRepeatPassword(pwd)}
                            placeholder="Repeat password"
                            required={true}/>
                        <Checkbox
                            id="terms-of-service"
                            checked={termsChecked}
                            invalid={signupState === SIGNUP_STATE.err_unaccepted_terms_or_privacy}
                            invalidText="Aggreeing to the terms of service and privacy policy is mandatory."
                            onChange={() => { setTermsChecked((prev) => !prev) }}>
                            I agree to the <TermsOfServiceLink/> and the <PrivacyPolicyLink/><RequiredTag/>
                        </Checkbox>
                        <Checkbox
                            id="newsletter-signup"
                            checked={newsletterChecked}
                            onChange={() => { setNewsletterChecked((prev) => !prev) }}>
                            I want to receive the Ambient Sphere newsletter<OptionalTag/>
                        </Checkbox>
                        {errorMsgLabel}
                        <SubmitButton
                            style={{width: "100%"}}
                            active={formInputValid}>
                                Sign up
                        </SubmitButton>
                    </form>
                    <div className="signup-form-separator"></div>
                    <div className="signup-form-help">
                        <span style={{color: 'var(--col-fg-text-hint)'}}>Already have an account?</span>
                        <Link to={PAGE_ROUTES.login}>Sign in</Link>
                    </div>
                </div>
                <div style={{flexGrow: 1}}/>
                <Footer/>
            </div>
        </>
    );
}
