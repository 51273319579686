import AtmosphereCard from '../atmosphere-card/atmosphere_card';
import LoadingWidget from '../loading-widget/loading_widget';
import { useNavigate } from 'react-router-dom';
import { webPlayerContext } from '../../../contexts/web_player';

function AtmosphereList({ atmospheres }) {
    const navigate = useNavigate();
    const atms = atmospheres.map((atm, i) => 
        <AtmosphereCard 
            key={i} 
            onClick={() => { webPlayerContext.openAtmosphere(atm, navigate) }}
            meta={atm.atm_meta}/>
    );
    return <>{atms}</>
}

export default function AtmosphereContentWrapper({ loading, atmospheres, error}) {
    if (loading)
        return <LoadingWidget centered={false}/>;
    else if (error !== null)
        return <span style={{color: 'var(--color-error)', margin: '0px 24px'}}>Could not load atmospheres: {error}</span>
    else if (atmospheres.length === 0)
        return <span style={{color: 'var(--color-foreground-hinted)', margin: '0px 24px'}}>No content</span>
    else
        return <AtmosphereList atmospheres={atmospheres}/>;
}