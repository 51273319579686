import LoadingSpinner from "../../../../components/shared/loading-spinner/loading_spinner";
import Footer from "../../../../components/shared/footer/footer";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { APP_BASE_URL } from "../../../../constants/app";
import { PAGE_ROUTES } from "../../../../constants/page_routes";
import { DefaultInput } from "../../../components/form-input/form_input";
import { passwordRuleConformityHint, validateEmail, validatePasswordRuleConformity } from "../../../../utils/security";
import { DefaultButton } from "../../../components/button/button";
import { postResetPasswordSend, postResetPasswordSet } from "../../../../api/account";
import './password_reset.css';

function SendForm({ userNotFound = false, loading = false, email, setEmail, sendCB }) {
    const formValidateEmail = (vEmail) => {
        if (userNotFound) {
            return false;
        }
        if(!vEmail) {
            return true;
        };
        return validateEmail(vEmail);
    }
    const formValidateEmailHint = () => {
        if (userNotFound) {
            return "Could not find account with this email address.";
        }
        return "Please enter a valid email address.";
    }
    const loadingLabel = loading ?
          (<div className="login-form-footer-loading-spinner"><LoadingSpinner size={16}/></div>)
        : (<></>);
    const validEmail = formValidateEmail(email);
    return (
        <div className="password-reset-widget">
            <form className='password-reset-form' onSubmit={sendCB}>
                <h4>Password Reset</h4>
                <p style={{margin: '0px 0px 8px 0px'}}>
                    Please enter your account email address below and we will send you an email containing a link to reset your password.
                </p>
                <DefaultInput 
                    type='text'
                    id='email'
                    autoComplete='off'
                    onChange={(e) => {setEmail(e.target.value);}}
                    value={email}
                    validationOverwrite={validEmail}
                    validationHint={formValidateEmailHint}
                    placeholder='Email'/>
                <DefaultButton
                    style={{width: '100%'}}
                    deactivated={!validEmail || !email}>
                    Send Link
                </DefaultButton>
                <div style={{width: '100%', display:'flex', flexDirection:'row'}}>
                    <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                        Back to <Link to={PAGE_ROUTES.login}>log in</Link>
                    </span>
                    <div style={{flexGrow: 1}}/>
                    {loadingLabel}
                </div>
            </form>
        </div>
    );
}

function SendSuccess() {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <h4>Email Sent</h4>    
            <span>
                We have sent you an email with instructions to reset your password.
                Please check your inbox.
            </span>
            <span style={{font: 'var(--font-body-small)', color: 'var(--color-foreground-hinted)', marginBottom: '4px'}}>
                If you do not seem to have received the email, please check your spam folder as well, just in case.
            </span>
            <div style={{borderTop:'1px solid var(--color-bg-elem-dp2-glass)', width: '100%'}}/>
            <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                Back to <Link to={PAGE_ROUTES.login}>log in</Link>
            </span>
        </div>
    );
}

function ResetForm({ invalidPwd = false, invalidPwdMessages = ["Invalid Password."], loading, pwd, setPwd, setCB }) {
    const [repeatPwd, setRepeatPwd] = useState('');    
    const formValidatePassword = (vPwd) => {
        if (invalidPwd)
            return false;
        if (!vPwd)
            return true;
        return validatePasswordRuleConformity(vPwd);
    }
    const formValidationHintPassword = (vPwd) => {
        if (invalidPwd) {
            return (
                <>
                    {invalidPwdMessages.map((m, i) => <span key={i}>{m}&nbsp;</span>)}
                </>
            );
        }
        return passwordRuleConformityHint(vPwd);
    }

    const formValidateRepeatPassword = (vPwd) => {
        if (!vPwd)
            return true;
        return vPwd === pwd;
    }
    const formValidationHintRepeatPassword = (vPwd) => {
        return "Please enter the same password as above.";
    }

    const pwdValidationOverwrite = formValidatePassword(pwd);
    const repeatPwdValidationOverwrite = formValidateRepeatPassword(repeatPwd);
    const formInputValid = 
        pwdValidationOverwrite && 
        repeatPwdValidationOverwrite &&
        !!pwd && !!repeatPwd;
    const loadingLabel = loading ?
          (<div className="login-form-footer-loading-spinner"><LoadingSpinner size={16}/></div>)
        : (<></>);
    return (
        <div className="password-reset-widget">
            <form className='password-reset-form' onSubmit={setCB}>
                <h4>Password Reset</h4>
                <p style={{margin: '0px 0px 8px 0px'}}>
                    To reset your password, enter a new password for your account.
                </p>
                <DefaultInput 
                    type='password'
                    id='reset-password'
                    autoComplete='off'
                    onChange={(e) => {setPwd(e.target.value);}}
                    value={pwd}
                    validationOverwrite={pwdValidationOverwrite}
                    validationHint={formValidationHintPassword}
                    placeholder='Password'/>
                <DefaultInput 
                    type='password'
                    id='repeat-reset-password'
                    autoComplete='off'
                    onChange={(e) => {setRepeatPwd(e.target.value);}}
                    value={repeatPwd}
                    validationOverwrite={repeatPwdValidationOverwrite}
                    validationHint={formValidationHintRepeatPassword}
                    placeholder='Repeat Password'/>
                <DefaultButton
                    style={{width: '100%'}}
                    deactivated={!formInputValid}>
                    Confirm
                </DefaultButton>
                <div style={{width: '100%', display:'flex', flexDirection:'row'}}>
                    <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                        Back to <Link to={PAGE_ROUTES.login}>log in</Link>
                    </span>
                    <div style={{flexGrow: 1}}/>
                    {loadingLabel}
                </div>
            </form>
        </div>
    );
}

function ResetSuccess() {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <h4>Success</h4>    
            <span>
                Your password has been successfully reset.
                You can now log into your account using your new password.
            </span>
            <div style={{borderTop:'1px solid var(--color-bg-elem-dp2-glass)', width: '100%'}}/>
            <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                Back to <Link to={PAGE_ROUTES.login}>log in</Link>
            </span>
        </div>
    );
}

function UnknownUser({email}) {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <h4>Unknown Email</h4>    
            <span style={{marginBottom: '4px'}}>
                We could not find an account with this email address <span style={{color:'var(--color-foreground-hinted)'}}>({email})</span>.
            </span>
            <div style={{borderTop:'1px solid var(--color-bg-elem-dp2-glass)', width: '100%'}}/>
            <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                Back to <Link to={PAGE_ROUTES.login}>log in</Link>
            </span>
        </div>
    );
}


function CodeExpired({resendCB}) {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <form className='password-reset-form' onSubmit={resendCB}>
                <h4>Link Expired</h4>    
                <span>
                    The link you used to reset your password expired.
                    To receive a new link, click on the button below.
                </span>
                <DefaultButton
                    style={{width: '100%'}}>
                    Send a new Link
                </DefaultButton>
                <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                    Back to <Link to={PAGE_ROUTES.login}>log in</Link>
                </span>
            </form>
        </div>
    );
}

function CodeInvalid({resendCB}) {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <form className='password-reset-form' onSubmit={resendCB}>
                <h4>Invalid Link</h4>    
                <span>
                    The link you used to reset your password is invalid.
                    Make sure you used the correct link. 
                </span>
                <span>
                    To receive another password reset email, click the button below.
                </span>
                <DefaultButton
                    style={{width: '100%'}}>
                    Resend Email
                </DefaultButton>
                <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                    Back to <Link to={PAGE_ROUTES.login}>log in</Link>
                </span>
            </form>
        </div>
    );
}

function LockedError({ seconds, email }) {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <h4>Password Reset Locked</h4>    
            <span>
                The ability to reset the password of your account <span style={{color:'var(--color-foreground-hinted)'}}>({email})</span> has been temporarily disabled due to too many failed attempts.
            </span>
            <span style={{marginBottom: '4px'}}>
                Unlocks in: <span style={{color: 'var(--color-foreground-emph)'}}>{seconds}</span> seconds
            </span>
            <div style={{borderTop:'1px solid var(--color-bg-elem-dp2-glass)', width: '100%'}}/>
            <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                Back to <Link to={PAGE_ROUTES.login}>log in</Link>
            </span>
        </div>
    );
}

function UnknownError() {
    return (
        <div className='password-reset-widget' style={{display:'flex', flexDirection:'column', gap:'8px'}}>
            <h4>Unknown Error</h4>    
            <span style={{marginBottom: '4px'}}>
                An unknown error occured. Please try again later.
            </span>
            <div style={{borderTop:'1px solid var(--color-bg-elem-dp2-glass)', width: '100%'}}/>
            <span style={{color: 'var(--color-foreground-hinted)', width: '100%', fontSize: '11pt'}}>
                Back to <Link to={PAGE_ROUTES.login}>log in</Link>
            </span>
        </div>
    );
}

const PROCESS_STATE = {
    send: 0,
    send_unknown_user: 1,
    send_success: 2,
    reset: 3,
    reset_invalid_password: 4,
    reset_code_invalid: 5,
    reset_code_expired: 6,
    reset_unknown_user: 7,
    reset_success: 8,
    err_locked: 9,
    err_unknown: 10
};

export default function PasswordResetPage() {
    const [searchParams] = useSearchParams();
    const initialEmail = searchParams.get("email");
    const authCode = searchParams.get("authcode");

    const [email, setEmail] = useState(!initialEmail ? '' : initialEmail);
    const [lockedSeconds, setLockedSeconds] = useState(0);
    const [loading, setLoading] = useState(false);
    const [pwd, setPwd] = useState('');
    const [pwdHints, setPwdHints] = useState('');
    
    const sendMode = !authCode;
    const valid = sendMode || (email !== null && authCode !== null);
    const navigate = useNavigate();
    
    const [pState, setPState] = useState(sendMode ? PROCESS_STATE.send : PROCESS_STATE.reset);
    useEffect(() => {
        if (!valid) {
            navigate(APP_BASE_URL + PAGE_ROUTES.login);
        }
        document.title = "Ambient Sphere | Password reset";
    }, [navigate, valid]);

    const createLockedTimer = (seconds_left) => {
        var timer = seconds_left;
        var resetToSendMode = sendMode;
        var intervalHandle = setInterval(() => {
            timer = timer - 1;
            setLockedSeconds(timer);
            if (timer <= 0) {
                clearInterval(intervalHandle);
                if (resetToSendMode) {
                    setPState(PROCESS_STATE.send);
                }
                else {
                    setPState(PROCESS_STATE.reset);
                }
            }
        }, 1000);
    }

    const onSend = (e) => {
        e.preventDefault();
        setLoading(true);
        const successHandler = () => { setPState(PROCESS_STATE.send_success); };
        const userNotFoundHandler = (m) => { setPState(PROCESS_STATE.send_unknown_user); };
        const lockedHandler = (m, seconds_left) => {
            setPState(PROCESS_STATE.err_locked);
            setLockedSeconds(seconds_left);
            createLockedTimer(seconds_left);
        };
        const unknownErrorHandler = () => { setPState(PROCESS_STATE.err_unknown); };
        const finallyHandler = () => { setLoading(false); };
        postResetPasswordSend(
            email,
            successHandler,
            userNotFoundHandler,
            lockedHandler,
            unknownErrorHandler,
            finallyHandler);
    }

    const onSet = (e) => {
        e.preventDefault();
        setLoading(true);
        const successHandler = () => { setPState(PROCESS_STATE.reset_success) };
        const userNotFoundHandler = () => { setPState(PROCESS_STATE.reset_unknown_user) };
        const lockedHandler = (m, seconds_left) => {
            setPState(PROCESS_STATE.err_locked);
            setLockedSeconds(seconds_left);
            createLockedTimer(seconds_left);
        };
        const invalidPasswordHandler = (m) => { 
            setPState(PROCESS_STATE.reset_invalid_password);
            setPwdHints(m);
        };
        const resetCodeExpiredHandler = () => { setPState(PROCESS_STATE.reset_code_expired) };
        const resetCodeInvalidHandler = () => { setPState(PROCESS_STATE.reset_code_invalid) };
        const unknownErrorHandler = () => { setPState(PROCESS_STATE.err_unknown); };
        const finallyHandler = () => { setLoading(false); };
        postResetPasswordSet(
            email,
            pwd,
            authCode,
            successHandler,
            userNotFoundHandler,
            lockedHandler,
            invalidPasswordHandler,
            resetCodeExpiredHandler,
            resetCodeInvalidHandler,
            unknownErrorHandler,
            finallyHandler);
    }

    const onResend = (e) => {
        e.preventDefault();
        const successHandler = () => { setPState(PROCESS_STATE.send_success); };
        const userNotFoundHandler = (m) => { setPState(PROCESS_STATE.err_unknown); };
        const lockedHandler = (m, seconds_left) => { setPState(PROCESS_STATE.err_unknown); };
        const unknownErrorHandler = () => { setPState(PROCESS_STATE.err_unknown); };
        const finallyHandler = () => { };
        postResetPasswordSend(
            email,
            successHandler,
            userNotFoundHandler,
            lockedHandler,
            unknownErrorHandler,
            finallyHandler);
    }

    const sendFormSetEmailCB = (e) => {
        if (pState !== PROCESS_STATE.send)
            setPState(PROCESS_STATE.send);
        setEmail(e);
    }

    const setFormSetPwdCB = (e) => {
        if (pState !== PROCESS_STATE.reset)
            setPState(PROCESS_STATE.reset);
        setPwd(e);
    }

    const selectComponent = () => {
        if (pState === PROCESS_STATE.send || pState === PROCESS_STATE.send_unknown_user) {
            return <SendForm 
                userNotFound={pState === PROCESS_STATE.send_unknown_user} 
                loading={loading} 
                email={email} 
                setEmail={sendFormSetEmailCB} 
                sendCB={onSend}/>;
        }
        else if (pState === PROCESS_STATE.send_success) {
            return <SendSuccess/>;
        }
        else if (pState === PROCESS_STATE.reset || pState === PROCESS_STATE.reset_invalid_password) {
            return <ResetForm
            invalidPwd={pState === PROCESS_STATE.reset_invalid_password}
            invalidPwdMessages={pwdHints}
            loading={loading}
            pwd={pwd}
            setPwd={setFormSetPwdCB}
            setCB={onSet}/>;
        }
        else if (pState === PROCESS_STATE.reset_unknown_user) {
            return <UnknownUser email={email}/>;
        }
        else if (pState === PROCESS_STATE.reset_code_expired) {
            return <CodeExpired resendCB={onResend}/>;
        }
        else if (pState === PROCESS_STATE.reset_code_invalid) {
            return <CodeInvalid resendCB={onResend}/>;
        }
        else if (pState === PROCESS_STATE.reset_success) {
            return <ResetSuccess/>;
        }
        else if (pState === PROCESS_STATE.err_locked) {
            return <LockedError seconds={lockedSeconds} email={email}/>;
        }
        
        return <UnknownError/>;

    }
    
    const component = selectComponent();

    return (
        <div className='password-reset-page-container'>
            <div style={{flexGrow: 1}}/>
            { component }
            <div style={{flexGrow: 1}}/>
            <Footer/>
        </div>
    )
}