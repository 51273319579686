import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSurveyMeta } from "../../store/slices/hub";
import { getSurveyList } from "../../api/user";

export default function useSurveyMetaList() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const loadedSurveys = useSelector((state) => state.hub.surveyMeta);
    const dispatch = useDispatch();
    useEffect(() => {
        if (error === null && loadedSurveys === null) {
            setLoading(true);
            const successHandler = (surveyMeta) => {
                dispatch(setSurveyMeta(surveyMeta));
            }
            const permissionDeniedHandler = () => {
                setError("Permission denied.");
            }
            const unknownError = () => {
                setError('Unknown error.');
            }
            const finallyHandler = () => {
                setLoading(false);
            }
            getSurveyList(
                successHandler,
                permissionDeniedHandler,
                unknownError,
                finallyHandler);
        }
    }, [error, loadedSurveys, dispatch]);
    return [
        loading,
        loadedSurveys,
        error
    ];    
}