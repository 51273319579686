import LoginPage from "../pages/auth/login/login";
import SignupPage from "../pages/auth/signup/signup";
import PasswordResetPage from "../deprecated/pages/auth/password-reset/password_reset";
import EmailVerificationPage from "../deprecated/pages/auth/email-verification/email_verification";
import { Route } from "react-router-dom";
import { PAGE_ROUTES } from "../constants/page_routes";
import { CondRouteNotLoggedIn } from "./shared/conditional_auth";

export default function RoutesAuth() {
    return (
        <>
            <Route element={<CondRouteNotLoggedIn alternative_target={PAGE_ROUTES.main}/>}>
                <Route path={PAGE_ROUTES.signup} element={(<SignupPage/>)}/>
                <Route path={PAGE_ROUTES.login} element={(<LoginPage/>)}/>
                <Route path={PAGE_ROUTES.verifyEmail} element={(<EmailVerificationPage/>)}/>
                <Route path={PAGE_ROUTES.passwordReset} element={(<PasswordResetPage/>)}/>
            </Route>
        </>
    );
}