import logo from '../../../assets/images/logo.svg';
import UserControl from './user_control';
import Tabs from './tabs';
import { useDispatch, useSelector } from 'react-redux';
import './header.css';
import { OutlinedButton } from '../button/button';
import { setFeedbackOverlay } from '../../../store/slices/session';

export default function Header() {
    const loggedIn = useSelector(state => state.session.loggedIn);
    const showFeedbackForm = useSelector((state => state.session.overlayFeedbackForm));
    const disptach = useDispatch();

    const Logo = (
        <img src={logo} alt="" style={{border: '4px solid transparent', boxSizing: 'border-box', height: '100%', width: 'var(--size-header-height)'}}/>
    );
    const Feedback = !loggedIn ? <></> : (
        <OutlinedButton style={{ margin: '4px', display: 'flex', alignItems: 'center' }}
            onClick={() => {if(!showFeedbackForm) disptach(setFeedbackOverlay(true));}}>
            Give Feedback
        </OutlinedButton>
    )
    const LoggedInNavArea = !loggedIn ? <></> : (
            <>
                <Tabs/>
                <UserControl/>
            </>
        );
    return (
        <div className='header'>
            { Logo }
            { Feedback }
            <div style={{flexGrow: 1}}/>
            { LoggedInNavArea }
        </div>
    );
}
