import SocialLinks from '../../components/social-links/social_links';
import Footer from '../../../components/shared/footer/footer';
import { AnimatedBackground } from '../../../components/shared/animated-background/animated_background';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { OutlinedButton } from '../../components/button/button';
import { PAGE_ROUTES } from '../../../constants/page_routes';
import { useEffect } from 'react';
import { CloudX, LinkBreak } from '@phosphor-icons/react';
import './status_page.css';

function StatusSeparator() {
    return <div style={{height: '1px', width: '100%', background: 'var(--color-bg-elem-dp2)', margin: '8px 0px'}}/>;
}

function StatusPage({icon, code, title, children}) {
    useEffect(() => {
        document.title = "Ambient Sphere | " + code + " " + title;
    });
    return (
        <>
            <AnimatedBackground filter='blur(8px) opacity(33%)'/>
            <div className='status-page-container'>
                <div style={{flexGrow: 1}}/>
                <div className='status-message-container'>
                    <h1 style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {icon}&nbsp;
                        {code}
                    </h1>
                    <h3 style={{textAlign: 'center'}}>{title}</h3>
                    {children}
                </div>
                <div style={{flexGrow: 1}}/>
                <Footer/>
            </div>
        </>
    );
}

export function StatusPage404() {
    const loggedIn = useSelector(state => state.session.loggedIn);
    const navigate = useNavigate();
    const onClick = () => {
        navigate(loggedIn ? PAGE_ROUTES.main : PAGE_ROUTES.login);
    }
    return (
        <StatusPage 
            icon={<LinkBreak/>}
            code='404' 
            title='You are traveling on a lost path'
        >
            <StatusSeparator/>
            <div>
                We could not locate what you are looking for.
                This might be due to a broken link or a missing resource.
            </div>
            <div style={{height: '4px'}}/>
            <div>
                Please try something else or <a href='mailto:contact@ambientsphere.com'>contact</a> us if you think this is a mistake.
            </div>
            <StatusSeparator/>
            <div style={{height: '4px'}}/>
            <OutlinedButton style={{width: '100%'}} onClick={onClick}>
                {loggedIn ? 'Back to Hub' : 'Back to Log In'}
            </OutlinedButton>
        </StatusPage>
    );
}

export function StatusPage503() {
    return (
        <StatusPage 
            icon={<CloudX/>}
            code='503' 
            title='Service unavailable'
        >
            <StatusSeparator/>
            <div>
                It appears that the goblins have breached our server.
                We are working hard on fixing it to bring Ambient Sphere back online.
            </div>
            <div style={{height: '4px'}}/>
            <div>
                If you want to be kept updated on the situation, check our socials.
                Links are below.
            </div>
            <StatusSeparator/>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <SocialLinks/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Link to={PAGE_ROUTES.main}>Try out if the problem remains</Link>
            </div>
        </StatusPage>
    );
}