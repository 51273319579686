import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewsMeta } from "../../store/slices/hub";
import { getNewsList } from "../../api/data";

export default function useNewsMetaList() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const loadedNews = useSelector((state) => state.hub.newsMeta);
    const dispatch = useDispatch();
    useEffect(() => {
        if (error === null && loadedNews === null) {
            setLoading(true);
            const successHandler = (news) => {
                dispatch(setNewsMeta(news));
            }
            const unknownError = () => {
                setError('Unknown error.');
            }
            const finallyHandler = () => {
                setLoading(false);
            }
            getNewsList(
                30,
                successHandler,
                unknownError,
                finallyHandler);
        }
    }, [error, loadedNews, dispatch]);
    return [
        loading,
        loadedNews,
        error
    ];    
}