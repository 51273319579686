import axios from 'axios';
import { BACKEND_API_BASE_URL, CMS_API_BASE_URL, CMS_API_KEY, ANALYTICS_API_BASE_URL } from '../../constants/api';
import { addAuth } from './auth';

export const cmsApi = axios.create({
    baseURL: CMS_API_BASE_URL
});

cmsApi.interceptors.request.use(
    (config) => { 
        config.headers['api-key'] = CMS_API_KEY;
        return config;
    },
    (error) => { return Promise.reject(error); }
);

export const backendApiPublic = axios.create({
    baseURL: BACKEND_API_BASE_URL
});

export const backendApiCredentials = axios.create({
    baseURL: BACKEND_API_BASE_URL,
    withCredentials: true
});

export const backendApiLoggedIn = axios.create({
    baseURL: BACKEND_API_BASE_URL
});

export const backendAnalytics = axios.create({
    baseURL: ANALYTICS_API_BASE_URL
})

addAuth(backendApiLoggedIn, backendApiLoggedIn, backendApiCredentials)
addAuth(backendAnalytics, backendApiLoggedIn, backendApiCredentials)