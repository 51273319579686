import LoadingSpinner from "../../../components/shared/loading-spinner/loading_spinner";

export default function LoadingWidget({centered = true}) {
    const centeredStyle = !centered ? {} : { justifyContent: 'center' };
    return (
        <div style={{
            width: '100%', 
            height: '100%', 
            display: 'flex',
            padding: '0px 24px',
            boxSizing: 'border-box',
            alignItems: 'center', 
            ...centeredStyle
        }}>
            <span style={{marginRight: '8px'}}>Loading</span>
            <LoadingSpinner size={16}/>
        </div>
    );
}