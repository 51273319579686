import AtmosphereContentWrapper from '../../../../components/hub/atmosphere_content_wrapper';
import LoadingWidget from '../../../../components/loading-widget/loading_widget';
import useAtmosphereMetaList from '../../../../../hooks/hub/useAtmosphereMetaList';
import { Info as InfoIcon } from '@phosphor-icons/react';
import './library.css';

export default function HubSubpageLibrary() {
    const [atmLoading, loadedAtmospheres, atmError] = useAtmosphereMetaList();
    if (atmLoading) {
        return <LoadingWidget/>;
    }    
    const normalAtms = <AtmosphereContentWrapper loading={atmLoading} atmospheres={loadedAtmospheres?.normal} error={atmError}/>;
    return (
        <div className='hub-subpage-library'>
            <h4 style={{color: 'var(--color-foreground-hinted)'}}>Coming Soon</h4>
            <div className='info-box'>
                <div className='info-icon'>
                    <InfoIcon size={20}/>
                </div>
                <div className='info-desc'>
                    <span>
                        This is your library.
                        This is the place where you'll find your favored atmospheres as well as your own creations.
                    </span>
                    <span>
                        During the alpha phase, we'll show you all functioning atmospheres here.
                    </span>
                </div>
            </div>
            <h3>Alpha Atmospheres</h3>
            <div className='atm-container'>
                { normalAtms }  
            </div>

        </div>
    );
}