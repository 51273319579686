import './tag.css';

export default function Tag({ tag }) {
    return (
        <div className='tag' style={{backgroundColor: tag.c}}>
            <span>{tag.t}</span>
        </div>
    );
}

export function XlTag({ tag }) {
    return (
        <div className='xl-tag' style={{backgroundColor: tag.c}}>
            <span>{tag.t}</span>
        </div>
    );
}