import React, { Suspense } from 'react';
import { Route } from "react-router-dom";
import { PAGE_ROUTES } from "../constants/page_routes";
import { CondRouteLoggedIn } from "./shared/conditional_auth";
import { EnsureNoRequiredActions, EnsurePermanentContexts } from "./shared/session";
import MobileDisclaimer from "../deprecated/pages/misc/mobile-disclaimer/mobile_disclaimer";
import SurveyPage from "../deprecated/pages/content/survey/survey";
import SettingsPage from "../deprecated/pages/account/settings/settings";
import HubPage from "../deprecated/pages/content/hub/hub";
import WebPlayerPage from "../deprecated/pages/content/web-player/web_player";
import LoadingWidget from '../deprecated/components/loading-widget/loading_widget';

//const WebPlayPageLazy = React.lazy(() => import("../deprecated/pages/content/web-player/web_player"));
//const WebPlayerPage = () => (
//    <Suspense fallback={<LoadingWidget/>}>
//        <WebPlayPageLazy/>
//    </Suspense>
//);

const NewsReaderPageLazy = React.lazy(() => import("../deprecated/pages/content/news-reader/news_reader"));
const NewsReader = () => (
    <Suspense fallback={<LoadingWidget/>}>
        <NewsReaderPageLazy/>
    </Suspense>
);

export default function RoutesContent() {
    return (
        <Route element={<CondRouteLoggedIn alternative_target={PAGE_ROUTES.login}/>}>
            <Route element={<EnsurePermanentContexts/>}>
                <Route path={PAGE_ROUTES.settings} element={<SettingsPage/>}/>
                <Route element={<MobileDisclaimer/>}>
                    <Route element={<EnsureNoRequiredActions/>}>
                        <Route path={PAGE_ROUTES.main} element={<HubPage/>}/>
                        <Route path={PAGE_ROUTES.atmosphere} element={<WebPlayerPage/>}/>
                        <Route path={PAGE_ROUTES.newsReader} element={<NewsReader/>}/>
                        <Route path={PAGE_ROUTES.survey} element={<SurveyPage/>}/>
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}