import { useState } from "react";
import { DropdownBox, DropdownElement } from "../../../../components/dropdown/dropdown";
import { FormSeparator, FormVSpace } from "../../../../components/structural/structural";
import { useDispatch, useSelector } from "react-redux";
import { BACKGROUND_OPTIONS, BACKGROUND_OPTION_KEY } from "../../../../../constants/settings";
import { optionByValue } from "../../../../../utils/settings";
import { DefaultButton } from "../../../../components/button/button";
import { setAppSettings } from "../../../../../store/slices/session";

function Info({children}) {
    return (
        <>
            <div style={{
                color: 'var(--color-foreground-hinted)', 
                font: 'var(--font-body-medium)'
            }}>
                {children}
            </div>
            <FormVSpace/>
        </>
    );
}

function BackgroundSettings({ settings, setSettings }) {
    const [dropAnimationOpen, setDropAnimationOpen] = useState();
    const currentSetting = useSelector(state => state.session.appSettings.backgroundSettings);
    const [selectedOption, setSelectedOption] = useState(optionByValue(BACKGROUND_OPTIONS, currentSetting));
    const options = Object.keys(BACKGROUND_OPTIONS).map((key, i) => {
        const opt = BACKGROUND_OPTIONS[key];
        const currentSettingMarker = opt.val !== currentSetting ? <></> :
            <div style={{color: 'var(--color-foreground-hinted)', font: 'var(--font-body-small)'}}>
                &nbsp;(current setting)
            </div>;
        const onClick = () => {
            setDropAnimationOpen(false);
            if (opt.val === selectedOption.val)
                return;
            setSelectedOption(opt);
            if (opt.val !== currentSetting) {
                let newSettings = structuredClone(settings);
                newSettings[BACKGROUND_OPTION_KEY] = opt.val;
                setSettings(newSettings);
            }
            else if (BACKGROUND_OPTION_KEY in settings) {
                let newSettings = structuredClone(settings);
                delete newSettings[BACKGROUND_OPTION_KEY];
                setSettings(newSettings);
            }
        }
        return (
            <DropdownElement key={i} onClick={onClick}>{opt.name}{currentSettingMarker}</DropdownElement>
        );
    });
    return (
        <div className='settings-column'>
            <h4>Background Images</h4>
            <FormVSpace/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <DropdownBox
                    style={{width: '100%'}}
                    text={selectedOption.name}
                    open={dropAnimationOpen}
                    setOpen={setDropAnimationOpen}
                >
                    { options }
                </DropdownBox>
            </div>
            <Info>{selectedOption.info}</Info>
        </div>
    );
}

function SaveSettings({ settings, setSettings }) {
    const dispatch = useDispatch();
    const changed = Object.keys(settings).length > 0;
    const onSave = !changed ? () => { } : () => {
        dispatch(setAppSettings(settings));
        setSettings({});
    };
    return (
        <div className='settings-column'>
            <DefaultButton deactivated={!changed} style={{ width: '50%'}} onClick={onSave}>
                Save Changes
            </DefaultButton>
        </div>
    );
}

export default function VisualSettings() {
    const [settings, setSettings] = useState({});    
    return (
        <div className='settings-page'>
            <h2>Visual Settings</h2>
            <FormSeparator/>
            <BackgroundSettings settings={settings} setSettings={setSettings}/>
            <FormSeparator/>
            <SaveSettings settings={settings} setSettings={setSettings}/>
        </div>
    );
}