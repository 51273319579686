import { useCallback, useEffect, useRef, useState } from 'react';
import './atmosphere_matrix.css';

function CategoryHead({ name }) {
    return (
        <div className='atmosphere-matrix-category'>
            <h2 className='text-ellipsis'>{name}</h2>
        </div>
    );
}

function Variation({ variation, categoryIdx, elementIdx, variationIdx, active,  setState }) {
    const onClick = async (e) => {
        e.stopPropagation();
        if (!active) {
            await setState(categoryIdx, elementIdx, variationIdx);
        }
        else {
            await setState(categoryIdx, elementIdx, 0);
        }
    }
    return (
        <div className={'text-ellipsis atmosphere-matrix-element-variation' + (active ? ' active' : '')} onClick={onClick}>
            { variation }
        </div>
    );
}

function Element({ element, categoryIdx, elementIdx, currentColState, setState }) {
    const active = currentColState[0] === elementIdx;
    const variations = element.length === 1 ? <></> :
        <div style={{display: 'flex', gap: '8px', flexWrap: 'wrap', justifyContent: 'center'}}>
            {
                element.slice(1, element.length).map((e, i) => 
                    <Variation 
                        key={i}
                        variation={e}
                        categoryIdx={categoryIdx}
                        elementIdx={elementIdx}
                        variationIdx={i + 1} 
                        active={active && currentColState[1] === (i + 1)} 
                        setState={setState}/>
                )
            }
        </div>;
    const onClick = async (e) => {
        if (!active || currentColState[1] !== 0) {
            await setState(categoryIdx, elementIdx, 0);
        }
    }
    return (
        <div className={'text-ellipsis atmosphere-matrix-element' + (active ? ' active' : '') + (element.length > 1 ? ' variations' : '')} onClick={onClick}>
            { element[0] }
            { variations }
        </div>
    );
}

function ElementList({ category, categoryIdx, currentState, setState }) {
    const elements = category.elements.map((e, i) => 
        <Element 
            key={i} 
            element={e} 
            categoryIdx={categoryIdx} 
            elementIdx={i} 
            currentColState={currentState[categoryIdx]}
            setState={setState}/>
    );
    const scrollRef = useRef();
    const mouseWheelScroll = (e) => {
        if (e.deltaY === 0) {
            return;
        }
        else if (scrollRef.current.scrollHeight === scrollRef.current.clientHeight) {
            return;
        }
        e.stopPropagation();
        scrollRef.current.scrollTo({
            top: scrollRef.current.scrollTop + e.deltaY
        });
    };
    return (
        <div className='atmosphere-matrix-element-list-anchor'>
            <div className='atmosphere-matrix-element-list custom-scrollbar-always' ref={scrollRef} onWheel={mouseWheelScroll}>
                { elements }
            </div>
        </div>
    );
}

export default function AtmosphereMatrix({ categories, currentState, setState }) {    
    const categoryHeads = categories.map((c, i) => <CategoryHead key={i} name={c.name}/>);
    const elementLists = categories.map((c, i) => 
        <ElementList key={i} category={c} categoryIdx={i} currentState={currentState} setState={setState}/>
    );

    const [scrollShadowR, setScrollShadowR] = useState(false);
    const [scrollShadowL, setScrollShadowL] = useState(false);
    const scrollRef = useRef(null);
    const checkScrollShadows = () => {
        const top = scrollRef.current.scrollLeft === 0;
        const bottom = scrollRef.current.scrollWidth - scrollRef.current.scrollLeft === scrollRef.current.clientWidth;
        setScrollShadowL(!top);
        setScrollShadowR(!bottom);
    }
    const checkScrollableContainer = useCallback(() => {
        if (scrollRef.current.clientWidth < scrollRef.current.scrollWidth) {
            checkScrollShadows();
        }
        else {
            setScrollShadowL(false);
            setScrollShadowR(false);
        }
    }, []);
    useEffect(() => {
        checkScrollableContainer();
        const ro = new ResizeObserver(checkScrollableContainer);
        ro.observe(scrollRef.current);
        return () => { ro.disconnect(); }
    }, [checkScrollableContainer]);
    const scrollShadowLeft = !scrollShadowL ? 
        <></> :
        <div style={{
            position: 'absolute',
            left: '0',
            height: 'calc(100% - 16px)',
            boxShadow: '0px 0px 16px 4px var(--color-primary-inactive)'
        }}/>;
    const scrollShadowRight = !scrollShadowR ? 
        <></> :
        <div style={{
            position: 'absolute',
            right: '0',
            height: 'calc(100% - 16px)',
            boxShadow: '0px 0px 16px 4px var(--color-primary-inactive)'
        }}/>;
    const mouseWheelScroll = (e) => {
        if (e.deltaY === 0) {
            return;
        }
        scrollRef.current.scrollTo({
            left: scrollRef.current.scrollLeft + e.deltaY,
        });
    };
    return (
        <>
            <div className='atmosphere-matrix-scroll-shadow-container'>
                {scrollShadowLeft}
                {scrollShadowRight}
            </div>
            <div 
                className='atmosphere-matrix-scroll-container custom-scrollbar-horizontal' 
                ref={scrollRef}
                onResize={checkScrollableContainer}
                onScroll={checkScrollShadows}
                onWheel={mouseWheelScroll}
            >
                <div style={{flexGrow: 1}}/>
                <div className='atmosphere-matrix'>
                    <div className='atmosphere-matrix-header'>
                        {categoryHeads}
                    </div>
                    <div className='atmosphere-matrix-body'>
                        { elementLists }
                    </div>
                </div>
                <div style={{flexGrow: 1}}/>
            </div>
        </>
    );
}