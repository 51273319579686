import * as Icons from '@phosphor-icons/react';
import HubSubpageLibrary from './subpages/library';
import HubSubpageSearch from './subpages/search';
import HubSubpageHome from './subpages/home';
import HubSubpageHomeWaitinglist from './subpages/home_waitinglist';
import HubSubpageSurveys from './subpages/surveys'; 
import useContextTab from '../../../../hooks/useContextTab';
import { AnimatedBackground } from '../../../../components/shared/animated-background/animated_background';
import { useEffect, useState } from 'react';
import { FooterStacked } from '../../../components/footer/footer';
import { HUB_PAGES } from '../../../../constants/hub';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenPage } from '../../../../store/slices/hub';
import { CONTEXT_ID } from '../../../../constants/context';
import { PERMISSIONS } from '../../../../constants/permissions';
import { NavContainer } from '../../../components/navbar/navbar';

export default function HubPage() {
    useEffect(() => {
        document.title = 'Ambient Sphere';
    }, []);
    const [sidebarRetracted, setSidebarRetracted] = useState(false);
    const openPage = useSelector((state) => state.hub.openPage);
    const permissions = useSelector((state) => state.session.permissions);
    const contextTab = useContextTab(CONTEXT_ID.hub);
    const alphaAccess = permissions.includes(PERMISSIONS.access_content);
    const dispatch = useDispatch();

    useEffect(() => {
        if (openPage === HUB_PAGES.home.id)
            contextTab.setTitle(HUB_PAGES.home.title);
        else if (openPage === HUB_PAGES.library.id)
            contextTab.setTitle(HUB_PAGES.library.title);
        else if (openPage === HUB_PAGES.search.id)
            contextTab.setTitle(HUB_PAGES.search.title);
        else if (openPage === HUB_PAGES.surveys.id)
            contextTab.setTitle(HUB_PAGES.surveys.title);
    }, [openPage, contextTab]);

    var subpageComp = (() => {
        if (openPage === HUB_PAGES.home.id) {
            if (alphaAccess) {
                return <HubSubpageHome/>;
            }
            else {
                return <HubSubpageHomeWaitinglist/>;
            }
        }
        else if (openPage === HUB_PAGES.library.id) {
            return <HubSubpageLibrary/>;
        }
        else if (openPage === HUB_PAGES.search.id) {
            return <HubSubpageSearch/>;
        }
        else if (openPage === HUB_PAGES.surveys.id) {
            return <HubSubpageSurveys/>;
        }
    })();
   
    const alphaPages = alphaAccess ? [
        { id: HUB_PAGES.library.id, title: HUB_PAGES.library.title, icon: <Icons.BookBookmark size={18}/> },
        { id: HUB_PAGES.search.id, title: HUB_PAGES.search.title, icon: <Icons.MagnifyingGlass size={18}/> }
    ] : [];
    const pages = [
        { id: HUB_PAGES.home.id, title: HUB_PAGES.home.title, icon: <Icons.House size={18}/> },
        ...alphaPages,
        { id: HUB_PAGES.surveys.id, title: HUB_PAGES.surveys.title, icon: <Icons.Question size={18}/> }
    ]

    return (
        <>
            <AnimatedBackground filter='blur(8px) opacity(33%)'/>
            <NavContainer
                pages={pages}
                openPageId={openPage}
                onOpen={(p) => dispatch(setOpenPage({ id: p.id, title: p.title }))}
                retracted={sidebarRetracted}
                setRetracted={setSidebarRetracted}
                footer={<FooterStacked/>}
            >
                { subpageComp }
            </NavContainer>
        </>
    );
}