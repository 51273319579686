export const PAGE_ROUTES = {
    main: "/",
    devPrefix: "/dev",
    status404: "/404",
    status503: "/503",
    signup: "/signup",
    login: "/login",
    maintenance: "/maintenance",
    settings: "/user-settings",
    passwordReset: "/password-reset",
    verifyEmail: "/email-verification",
    atmosphere: "/atmosphere/:atm_id",
    newsReader: "/news/:news_index/",
    survey: "/survey/:survey_id/"
}
