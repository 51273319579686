import { createSlice } from "@reduxjs/toolkit";
import { BACKGROUND_OPTIONS } from "../../constants/settings";

const initialState = {
    'loggedIn': false,
    'accessToken': "",
    'permissions': [],
    'requiredActions': [],
    'openContexts': { },
    'focusedContext': null,
    'ignoreMobileDisclaimer': false,
    'appSettingsQueried': false,
    'appSettings': {
        'backgroundSettings': BACKGROUND_OPTIONS.animated.val
    },
    'overlayFeedbackForm' : false
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setAccessToken: {
            reducer: (state, action) => {
                state.loggedIn = action.payload !== "";
                state.accessToken = action.payload;
            }
        },
        setPermissions: {
            reducer: (state, action) => {
                state.permissions = action.payload;
            }
        },
        setRequiredActions: {
            reducer: (state, action) => {
                state.requiredActions = action.payload;
            }
        },
        setOpenContexts: {
            reducer: (state, action) => {
                state.openContexts = action.payload;
            }
        },
        setFocusedContext: {
            reducer: (state, action) => {
                state.focusedContext = action.payload;
            }
        },
        setIgnoreMobileDisclaimer: {
            reducer: (state, action) => {
                state.ignoreMobileDisclaimer = action.payload;
            }
        },
        setAppSettingsQueried: {
            reducer: (state, action) => {
                state.appSettingsQueried = action.payload;
            }
        },
        setAppSettings: {
            reducer: (state, action) => {
                for (const key of Object.keys(state.appSettings)) {
                    if (key in action.payload) {
                        state.appSettings[key] = action.payload[key];
                    }
                }
                window.localStorage.setItem('appSettings', JSON.stringify(state.appSettings));
            }
        },
        setFeedbackOverlay:{
            reducer: (state, action) => {
                state.overlayFeedbackForm = action.payload;
            }
        }
    }
});

export const { 
    setAccessToken, 
    setPermissions, 
    setRequiredActions, 
    setOpenContexts, 
    setFocusedContext,
    setIgnoreMobileDisclaimer,
    setAppSettingsQueried,
    setAppSettings,
    setFeedbackOverlay
} = sessionSlice.actions;
export default sessionSlice.reducer;
