import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAtmosphereMeta } from "../../store/slices/hub";
import { getAtmosphereMetaList } from "../../api/data";

export default function useAtmosphereMetaList() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const loadedAtmospheres = useSelector((state) => state.hub.atmosphereMeta);
    const dispatch = useDispatch();    
    useEffect(() => {
        if (error === null && loadedAtmospheres.loaded === 0) {
            setLoading(true);
            const successHandler = (atmospheres) => {
                dispatch(setAtmosphereMeta(atmospheres));
            }
            const permissionDeniedHandler = () => {
                setError('Permission denied.');
            }
            const unknownError = () => {
                setError('Unknown error.');
            }
            const finallyHandler = () => {
                setLoading(false);
            }
            getAtmosphereMetaList(
                successHandler,
                permissionDeniedHandler,
                unknownError,
                finallyHandler);
        }
    }, [error, loadedAtmospheres, dispatch]);
    return [
        loading,
        loadedAtmospheres, 
        error
    ];
}