const benefits = [
    ["Ad-free", "Unlimited access to the entire library"],
    ["Ad-free", "Unlimited access to the entire library", "Create your own Atmospheres", "Share your creations with the community"],
    ["Ad-free", "Unlimited access to the entire library", "Create your own Atmospheres", "Share your creations with the community", "16% discount on monthly plan"]
]

export const AB_TEST_PLANS_VARIATIONS = [
    [
        { icon: "low_tier", name: 'Casual Storyteller', subtitle: null, price: 499, time: "mo", benefits: benefits[0], primary: false },
        { icon: "high_tier", name: 'Master Narrator', subtitle: '1-month', price: 699, time: "mo", benefits: benefits[1], primary: true },
        { icon: "high_tier", name: 'Master Narrator', subtitle: '12-months', price: 6999, time: "yr", benefits: benefits[2], primary: false }
    ],
    [
        { icon: "low_tier", name: 'Casual Storyteller', subtitle: null, price: 499, time: "mo", benefits: benefits[0], primary: false },
        { icon: "high_tier", name: 'Master Narrator', subtitle: '1-month', price: 799, time: "mo", benefits: benefits[1], primary: true },
        { icon: "high_tier", name: 'Master Narrator', subtitle: '12-months', price: 7999, time: "yr", benefits: benefits[2], primary: false }
    ],
    [
        { icon: "low_tier", name: 'Casual Storyteller', subtitle: null, price: 599, time: "mo", benefits: benefits[0], primary: false},
        { icon: "high_tier", name: 'Master Narrator', subtitle: '1-month', price: 799, time: "mo", benefits: benefits[1], primary: true},
        { icon: "high_tier", name: 'Master Narrator', subtitle: '12-months', price: 7999, time: "yr", benefits: benefits[2], primary: false }
    ],
    [
        { icon: "low_tier", name: 'Casual Storyteller', subtitle: null, price: 599, time: "mo", benefits: benefits[0], primary: false },
        { icon: "high_tier", name: 'Master Narrator', subtitle: '1-month', price: 899, time: "mo", benefits: benefits[1], primary: true },
        { icon: "high_tier", name: 'Master Narrator', subtitle: '12-months', price: 8999, time: "yr", benefits: benefits[2], primary: false }
    ]
];

export const AB_TEST_VERSION_MAP = {
    "AGiRWKDT7P": { plans: 0},
    "OkRLb77Lg5": { plans: 1},
    "6CHB4FyoFE": { plans: 2},
    "bMXENcYIJA": { plans: 3}
}