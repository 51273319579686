import { FacebookLogo, InstagramLogo, YoutubeLogo } from '@phosphor-icons/react';
import DiscordLogo from '../../../assets/icons/brands/discord.svg';
import './social_links.css'

function SocialIcon({link, children}) {
    return (
        <a href={link} target="_blank" rel="noreferrer">
            {children}
        </a>
    );
}

export default function SocialLinks({size = 24, style}) {
    return (
        <div className='social-links-container' style={style}>
            <SocialIcon link="https://discord.gg/NQyKcaS7CW">
                <img src={DiscordLogo} style={{width: String(size) + 'px'}} alt=""/>
            </SocialIcon>
            <SocialIcon link="https://www.instagram.com/ambient_sphere/">
                <InstagramLogo color='var(--color-foreground)' size={size}/>
            </SocialIcon>
            <SocialIcon link="https://www.youtube.com/@AmbientSphereTTRPG">
                <YoutubeLogo color='var(--color-foreground)' size={size + 2} weight='fill'/>
            </SocialIcon>
            <SocialIcon link="https://fb.me/AmbientSphereSoftware">
                <FacebookLogo color='var(--color-foreground)' size={size - 2} weight='fill'/>
            </SocialIcon>
        </div>
    );
}