import { useCallback, useEffect, useRef } from 'react';
import { CaretDown } from '@phosphor-icons/react';
import './dropdown.css';

export function Dropdown({anchor, align, open, setOpen, children}) {
    const alignClass = align === 'right' ? " right-aligned" 
    : align === 'cover' ? " cover-anchor-component"
    : "";
    
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                if (!anchorRef.current || !anchorRef.current.contains(event.target)) {
                    setOpen(false)
                }
            }
        };
        open ? 
            document.addEventListener('click', handleClickOutside, true)
        : document.removeEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [open, setOpen]);
    const onClick = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);

    var mappedChildren = [];
    for (var i = 0; i < children.length; ++i) {
        mappedChildren.push(children[i]);
        if (i + 1 < children.length) {
            mappedChildren.push((
                <div key={children.length + i} className='dropdown-element-separator'/>
            ));
        }
    }
    return (
        <div className={"dropdown" + (open ? " open" : "")}>
            <div ref={anchorRef} onClick={onClick}>
                {anchor}
            </div>
            <div ref={menuRef} className={"custom-scrollbar dropdown-menu" + alignClass}>
                {mappedChildren}
            </div>
        </div>
    );
}

export function DropdownClick({anchor, align, open, setOpen, children}) {
    const menuRef = useRef(null);
    const anchorRef = useRef(null);
    const alignClass = align === 'right' ? " right-aligned" 
        : align === 'cover' ? " cover-anchor-component"
        : "";
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                if (!anchorRef.current || !anchorRef.current.contains(event.target)) {
                    setOpen(false)
                }
            }
        };
        open ? 
          document.addEventListener('click', handleClickOutside, true)
        : document.removeEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [open, setOpen]);
    const onClick = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);
    var mappedChildren = [];
    for (var i = 0; i < children.length; ++i) {
        mappedChildren.push(children[i]);
        if (i + 1 < children.length) {
            mappedChildren.push((
                <div key={children.length + i} className='dropdown-element-separator'/>
            ));
        }
    }
    return (
        <div className={"dropdown-click" + (open ? " open" : "")}>
            <div ref={anchorRef} onClick={onClick}>
                {anchor}
            </div>
            <div ref={menuRef} className={"custom-scrollbar dropdown-menu" + alignClass}>
                {mappedChildren}
            </div>
        </div>
    );
}

export function DropdownElement({onClick, children}) {
    return (
        <button className="dropdown-element" onClick={onClick}>
            {children}
        </button>
    );
}

export function DropdownIcon({icon=<></>}) {
    return (
        <div className="dropdown-icon">
            <div style={{flexGrow: 1}}/>
            {icon}
            <div style={{flexGrow: 1}}/>
        </div>
    )
}

export function DropdownBox({style, text = '', open, setOpen, children}) {
    const anchor = (
        <div className='dropdown-box' style={style}>
            {text}
            <div style={{flexGrow: 1}}/>
            <div style={{color: 'var(--color-foreground-hinted)'}}><CaretDown weight='bold'/></div>
        </div>
    );
    return (
        <DropdownClick anchor={anchor} align='cover' open={open} setOpen={setOpen}>
            {children}
        </DropdownClick>
    );
}