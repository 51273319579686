import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { postRefresh } from "../../api/account";
import { sessionContext } from "../../contexts/session";
import LoadingSpinner from "../../components/shared/loading-spinner/loading_spinner";

export function EnsurePersistence() {
    const loggedIn = useSelector(state => state.session.loggedIn);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!loggedIn) {
            const successHandler = sessionContext.login.bind(sessionContext);
            const invalidRefreshTokenHandler = () => { };
            const unknownErrorHandler = () => { sessionContext.fatalFailure(); }
            const finallyHandler = () => {
                setLoading(false);
            }
            postRefresh(
                successHandler,
                invalidRefreshTokenHandler,
                unknownErrorHandler,
                finallyHandler);
        }
        else {
            setLoading(false);
        }
    }, [loggedIn]);
    if (!loading)
        return <Outlet/>
    else {
        return (
            <div style={{
                width: '100%', 
                height: '100%', 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center" }}
            >
                <span>Loading&nbsp;&nbsp;</span>
                <LoadingSpinner size={16}/>
            </div>
        );
    }
}

export function CondRouteMissingPermission({permission, alternative_target}) {
    const permissions = useSelector(state => state.session.permissions);
    const hasPermission = permissions.includes(permission);
    return hasPermission ?
        <Outlet/> :
        <Navigate to={alternative_target} replace={true}/>;
}

export function CondRouteNotLoggedIn({alternative_target}) {
    const loggedIn = useSelector(state => state.session.loggedIn);
    if (loggedIn)
        return <Navigate to={alternative_target} replace={true}/>;
    else
        return <Outlet/>
}

export function CondRouteLoggedIn({alternative_target}) {
    const loggedIn = useSelector(state => state.session.loggedIn);
    if (!loggedIn)
        return <Navigate to={alternative_target} replace={true}/>;
    else
        return <Outlet/>
}