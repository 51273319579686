import LoadingWidget from "../loading-widget/loading_widget";
import NewsContainer from "./news_container";

export default function NewsContentWrapper({ loading, news, error }) {
    if (loading || news === null)
        return <LoadingWidget centered={false}/>;
    else if (error !== null)
        return <div style={{color: 'var(--color-error)', margin: '8px 24px'}}>Could not load news: {error}</div>
    else if (news.length === 0)
        return <div style={{color: 'var(--color-foreground-hinted)', margin: '8px 24px'}}>No news</div>
    else
        return <NewsContainer news={news}/>;
}