import { useState } from 'react';
import { SlimInput } from '../../../../components/form-input/form_input';
import { passwordRuleConformityHint, validatePasswordRuleConformity } from '../../../../../utils/security';
import { FormSeparator, FormVSpace } from '../../../../components/structural/structural';
import { OutlinedButton, DangerOutlinedButton, DefaultButton } from '../../../../components/button/button';
import { Warning, XCircle } from '@phosphor-icons/react';
import { postAccountDelete, postUpdatePassword } from '../../../../../api/account';
import { sessionContext } from '../../../../../contexts/session';
import './account.css';

function Info({children}) {
    return (
        <>
            <div style={{
                color: 'var(--color-foreground-hinted)', 
                font: 'var(--font-body-medium)'
            }}>
                {children}
            </div>
            <FormVSpace/>
        </>
    );
}

function ConfirmationBanner({ content, onClose }) {
    const [pw, setPw] = useState('');
    const [err, setErr] = useState('');
    const inputValid = (!content.requirePassword) || (pw !== '');
    const passwordInput = !content.requirePassword ? <></> :
        <>
            <div style={{font: 'var(--font-body-medium)'}}>Please enter your current password to confirm:</div>
            <FormVSpace/>
            <SlimInput
                style={{width: '100%'}}
                type='password'
                id='pw'
                placeholder='Password'
                value={pw}
                onChange={(e) => { setPw(e.target.value); setErr(''); } }
            />
        </>;
    const onSave = content.requirePassword ?
        () => { content.onSave(setErr, onClose, pw) } :
        () => { content.onSave(setErr, onClose) };
    const error = err === null || err === '' ? 
        <></> : 
        <>
            <div style={{ color: 'var(--color-error)', display: 'flex', alignItems: 'center' }}>
                <XCircle size={17}/>&nbsp;{ err } 
            </div>
            <FormSeparator/>
        </>;
    return (
        <div className='confirmation-banner-overlay'>
            <div style={{flexGrow: 1}}/>
            <div className='confirmation-banner'>
                <h3 style={{textAlign: 'center'}}>{content.title}</h3>
                <div>{content.info}</div>
                <FormSeparator/>
                <form onSubmit={(e) => { e.preventDefault(); onSave(); }}>
                    
                    { passwordInput }
                    <FormSeparator/>
                    { error }
                    <div style={{display: 'flex'}}>
                        <DefaultButton style={{width: '100%'}} deactivated={!inputValid}>Confirm</DefaultButton>
                        <div style={{width: '16px'}}/>
                        <OutlinedButton style={{width: '100%'}} onClick={onClose}>Cancel</OutlinedButton>
                    </div>
                </form>
            </div>
            <div style={{flexGrow: 1}}/>
        </div>
    );
}

function PasswordSettings({ openConfirmation }) {
    const [pw, setPw] = useState('');
    const [rpw, setRPw] = useState('');

    const validatePassword = (vPwd) => {
        if (!vPwd)
            return true;
        return validatePasswordRuleConformity(vPwd);
    }
    const validationHintPassword = (vPwd) => {
        return passwordRuleConformityHint(vPwd);
    }    
    const validateRepeatPassword = (vPw) => {
        if (!vPw)
            return true;
        return vPw === pw;
    }
    const validationHintRepeatPassword = (vPwd) => {
        return "Please enter the same password as above.";
    }
    const pwValidationOverwrite = validatePassword(pw);
    const rpwValidationOverwrite = validateRepeatPassword(rpw);
    const pwValid = pw && rpw && pwValidationOverwrite && rpwValidationOverwrite;
    const onSave = (e) => {
        e.preventDefault();
        openConfirmation({
            title: 'Confirm Password Change',
            info: 'You are about to change your account password.',
            requirePassword: true,
            onSave: (error, close, currentPassword) => {
                const successHandler = () => {
                    setPw('');
                    setRPw('');
                    close();
                }
                const userNotFoundHandler = () => error('User not found.');
                const invalidCurrentPassword = () => error('Incorrect password.');
                const invalidNewPassword = (m) => error((
                    <>
                        <span>The new password does not conform to our password rules:</span>
                        {m.map((t, i) => <span key={i}>{ i !== 0 ? ',' : ''}&nbsp;{t}</span>)}
                    </>
                ));
                const permissionDeniedHandler = () => error('You lack the permission to modify this account.');
                const unknownErrorHandler = () => error('Unknown error. Please try again later or contact an administrator.');
                const finallyHandler = () => { }
                postUpdatePassword(
                    currentPassword,
                    pw,
                    successHandler,
                    userNotFoundHandler,
                    invalidCurrentPassword,
                    invalidNewPassword,
                    permissionDeniedHandler,
                    unknownErrorHandler,
                    finallyHandler);
            }
        });
    }
    return (
        <div className='settings-column'>
            <h4>Change Password</h4>
            <Info>
                Change the password you use to log in to your account.
            </Info>
            <form onSubmit={onSave}>
                <SlimInput
                    type='password'
                    id='pw'
                    placeholder='New Password'
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                    validationOverwrite={pwValidationOverwrite}
                    validationHint={validationHintPassword}
                />
                <FormVSpace/>
                <SlimInput
                    type='password'
                    id='rpw'
                    placeholder='Repeat New Password'
                    value={rpw}
                    onChange={(e) => setRPw(e.target.value)}
                    validationOverwrite={rpwValidationOverwrite}
                    validationHint={validationHintRepeatPassword}
                />
                <FormVSpace/>
                <OutlinedButton style={{width: '50%'}} deactivated={!pwValid}>Change Password</OutlinedButton>
            </form>
        </div>
    );
}

function DeleteAccount({ openConfirmation }) {
    const onSave = (e) => {
        e.preventDefault();
        openConfirmation({
            title: 'Account Deletion',
            info: (
                <>
                    <div style={{display: 'flex', alignItems: 'center', color: 'var(--color-danger)'}}><Warning/>&nbsp;You are about to delete your account.</div>
                    <div>This cannot be undone.</div>
                </>
            ),
            requirePassword: true,
            onSave: (error, close, currentPassword) => {
                const successHandler = () => { sessionContext.expireSession(); };
                const userNotFoundHandler = () => error('User not found.');
                const invalidPasswordHandler = () => error('Incorrect password.');
                const permissionDeniedHandler = () => error('You lack permission to modify this account.');
                const unknownErrorHandler = () => error('Unknown error. Please try again later or contact an administrator.');
                const finallyHandler = () => { };
                postAccountDelete(
                    currentPassword,
                    successHandler,
                    userNotFoundHandler,
                    invalidPasswordHandler,
                    permissionDeniedHandler,
                    unknownErrorHandler,
                    finallyHandler
                );
            }
        });
    }
    return (
        <div className='settings-column'>
            <h4 style={{color: 'var(--color-danger)'}}>Delete Account</h4>
            <Info>
                Account deletion is permanent and cannot be undone.
                All your associated data is deleted on our end.
            </Info>
            <DangerOutlinedButton style={{width: '50%', display: 'flex', alignItems: 'center'}} onClick={onSave}>
                <div style={{flexGrow: 1}}/>
                <Warning/>&nbsp;Delete Account
                <div style={{flexGrow: 1}}/>
            </DangerOutlinedButton>
        </div>
    );
}

export default function AccountSettings() {
    const [confirmationContent, setConfirmationContent] = useState(null);
    const confirmationBanner = confirmationContent === null ? <></> :
        <ConfirmationBanner 
            content={confirmationContent} 
            onClose={() => setConfirmationContent(null)}
        />;
    return (
        <>
            { confirmationBanner }
            <div className='settings-page'>
                <h2>Account Settings</h2>
                <FormSeparator/>
                <PasswordSettings openConfirmation={setConfirmationContent}/>
                <FormSeparator/>
                <DeleteAccount openConfirmation={setConfirmationContent}/>
            </div>
        </>
    );
}