import { generateTrigrams } from "./string";

export function generateTrigramPattern(str) {
    return generateTrigrams(str.toLowerCase().replace(/\s+/g, ''));
}

export function generateTrigramSearchString(str) {
    return str.toLowerCase().replace(/\s+/g, '');
}

export function trigramMatch(trigramPattern, searchStr) {
    if (!trigramPattern || trigramPattern.length === 0 || searchStr.length < 3)
        return 0;
    let found = 0;
    trigramPattern.forEach(trigram => {
        found += searchStr.search(trigram) === -1 ? 0 : 1;
    });
    return found / trigramPattern.length;
}

export function generateWordSeparatedTrigramPattern(str) {
    const words = str.trim().split(/\s+/);
    const trigrams = [];
    words.forEach(word => trigrams.push(...generateTrigramPattern(word)));
    return trigrams;
}

export function generateExtendedTrigramPattern(str) {
    const trigrams = [...generateTrigramPattern(str), ...generateWordSeparatedTrigramPattern(str)];
    return trigrams;
}