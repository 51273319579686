import { useRef, useState, useEffect } from 'react';
import './overlay.css';

export function Overlay({style, show, anchor, anchorStyle, position = 'n', children}){
    const [positionStyle, setPositionStyle] = useState({});
    const anchorRef = useRef();
    const widgetRef = useRef();
    useEffect(() => {
        if (anchorRef.current && widgetRef.current) {
            const centerH = ((anchorRef.current.clientWidth - widgetRef.current.clientWidth) / 2) + 'px';
            const centerV = ((anchorRef.current.clientHeight - widgetRef.current.clientHeight) / 2) + 'px';
            switch(position) {
                default:
                case 's':
                case 'south':
                    setPositionStyle({
                        top: 'calc(100% + 4px)',
                        left: centerH
                    });
                    break;
                case 'w':
                case 'west':
                    setPositionStyle({
                        top: centerV,
                        right: 'calc(100% + 8px)'
                    });
                    break;
                case 'n':
                case 'north':
                    setPositionStyle({
                        bottom: 'calc(100% + 4px)',
                        left: centerH
                    });
                    break;
                case 'e':
                case 'east':
                    setPositionStyle({
                        top: centerV,
                        left: 'calc(100% + 8px)'
                    });
                    break;
                case 'nw':
                case 'northwest':
                    setPositionStyle({
                        bottom: 'calc(100% + 1px)',
                        right: 'calc(100% + 1px)'
                    });
                    break;
                case 'ne':
                case 'northeast':
                    setPositionStyle({
                        bottom: 'calc(100% + 1px)',
                        left: 'calc(100% + 1px)'
                    });
                    break;
                case 'sw':
                case 'southhwest':
                    setPositionStyle({
                        top: 'calc(100% + 1px)',
                        right: 'calc(100% + 1px)'
                    });
                    break;
                case 'se':
                case 'southeast':
                    setPositionStyle({
                        top: 'calc(100% + 1px)',
                        left: 'calc(100% + 1px)'
                    });
                    break;
            }
        }
    }, [position, anchor]);

    const overlay = show ? 
        <div ref={widgetRef} className={'overlay-widget' + (show ? ' shown' : '')} style={{...positionStyle}}>
            {children}
        </div> : <></> 

    return(
        <div className='overlay' style={style}>
             <div 
                ref={anchorRef}
                style={anchorStyle}>
                {anchor}
            </div>
            {overlay}
        </div>
    );
}