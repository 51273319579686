export function FormSeparator() {
    return (
        <div style={{width: '100%', height: '1px', margin: '6px 0px', background: 'var(--color-bg-elem-dp1-lg)'}}/>
    );
}

export function FormVSpace() {
    return (
        <div style={{height: '6px'}}/>
    );
}
