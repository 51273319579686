import { Route } from "react-router-dom";
import { PAGE_ROUTES } from "../constants/page_routes";
import { EnsurePermanentContexts } from "./shared/session";
import { StatusPage404, StatusPage503 } from "../deprecated/pages/status/status_page";
import { EnsurePersistence } from "./shared/conditional_auth";

export default function RoutesStatus() {
    return (
        <>
            <Route path={PAGE_ROUTES.status503} element={(<StatusPage503/>)}/>
            <Route element={<EnsurePersistence/>}>
                <Route element={<EnsurePermanentContexts/>}>
                    <Route path={PAGE_ROUTES.status404} element={(<StatusPage404/>)}/>
                </Route>
            </Route>
        </>
    );
}