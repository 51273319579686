import AmbientSphereLogo from '../../../assets/images/logo.svg';
import NullImg from '../../../assets/images/null.jpg';
import Tag from '../tag/tag';
import { getThumbnailURL } from '../../../api/data';
import './atmosphere_card.css';

function ShortDescription({ meta }) {
    const officialSignature = meta.types.includes('official') ? 
        <img className='atmosphere-card-official-logo' src={AmbientSphereLogo} alt=""/> : 
        <></>;
    const mockupLabel = meta.types.includes('mockup') ?
        <span style={{color: 'var(--color-foreground-hinted)', font: 'var(--font-body)'}}>(Mockup)&nbsp;</span> :
        <></>;
    return (
        <>
            <div className='info-container'>
                <div className='title'>
                    {mockupLabel}{meta.name}
                </div>
                <div className='creator'>
                    By {meta.creator}
                </div>
            </div>
            <div className='signature-container'>
                <div className='signature'>
                    {officialSignature}
                </div>
            </div>
        </>
    );
}

function DetailedDescription({ meta }) {
    const atmosphereState = meta.types.includes('official') ? 
        'Official Atmosphere' :
        'Custom Atmosphere';
    const mockupLabel = meta.types.includes('mockup') ?
        <span style={{color: 'var(--color-foreground-hinted)'}}>(Mockup)&nbsp;</span> :
        <></>;
    const mockupText = meta.types.includes('mockup') ?
        <span style={{color: 'var(--color-foreground-hinted)'}}>
            This is a mockup of an atmosphere.
            Its only purpose is to showcase how different atmospheres can look. 
            <br/>
        </span> :
        <></>;
    const descriptionText = meta.desc !== '' ?
        meta.desc.map((d, i) => <span key={i}>{d}</span>) :
        <span style={{color: 'var(--color-foreground-hinted)'}}>No description available</span>
    return (
        <div className='detail-container custom-scrollbar'>
            <h4>{mockupLabel}{meta.name}</h4>
            <div className='creator'>{atmosphereState}</div>
            <div className='tag-container'>
                {meta.tags.map((t, i) => <Tag key={i} tag={t}/>)}
            </div>
            <div className='description'>
                {mockupText}
                {descriptionText}
            </div>
        </div>
    );
}

function Thumbnail({ path }) {
    if (path === null || path === '') {
        return <img className='atmosphere-card-thumbnail' src={NullImg} alt=""/>;
    }
    return (
        <img className='atmosphere-card-thumbnail' 
            src={ getThumbnailURL(path) } 
            alt=""
            onError={(e) => {
                e.target.src = NullImg
            }}
        />
    );
}

export default function AtmosphereCard({ onClick, meta }) {
    return (
        <div className='atmosphere-card' onClick={onClick}>
            <Thumbnail path={meta.thumbnail_path}/>
            <div className='atmosphere-card-overlay'>
                <DetailedDescription meta={meta}/>
            </div>
            <div className='atmosphere-card-short-desc'>
                <ShortDescription meta={meta}/>
            </div>
        </div>
    );
}