import AtmosphereMatrix from '../../../components/atmosphere-matrix/atmosphere_matrix';
import useContextTab from '../../../../hooks/useContextTab';
import LoadingSpinner from '../../../../components/shared/loading-spinner/loading_spinner';
import Footer from '../../../../components/shared/footer/footer';
import NullImg from '../../../../assets/images/null.jpg';
import VolumeSlider from '../../../components/volume-slider/volume_slider';
import AudioMetaInfo from '../../../components/audio-meta-info/audio_meta_info';
import SceneButton, { CustomSceneButton } from '../../../components/scene-button/scene_button';
import * as Icons from '@phosphor-icons/react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CONTEXT_ID } from '../../../../constants/context';
import { PAGE_ROUTES } from '../../../../constants/page_routes';
import { webPlayerContext } from '../../../../contexts/web_player';
import { getAtmosphere, getAtmosphereMeta, getImageURL, getThumbnailURL } from '../../../../api/data';
import { sessionContext } from '../../../../contexts/session';
import { AnimatedBackground, AnimatedBackgroundURL } from '../../../../components/shared/animated-background/animated_background';
import { setCustomSceneSearchString, setOpenSideMenu, setSceneSearchString, setShowSplash } from '../../../../store/slices/web_player';
import { XlTag } from '../../../components/tag/tag';
import { DefaultButton, OutlinedButton } from '../../../components/button/button';
import { generateTrigramSearchString, generateExtendedTrigramPattern, trigramMatch } from '../../../../utils/search';
import { TextBox } from '../../../../components/shared/textboxes/textboxes';
import { SimpleTabRegister } from '../../../../components/shared/tab-registers/tab_registers';
import './web_player.css';

const webPlayerState = {
    error: 0,
    loading_atmosphere_meta: 1,
    loading_atmosphere: 2,
    loaded: 3
};

function CloseButton() {
    const navigate = useNavigate();
    const onClose = () => {
        sessionContext.closeContext(CONTEXT_ID.webPlayer);
        navigate(PAGE_ROUTES.main);
    };
    return (
        <div style={{
            display: 'flex',
            color: 'var(--color-foreground-hinted)'
        }}>
            <span>Go back to&nbsp;</span>
            <div className='web-player-close-button' onClick={ onClose }>
                Hub
            </div>
        </div>
    );
}

function ErrorMessage({ message, onClose }) {
    return (
        <>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div style={{flexGrow: 1}}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column', 
                    justifyContent: 'center',
                    maxWidth: '600px',
                    backgroundColor: 'var(--color-bg-elem-dp1-dk)',
                    padding: '16px',
                    borderRadius: '16px',
                    boxShadow: '2px 2px 2px var(--color-bg-elem-dp1-glass)'
                }}>
                    <div style={{color: 'var(--color-error)'}}>
                        {message}
                    </div>
                    <CloseButton/>
                </div>
                <div style={{flexGrow: 1}}/>
                <Footer/>
            </div>
        </> 
    );
}

function LoadingMessage() {
    return (
        <>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div style={{flexGrow: 1}}/>
                <div style={{
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    backgroundColor: 'var(--color-bg-elem-dp1-dk)',
                    padding: '16px',
                    borderRadius: '16px',
                    boxShadow: '2px 2px 2px var(--color-bg-elem-dp1-glass)'
                }}>
                    Loading<div style={{marginLeft: '8px'}}><LoadingSpinner size={16}/></div>
                </div>
                <div style={{flexGrow: 1}}/>
                <Footer/>
            </div>
        </>        
    );
}

const SIDE_MENU = {
    exit: 'Close',
    atmosphereInfo: 'Info',
    volume: 'Volume',
    artists: 'Artists',
    scenes: 'Scenes'
};

function SideMenuButton({ sideMenu }) {
    const openSideMenu = useSelector((state) => state.webPlayer.guiState.openSideMenu);
    const dispatch = useDispatch();
    const selectIcon = (m) => {
        if (m === SIDE_MENU.exit) {
            return <Icons.SignOut size={24}/>;
        }
        else if (m === SIDE_MENU.atmosphereInfo) {
            return <Icons.BookOpen size={24}/>;
        }
        else if (m === SIDE_MENU.volume) {
            return <Icons.FadersHorizontal size={24}/>;
        }
        else if (m === SIDE_MENU.artists) {
            return <Icons.Eye size={24}/>;
        }
        else if (m === SIDE_MENU.scenes) {
            return <Icons.FilmSlate size={24}/>;
        }
        else {
            return <Icons.List size={24}/>;
        }
    };
    const icon = selectIcon(sideMenu);
    const label = <span>{sideMenu}</span>;
    const buttonClass = sideMenu === openSideMenu ?
        'side-menu-button active' :
        'side-menu-button';
    const changeMenu = () => {
        dispatch(setOpenSideMenu(sideMenu === openSideMenu ? null : sideMenu));
    };
    const navigate = useNavigate();
    const exit = () => {
        sessionContext.closeContext(CONTEXT_ID.webPlayer);
        navigate(PAGE_ROUTES.main);
    };
    const onClick = sideMenu !== SIDE_MENU.exit ? changeMenu : exit;
    return (
        <div className={buttonClass} onClick={onClick}>
            {icon}
            {label}
        </div>
    );
}

function SideMenuInfoWidged({children}) {
    return (
        <div className='info-text'>
            <div style={{marginRight: '8px'}}><Icons.Info size={18}/></div>
            <span>{children}</span>
        </div>
    );
}

function VolumeSideMenu() {
    const playbacks = useSelector((state) => state.webPlayer.atmosphere.playbacks);
    const mainPlayback = useSelector((state) => state.webPlayer.mainPlayback);
    const playbackVolumeComps = playbacks.map((pb, i) => {
        return (
            <div className='volume-control' key={i}>
                <span style={{font: 'var(--font-body-medium)'}}>{pb.name}</span>
                <VolumeSlider 
                    muted={pb.muted} 
                    setMuted={(m) => webPlayerContext.setMuted(i, m)} 
                    volume={pb.volume} 
                    setVolume={(v) => webPlayerContext.setVolume(i, v)}/>
            </div>
        );
    });
    return (
        <div className='side-menu-content custom-scrollbar'>
            <h4>Main Volume</h4>
            <div className='volume-control'>
                <VolumeSlider 
                    muted={mainPlayback.muted}
                    setMuted={(m) => webPlayerContext.setMuted(null, m)}
                    volume={mainPlayback.volume} 
                    setVolume={(v) => webPlayerContext.setVolume(null, v)}/>
            </div>
            <div style={{width: '100%', height: '1px', margin: '16px 0px', background: 'var(--color-bg-elem-dp1)'}}/>
            <h4>Playback Volume</h4>
            <SideMenuInfoWidged>
                Playbacks act as independent audio channels and are defined by the atmosphere.
            </SideMenuInfoWidged>
            {playbackVolumeComps}
        </div>
    );   
}

function ArtistSideMenu() {
    const playbacks = useSelector((state) => state.webPlayer.atmosphere.playbacks);
    const metaComps = playbacks.map((pb, i) => {
        return (
            <div className='playback-meta' key={i}>
                <span>{pb.name}</span>
                <div style={{marginLeft: '12px', font: 'var(--font-body-medium)'}}>
                    <AudioMetaInfo meta={pb.metaData} key={i}/>
                </div>
            </div>
        )
    });
    return (
        <div className='side-menu-content custom-scrollbar'>
            <h4>Artist Information</h4>
            <SideMenuInfoWidged>
                This menu shows artist meta data for currently playing audio tracks, if such data is available.
            </SideMenuInfoWidged>
            <div style={{width: '100%', height: '1px', margin: '16px 0px', background: 'var(--color-bg-elem-dp1)'}}/>
            {metaComps}
        </div>
    );
}

function PredefinedScenesList({ setState, categories }) {
    const scenes = useSelector(state => state.webPlayer.atmosphere.scenes);
    const searchString = useSelector(state => state.webPlayer.guiState.scenesSearchString);
    const [selectedScenes,setSelectedScenes] = useState(scenes);
    const dispatch = useDispatch();
    useEffect(() => {
        const noSearchRequired = (!searchString || searchString.length < 3);
        if (noSearchRequired)
            setSelectedScenes(scenes);
        const trigrams = generateExtendedTrigramPattern(searchString);
        const selection = scenes.filter(scene => {
            if (noSearchRequired)
                return true;
            let searchText = scene.name;
            scene.state.forEach((s, i) => {
                if (s !== null) {
                    searchText += categories[i].elements[s];
                }
            });
            const dist = trigramMatch(trigrams, generateTrigramSearchString(searchText));
            if (dist >= 0.7)
                return true;
            else 
                return false;
        });
        setSelectedScenes(selection);
    }, [searchString, scenes, categories]);
    const sceneButtons = 
        selectedScenes.length > 0 ?
            selectedScenes.map((s, i) => <SceneButton key={i} scene={s} categories={categories} onClick={setState}/>)
            :
            scenes.length === 0 ?
               <div style={{color: 'var(--color-foreground-hinted)'}}>This atmosphere has no scenes.</div>
               :
               <div style={{color: 'var(--color-foreground-hinted)'}}>No matching scenes found.</div>;
    /*(
        <div style={{display: 'flex'}}>
            <h4>Predefined Scenes</h4>
            <div style={{flexGrow: '1'}}/>
            <TooltipIcon text='' size='15' weight='bold' position='sw'>Scenes that are defined by the atmosphere.</TooltipIcon>
        </div>
    )*/
    return (
        <>
            <div style={{height: '16px'}}/>
            <TextBox 
                placeholder="Search"
                value={searchString}
                onChange={(e) => { dispatch(setSceneSearchString(e.target.value)); }}
                icon={<Icons.MagnifyingGlass/>}
            />            
            <div style={{height: '16px'}}/>            
            <div style={{
                position: 'relative',
                height: '100%'
            }}>
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                    overflow: 'auto'
                }} className='custom-scrollbar'>
                    { sceneButtons }
                </div>
            </div>
        </>
    );
}

function CustomSceneCreatorTag({ name, idx, selected, setSelected }) {
    const selectionIcon = selected ? 
        (<Icons.X/>)
        :
        (<Icons.Check/>);
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            color: selected ? 'var(--color-foreground)' : 'var(--color-foreground-inactive)',
            font: 'var(--font-body-medium)',
            padding: '2px 8px', 
            backgroundColor: selected ? 'var(--color-bg-elem-dp1-dk)' : 'var(--color-bg-elem-dp1-dk-glass)',
            textDecoration: selected ? '' : 'line-through',
            borderRadius: '100px'}}
        >
            { name }
            <div style={{width: '8px'}}/>
            <div className={'custom-scene-creator-tag-select-btn' + (selected ? ' selected' : '')} onClick={() => setSelected(idx, !selected)}>
                { selectionIcon }
            </div>
        </div>
    );
}

function CustomSceneCreator({ open, setOpen, create }) {
    const [name, setName] = useState('');
    const categories = useSelector(state => state.webPlayer.atmosphere.categories);
    const currentState = useSelector(state => state.webPlayer.atmosphere.currentState);
    const [selected, setSelected] = useState(currentState.map(e => true));
    const tagSelection = (i, s) => {
        const newSelection = [...selected];
        newSelection[i] = s;
        setSelected(newSelection);
    };
    const tags = currentState.map((e, i) => {
            const name = categories[i].elements[e[0]][0] + (e[1] === 0 ? '' :
                ' (' + categories[i].elements[e[0]][e[1]] + ')');
            return <CustomSceneCreatorTag 
                        key={i} 
                        name={name} 
                        idx={i} 
                        selected={selected[i]} 
                        setSelected={tagSelection}/>
        }
    );
    const selectedState = selected.map((e, i) => e ? currentState[i] : null);
    const selectedTagsCount = selectedState.filter(e => e !== null).length;
    const onClose = () => {
        setName("");
        setSelected(currentState.map(e => true));
        setOpen(false);
    }
    const onCreate = () => {
        create({ "sv": 3, "name": name, "state": selectedState });
        onClose();
    };
    return (
        <div className={'custom-scene-creator' + (open ? '' : ' closed')}>
            <div style={{display: 'flex'}}>
                <div style={{font: 'var(--font-body-medium)', fontWeight: 'bold'}}>
                    Custom Scene Creator
                </div>
                <div style={{flexGrow: 1}}/>
                <div className='custom-scene-creator-close-btn' onClick={onClose}>
                    <Icons.XCircle size='18'/>
                </div>
            </div>
            <TextBox 
                placeholder="Custom Scene Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <div style={{height: '8px'}}/>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '4px'}}>
                {tags}
            </div>
            <div style={{height: '8px'}}/>
            <OutlinedButton
                deactivated={selectedTagsCount === 0 || name.length === 0}
                onClick={onCreate}
                style={{
                    font: 'var(--font-body-medium)', 
                    padding: '6px 12px', 
                    width: '100%'
            }}>
                Save
            </OutlinedButton>
        </div>
    );
}

function CustomScenesList({ setState, categories }) {
    const scenes = useSelector(state => state.webPlayer.atmosphere.userDefinedScenes);
    const searchString = useSelector(state => state.webPlayer.guiState.customScenesSearchString);
    const [selectedScenes,setSelectedScenes] = useState(scenes);
    const [creatorOpen,setCreatorOpen] = useState(false);
    const [createButtonActive,setCreateButtonActive] = useState(true);
    const dispatch = useDispatch();
    useEffect(() => {
        if (creatorOpen || scenes.length >= 5)
            setCreateButtonActive(false);
        else
            setCreateButtonActive(true);
    }, [creatorOpen, scenes])
    useEffect(() => {
        const noSearchRequired = (!searchString || searchString.length < 3);
        if (noSearchRequired)
            setSelectedScenes(scenes);
        const trigrams = generateExtendedTrigramPattern(searchString);
        const selection = scenes.filter(scene => {
            if (noSearchRequired)
                return true;
            let searchText = scene.name;
            scene.state.forEach((s, i) => {
                if (s !== null) {
                    searchText += categories[i].elements[s];
                }
            });
            const dist = trigramMatch(trigrams, generateTrigramSearchString(searchText));
            if (dist >= 0.7)
                return true;
            else 
                return false;
        });
        setSelectedScenes(selection);
    }, [searchString, scenes, categories]);
    const onCustomSceneCreate = (scene) => {
        const newScenes = structuredClone(scenes);
        newScenes.push(scene);
        webPlayerContext.setCustomScenes(newScenes);
    };
    const onCustomSceneDelete = (idx) => {
        const newScenes = scenes.filter((e, i) => i !== idx);
        webPlayerContext.setCustomScenes(newScenes);
    };
    const sceneButtons = 
        selectedScenes.length > 0 ?
            selectedScenes.map((s, i) => <CustomSceneButton key={i} scene={s} categories={categories} onClick={setState} idx={i} onRemove={onCustomSceneDelete}/>)
            :
            scenes.length === 0 ?
               <div style={{color: 'var(--color-foreground-hinted)'}}>No custom scenes defined. Click the button to create one.</div>
               :
               <div style={{color: 'var(--color-foreground-hinted)'}}>No matching custom scenes found.</div>;
    /*(
        <div style={{display: 'flex'}}>
            <h4>Custom Scenes</h4>
            <div style={{flexGrow: '1'}}/>
            <TooltipIcon text='' size='15' weight='bold' position='nw'>
                You can create up to five new custom scenes by clicking on the 'Create' button.
            </TooltipIcon>
        </div>
    )*/
    return (
        <>
            <div style={{height: '16px'}}/>     
            <div style={{position: 'relative', width: '100%', height: '0px'}}>
                <CustomSceneCreator open={creatorOpen} setOpen={setCreatorOpen} create={onCustomSceneCreate}/>
            </div>
            <DefaultButton style={{width: '100%'}} deactivated={!createButtonActive} onClick={() => setCreatorOpen(true)}>
                Create ({scenes.length}/5)
            </DefaultButton> 
            <div style={{height: '16px'}}/>
            <TextBox 
                placeholder="Search"
                value={searchString}
                onChange={(e) => { dispatch(setCustomSceneSearchString(e.target.value)); }}
                icon={<Icons.MagnifyingGlass/>}
            />   
            <div style={{height: '16px'}}/>            
            <div style={{
                position: 'relative',
                height: '100%'
            }}>
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                    overflow: 'auto'
                }} className='custom-scrollbar'>
                    { sceneButtons }
                </div>
            </div>
        </>
    );
}

function ScenesMenu() {
    const [selectedTab, selectTab] = useState(0);
    const categories = useSelector(state => state.webPlayer.atmosphere.categories);
    const setState = (state) => {
        webPlayerContext.setScene(state);
    };
    const tabPredefined = {
        header: <div style={{textAlign: 'center', fontWeight: selectedTab === 0 ? 'bold' : 'normal'}}>Predefined</div>,
        body: <PredefinedScenesList setState={setState} categories={categories}/>
    };
    const tabCustom = {
        header: <div style={{textAlign: 'center', fontWeight: selectedTab === 1 ? 'bold' : 'normal'}}>Custom</div>,
        body: <CustomScenesList setState={setState} categories={categories}/>
    }
    return (
        <div className='side-menu-content' style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
            <h4>Scenes</h4>
            <SideMenuInfoWidged>
                Use predefined or custom scenes to quickly change between states.
            </SideMenuInfoWidged>
            <div style={{width: '100%', height: '1px', margin: '8px 0px', background: 'var(--color-bg-elem-dp1)'}}/>
            <SimpleTabRegister
                style={{display: 'flex', flexDirection: 'column', height: '100%'}}
                selected={selectedTab}
                select={selectTab}
                tabs={[tabPredefined, tabCustom]}
            />
        </div>
    );
    /*
    return (
        <div className='side-menu-content' style={{display: 'flex', flexDirection: 'column'}}>
            <PredefinedScenesList setState={setState} categories={categories}/>
            <div style={{width: '100%', height: '1px', margin: '16px 0px', background: 'var(--color-bg-elem-dp2-lg)', flexShrink: '0'}}/>
            <CustomScenesList setState={setState} categories={categories}/>
        </div>
    );
    */
}

function Sidebar({ setRetracted }) {
    const openSideMenu = useSelector((state) => state.webPlayer.guiState.openSideMenu);
    const sidebarAreaClass = openSideMenu === null ? 
        'sidebar-area retracted' :
        'sidebar-area';
    useEffect(() => {
        setRetracted(openSideMenu === null);
    }, [openSideMenu, setRetracted]);
    const selectSideMenu = (open) => {
        if (open === SIDE_MENU.volume) {
            return <VolumeSideMenu/>;
        }
        else if (open === SIDE_MENU.artists) {
            return <ArtistSideMenu/>;
        }
        else if (open === SIDE_MENU.scenes) {
            return <ScenesMenu/>;
        }
        else {
            return <></>;
        }
    };
    const sideMenuComp = selectSideMenu(openSideMenu);
    return (
        <div className={sidebarAreaClass}>
            <div className='sidebar'>
                <div className='side-menu-content' style={{display: 'flex', flexDirection: 'column'}}>
                    { sideMenuComp }
                </div>
            </div>
            <div className='sidebar-stack'>
                <SideMenuButton sideMenu={SIDE_MENU.volume}/>
                <SideMenuButton sideMenu={SIDE_MENU.scenes}/>
                <SideMenuButton sideMenu={SIDE_MENU.artists}/>
                <div style={{flexGrow: 1}}/>
                <SideMenuButton sideMenu={SIDE_MENU.exit}/>
                <div style={{height: '4px'}}/>
            </div>
        </div>
    );
}

function MockupInfo() {
    return (
        <div className='mockup-info'>
            <Icons.Info size={18}/>
            <div style={{width: '8px'}}/>
            <span>Mockup atmospheres do not have sound.</span>
        </div>
    );
}

function SplashScreen({ meta, onClose }) {
    const mockupText = meta.types.includes('mockup') ?
        <span style={{color: 'var(--color-foreground-hinted)'}}>
            This is a mockup of an atmosphere.
            Its only purpose is to showcase how different atmospheres can look. 
            <br/>
        </span> :
        <></>;
    const descriptionText = meta.desc !== '' ?
        meta.desc.map((d, i) => <span key={i}>{d}</span>) :
        <span style={{color: 'var(--color-foreground-hinted)'}}>No description available</span>
    const tags = (meta.tags.length > 0) ? 
        meta.tags.map((t, i) => <XlTag key={i} tag={t}/>) :
        <span style={{color: 'var(--color-foreground-hinted)'}}>No tags</span>
    return (
        <div className='web-player-splash-container'>
            <div style={{flexGrow: 1}}/>
            <div className='web-player-splash-widget'>
                <div className='atmosphere-title-container'>
                    <img className='atmosphere-title-thumb' 
                        src={getThumbnailURL(meta.thumbnail_path)} 
                        alt=''
                        onError={(e) => {
                            e.target.src = NullImg
                        }}/>
                    <div className='atmosphere-title-info'>
                        <h2>{meta.name}</h2>
                        <span style={{color: 'var(--color-foreground-hinted)'}}>By {meta.creator}</span>
                    </div>
                </div>
                <div className='info-container custom-scrollbar'>
                    <div className='tag-container'>
                        { tags }
                    </div>
                    <div className='text'>
                        { mockupText }
                        { descriptionText }
                    </div>
                </div>
                <DefaultButton style={{width: '100%'}} onClick={onClose}>Begin</DefaultButton>
            </div>
            <div style={{flexGrow: 1}}/>
        </div>
    );
}

function Atmosphere() {
    const meta = useSelector((state) => state.webPlayer.atmosphereMeta);
    const atmosphere = useSelector((state) => state.webPlayer.atmosphere);
    const showSplash = useSelector((state) => state.webPlayer.guiState.showSplash);
    const [sidebarRetracted, setSidebarRetracted] = useState(false);
    const background = meta.image_path === '' ?
        <AnimatedBackground filter='blur(4px) opacity(50%)'/> :
        <AnimatedBackgroundURL imageUrl={getImageURL(meta.image_path)} filter='blur(4px) opacity(50%)'/>;
    const atmContainerClass = 'atmosphere-container' + (sidebarRetracted ? '' : ' non-exclusive');
    const transitionFix = 'sidemenu-transition-fix' + (sidebarRetracted ? '' : ' non-exclusive');
    const mockupInfo = meta.types.includes('mockup') ?
        <MockupInfo/> :
        <></>;
    const setState = (c, e, v) => {
        webPlayerContext.updateState(c, e, v);
    }
    const dispatch = useDispatch();
    const onSplashClose = async () => {
        dispatch(setShowSplash(false));
        await webPlayerContext.start();
    }
    const splashScreen = showSplash ? 
        <SplashScreen meta={meta} onClose={onSplashClose}/> : 
        <></>;
    return (
        <>
            {background}
            <div className='web-player'>
                <div className={transitionFix}/>
                <div className={atmContainerClass}>
                    <div className='atmosphere-title-container'>
                        <img className='atmosphere-title-thumb' 
                            src={getThumbnailURL(meta.thumbnail_path)} 
                            alt=''
                            onError={(e) => {
                                e.target.src = NullImg
                            }}/>
                        <div className='atmosphere-title-info'>
                            <h2>{meta.name}</h2>
                            <span style={{color: 'var(--color-foreground-hinted)'}}>By {meta.creator}</span>
                        </div>
                    </div>
                    {mockupInfo}
                    <div className='atmosphere-matrix-container'>
                        <AtmosphereMatrix 
                            categories={atmosphere.categories} 
                            currentState={atmosphere.currentState}
                            setState={setState}/>
                    </div>
                    <div style={{flexGrow: 1}}/>
                    <Footer/>
                </div>
                <Sidebar setRetracted={setSidebarRetracted}/>
            </div>
            {splashScreen}
        </>
    );
}

export default function WebPlayerPage() {
    const atmosphereMeta = useSelector((state) => state.webPlayer.atmosphereMeta);
    const atmosphereId = useSelector((state) => state.webPlayer.atmosphere.id);
    const atmosphereValid = useSelector((state) => state.webPlayer.atmosphere.valid);
    const { atm_id } = useParams();
    const contextTab = useContextTab(CONTEXT_ID.webPlayer, '/atmosphere/' + atm_id);
    const [playerState, setPlayerState] = useState(webPlayerState.loading_atmosphere_meta);
    const [errorMsg, setErrorMsg] = useState('');
  
    const fileNotFoundHandler = useCallback(() => {
        setErrorMsg("Atmosphere not found.");
        setPlayerState(webPlayerState.error);
    }, []);
    const permissionDeniedHandler = useCallback(() => {
        setErrorMsg("Access denied. You lack permission to use this atmosphere.");
        setPlayerState(webPlayerState.error);
    }, []);
    const unknownErrorHandler = useCallback(() => {
        setErrorMsg("An unknown error occured. Please try again later.");
        setPlayerState(webPlayerState.error);
    }, []);

    useEffect(() => {
        if (atmosphereMeta?.id !== parseInt(atm_id)) {
            const successHandler = (meta) => {
                webPlayerContext.openAtmosphereFromWebPlayer(meta);
                setPlayerState(webPlayerState.loading_atmosphere);
            };
            getAtmosphereMeta(
                atm_id,
                successHandler,
                fileNotFoundHandler,
                permissionDeniedHandler,
                unknownErrorHandler);
        }
        else if (playerState === webPlayerState.loading_atmosphere_meta) {
            setPlayerState(webPlayerState.loading_atmosphere);
        }
    }, [
        atmosphereMeta, 
        atm_id, 
        playerState, 
        fileNotFoundHandler,
        permissionDeniedHandler,
        unknownErrorHandler]);

    useEffect(() => {
        if (playerState === webPlayerState.loading_atmosphere) {
            if (atmosphereId !== parseInt(atm_id)) {
                const successHandler = (atm) => {
                    webPlayerContext.setAtmosphere({ id: parseInt(atm_id), ...atm });
                    setPlayerState(webPlayerState.loaded);
                };
                getAtmosphere(
                    atm_id,
                    successHandler,
                    fileNotFoundHandler,
                    permissionDeniedHandler,
                    unknownErrorHandler);
            }
            else {
                setPlayerState(webPlayerState.loaded);
            }
        }
    }, [
        playerState, 
        atmosphereId, 
        atm_id,
        fileNotFoundHandler,
        permissionDeniedHandler,
        unknownErrorHandler]);

    useEffect(() => {
        if (playerState === webPlayerState.loaded && !atmosphereValid) {
            setErrorMsg('Corrupted atmosphere file.');
            setPlayerState(webPlayerState.error);
        }
    }, [atmosphereValid, playerState])

    useEffect(() => {
        if (playerState === webPlayerState.loading_atmosphere_meta || playerState === webPlayerState.loading_atmosphere) {
            const id = parseInt(atm_id);
            if (id !== undefined && id === atmosphereId && id === atmosphereMeta?.id) {
                contextTab.setTitle(atmosphereMeta.name);
                contextTab.setThumbnail(atmosphereMeta.thumbnail_path);
                document.title = 'Ambient Sphere | ' + atmosphereMeta.name;
            }
            else {
                contextTab.setTitle('Loading...');
                document.title = 'Ambient Sphere';
            }
        }
        else if (playerState === webPlayerState.error) {
            contextTab.setTitle('Error');
            document.title = 'Ambient Sphere';
        }
        else {
            contextTab.setTitle(atmosphereMeta.name);
            document.title = 'Ambient Sphere | ' + atmosphereMeta.name;
            webPlayerContext.loadCustomScenes();
        }
    }, [atmosphereMeta, contextTab, playerState, atm_id, atmosphereId]);

    const selectForegroundComponent = () => {
        if (playerState === webPlayerState.error) {
            return <ErrorMessage message={errorMsg}/>;
        }
        else if (playerState === webPlayerState.loaded) {
            return <Atmosphere/>
        }
        else {
            return <LoadingMessage/>;
        }
    }
    const foregroundComponent = selectForegroundComponent();
    return (
        <div className='web-player-page-container'>
            { foregroundComponent }
        </div>
    );
}