import NewsContentWrapper from '../../../../components/hub/news_content_wrapper';
import SocialLinks from '../../../../components/social-links/social_links';
import useNewsMetaList from '../../../../../hooks/hub/useNewsMetaList';
import { Info as InfoIcon } from '@phosphor-icons/react';
import './home.css';

export default function HubSubpageHomeWaitinglist() {
    const [newsLoading, news, newsError] = useNewsMetaList();
    const newsContainer = <NewsContentWrapper loading={newsLoading} news={news} error={newsError}/>;
    return (
        <div className='hub-subpage-home'>
            
            <div style={{
                boxSizing: 'border-box',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'var(--color-bg-elem-dp1-dk-glass)',
                margin: '16px 0px',
                padding: '16px',
                borderRadius: '32px'
            }}>
                <div style={{
                    maxWidth: '960px',
                    marginLeft: '24px'
                }}>
                    <h2 style={{textAlign: 'center'}}>Alpha Waitinglist</h2>
                    <p>Welcome back, Storyteller!</p>
                    <p>
                        Thank you for showing interest into Ambient Sphere.
                        For now, access to the alpha version of Ambient Sphere is limited.
                        Unfortunately, your account currently lacks alpha access.
                    </p>
                    <p>
                        For now, you can use the Hub to read up on the latest Ambient Sphere news.
                        You can also participate in our user surveys, if you haven't done so yet.
                    </p>
                    <div className='info-box'>
                        <div className='info-icon'>
                            <InfoIcon size={20}/>
                        </div>
                        <div className='info-desc'>
                            <span>
                                Note that survey participation increases your chances of being selected for alpha access earlier.
                            </span>
                        </div>
                    </div>
                    <p>
                        Make sure to also join our growing community over on <a href='https://discord.gg/NQyKcaS7CW' target='_blank' rel='noreferrer'>Discord</a> and check out our social media.
                    </p>
                    <SocialLinks style={{justifyContent: 'center'}} size={32}/>
                    <p>
                        Until then<br/>
                        ~ the party
                    </p>
                </div>
            </div>
            <h3>News</h3>
            { newsContainer }
        </div>
    );
}