import './textboxes.css'

 function BaseTextBox({
    className,
    style,
    id,
    type,
    onChange,
    active = true,
    value,
    labelText,
    placeholder,
    autoComplete,
    invalid = false,
    invalidText = null,
    icon = null,
    required = false
    })
{
    const textboxClassName = className + (icon ? ' show-icon' : '') +
        (active ? (invalid ? ' invalid' : '') : ' deactivated');
    const textboxLabelClassName = 'textbox-label' +  
        (active ? (invalid ? ' invalid' : '') : ' deactivated');
    
    const hint = !invalid || invalidText === null || !active ? <></> : (
        <div className='textbox-invalid-label'>
            {invalidText}
        </div>);

    const iconClassName = 'textbox-leading-icon' + (active ? '': ' deactivated');

    const label = labelText? (<label className={textboxLabelClassName} htmlFor={id}>{labelText}</label>) : <></>

    const iconDiv = icon? <div className={iconClassName}> {icon} </div> : <></>;

    return(
        <div style={{display: 'flex', flexDirection: 'column', ...style}}>
            {label}
            <div style={{position: 'relative'}}>
                {iconDiv}
                <input
                    required={required}
                    style={{width: '100%'}}
                    className={textboxClassName}
                    id={id}
                    type={type}
                    value={value}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    disabled={!active}
                    onChange={active? onChange : () => {}}/>
            </div>
            {hint}
        </div>
    );
}

function BaseTextArea({
    className,
    style,
    id,
    type,
    onChange,
    active = true,
    value,
    labelText,
    placeholder,
    invalid = false,
    invalidText = null,
    icon = null,
    required = false
    }){

    const textboxClassName = className + ' custom-scrollbar' + (icon ? ' show-icon' : '') +
        (active ? (invalid ? ' invalid' : '') : ' deactivated');
    const textboxLabelClassName = 'textbox-label' +  
        (active ? (invalid ? ' invalid' : '') : ' deactivated');
    
    const hint = !invalid || invalidText === null || !active ? <></> : (
        <div className='textbox-invalid-label'>
            {invalidText}
        </div>);

    const label = labelText? (<label className={textboxLabelClassName} htmlFor={id}>{labelText}</label>) : <></>
    return(
        <div style={{display: 'flex', flexDirection: 'column', ...style}}>
            {label}
            <div style={{position: 'relative'}}>
                <textarea
                    required={required}
                    style={{width: '100%'}}
                    className={textboxClassName}
                    id={id}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    disabled={!active}
                    onChange={active? onChange : () => {}}/>
            </div>
            {hint}
        </div>
    );
}

export function TextBox({
    style,
    id,
    type,
    onChange,
    active = true,
    value,
    labelText,
    placeholder,
    autoComplete,
    invalid = false,
    invalidText = null,
    icon = null,
    required = false,
    mulitline = false}){
    const className = 'textbox';
    const TextField = mulitline ? 
        <BaseTextArea className={className} style={style} id={id} type={type} onChange={onChange} active={active} value={value} 
        labelText={labelText} placeholder={placeholder} invalid={invalid} invalidText={invalidText} icon={icon} required={required}/>
        :
        <BaseTextBox className={className} style={style} id={id} type={type} onChange={onChange} active={active} value={value}
        labelText={labelText} placeholder={placeholder} autoComplete={autoComplete} invalid={invalid} invalidText={invalidText} icon={icon} required={required}/>
        
    return(
        <>
            {TextField}
        </>
    )
}

export function TextBoxWide({
    style,
    id,
    type,
    onChange,
    active = true,
    value,
    labelText,
    placeholder,
    autoComplete,
    invalid = false,
    invalidText = null,
    icon = null,
    required = false}){
    const className = 'textbox wide'
    return(
        <BaseTextBox className={className} style={style} id={id} type={type} onChange={onChange} active={active} value={value}
            labelText={labelText} placeholder={placeholder} autoComplete={autoComplete} invalid={invalid} invalidText={invalidText} icon={icon} required={required}>
        </BaseTextBox>
    )
}

const TextBoxes = {
    Default: TextBox,
    Wide: TextBoxWide
}
export default TextBoxes;