import AtmosphereContentWrapper from '../../../../components/hub/atmosphere_content_wrapper';
import LoadingWidget from '../../../../components/loading-widget/loading_widget';
import useAtmosphereMetaList from '../../../../../hooks/hub/useAtmosphereMetaList';
import { Info as InfoIcon } from '@phosphor-icons/react';
import './search.css';

export default function HubSubpageSearch() {
    const [atmLoading, loadedAtmospheres, atmError] = useAtmosphereMetaList();
    if (atmLoading) {
        return <LoadingWidget/>;
    }    
    const normalAtms = <AtmosphereContentWrapper loading={atmLoading} atmospheres={loadedAtmospheres?.normal} error={atmError}/>;
    const mockupAtms = <AtmosphereContentWrapper loading={atmLoading} atmospheres={loadedAtmospheres?.mockup} error={atmError}/>;
    return (
        <div className='hub-subpage-search'>
            <h4 style={{color: 'var(--color-foreground-hinted)'}}>Coming Soon</h4>
            <div className='info-box'>
                <div className='info-icon'>
                    <InfoIcon size={20}/>
                </div>
                <div className='info-desc'>
                    <span>
                        This is the search area.
                        This is where you'll be able to browse the atmospheres found on Ambient Sphere, including those published by other users.
                    </span>
                    <span>
                        During the alpha phase, we'll just show you all atmospheres here, functioning and mockups alike.
                    </span>
                </div>
            </div>
            <h3>Alpha Atmospheres</h3>
            <div className='atm-container'>
                { normalAtms }  
            </div>
            <h3>Mockup Atmospheres</h3>
            <div className='atm-container'>
                { mockupAtms }  
            </div>
        </div>
    );
}