import useSurveyMetaList from '../../../../hooks/hub/useSurveyMetaList';
import LoadingWidget from '../../../components/loading-widget/loading_widget';
import Survey from '../../../components/survey/survey';
import Footer from '../../../../components/shared/footer/footer';
import { AnimatedBackground } from '../../../../components/shared/animated-background/animated_background';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PAGE_ROUTES } from '../../../../constants/page_routes';
import { useDispatch } from 'react-redux';
import { setSurveyMeta } from '../../../../store/slices/hub';
import { postSurvey } from '../../../../api/user';
import './survey.css';

function SurveyMessage({style, children}) {
    return (
        <div className='survey-message-container'>
            <div style={{background: 'var(--color-bg-elem-dp1-glass', padding: '24px', borderRadius: '24px'}}>
                {children}
                <Link to={PAGE_ROUTES.main}>Back to hub</Link>
            </div>
        </div>
    );
}

function SurveyWrapper({ loading, survey, error, submitted, onSubmit }) {
    if (submitted)
        return <SurveyMessage><div>Thank you for your participation.</div></SurveyMessage>
    else if (loading)
        return <LoadingWidget centered={true}/>;
    else if (error !== null)
        return <SurveyMessage><div style={{color: 'var(--color-error)'}}>{error}</div></SurveyMessage>;
    else if (survey === null)
        return <SurveyMessage><div style={{color: 'var(--color-foreground-hinted)'}}>Requested Survey No Longer Exists</div></SurveyMessage>;
    else if (survey.submitted)
        return <SurveyMessage><div>You've already participated in this survey.</div></SurveyMessage>
    else
        return <div className='survey-page'><Survey skeleton={JSON.parse(survey.skeleton)} onSubmit={onSubmit}/></div>;
}

export default function SurveyPage() {
    const { survey_id } = useParams();
    const [loading, surveys, loadError] = useSurveyMetaList();
    const [submitError, setSubmitError] = useState(null);
    const [survey, setSurvey] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        document.title = 'Ambient Sphere' + (survey !== null ? ' | ' + survey.name : '');
    }, [survey]);
    useEffect(() => {
        if (surveys !== null) {
            if (survey_id in surveys) {
                setSurvey(surveys[survey_id]);
            }
            else {
                setSurvey(null);
            }
        }
    }, [surveys, survey_id]);
    const dispatch = useDispatch();
    const onSubmit = (values) => {
        const onSuccess = () => {
            const newSurveys = structuredClone(surveys);
            newSurveys[survey_id].submitted = true;
            dispatch(setSurveyMeta(newSurveys));
            setSubmitted(true);
        };
        const onPermissionDenied = () => {
            setSubmitError('Failed to submit survey: Permission denied.');
        };
        const onUnnownError = () => {
            setSubmitError('Failed to submit survey: Unknown error.');
        };
        postSurvey(survey.survey_id, values, onSuccess, onPermissionDenied, onUnnownError);
    };
    const error = submitError ? submitError : loadError ? 'Could not load survey: ' + loadError : null;
    return (
        <>
            <AnimatedBackground filter='blur(8px) opacity(66%)'/>
            <div className='survey-page-container'>
                <SurveyWrapper loading={loading} survey={survey} error={error} submitted={submitted} onSubmit={onSubmit}/>
                <Footer/>
            </div>
        </>
    );
}