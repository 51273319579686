const cfgSampleRate = 48000;
const cfgBufferLength = 0.04;
const cfgBufferChannels = 2;
const cfgSampleSize = 4;

export const AUDIO_CONFIG = {
    sampleRate: cfgSampleRate,
    bufferQueueLength: 16,
    bufferLength: cfgBufferLength,
    bufferChannels: cfgBufferChannels,
    sampleSize: cfgSampleSize,
    frameSize: cfgSampleSize * cfgBufferChannels,
    channelSize: cfgSampleSize * (cfgBufferLength * cfgSampleRate)
};

export const ENGINE_CONFIG = {
    cacheSizeLimit: 64 * 1024 * 1024, // bytes
    cacheObjLimit: 2048
}