export const BACKEND_API_BASE_URL = process.env.NODE_ENV === 'production' ?
      'https://as-edge.ambientsphere.com:5000'
    : 'http://localhost:5000';

export const ANALYTICS_API_BASE_URL = process.env.NODE_ENV === 'production' ?
      'https://as-edge.ambientsphere.com:5002'
    : 'http://localhost:5002';

export const CMS_API_BASE_URL = 'https://cms.ambientsphere.com';
export const CMS_API_KEY = 'API-0d2c5407edb4d7709e06fe8a586bff670531155f';

export const API_HEARTBEAT_INTERVAL = 20; // minutes
export const API_POST_PLAYTIME_TOKEN_INTERVAL = 30; // minutes