import axios from "axios";

export function newsletterPostSignup(email) {
    axios.post(
        'https://ambientsphere.com/lists/?p=asubscribe&id=1', 
        {
            'email': email,
            'htmlemail': 1,
            'list%5B2%5D': 'signup',
            'subscribe':  'subscribe'
        },
        {
            headers: {
                'accept': 'text/html, */*; q=0.01',
                'content-type': 'application/x-www-form-urlencoded'
            }
        });
}