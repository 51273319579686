import { AudioQueue } from "./audio_queue";
import { getAudioMeta, getAudioChunk, getPlaygraph as apiGetPlaygraph } from "../../api/data";
import { ENGINE_CONFIG } from "../../constants/audio";

const getSource = (sourceId, cbSuccess, cbError) => {
    getAudioMeta(sourceId, cbSuccess, cbError, cbError, cbError);
}

const getChunk = (chunkId, cbSuccess, cbError) => {
    const onSuccess = (c) => {
        cbSuccess(c, true);
    };
    getAudioChunk(chunkId, onSuccess, cbError, cbError, cbError);
}

const getPlaygraph = async (playgraphId) => {
    let content = null;
    const onSuccess = (c) => {
        content = c;
    }
    const onError = () => {
        content = null;
    }
    await apiGetPlaygraph(playgraphId, onSuccess, onError, onError, onError).requestPromise;
    return content;
}

// API method selector constants for message passing
const mpiApi_create = 'api_c';
const mpiApi_resetPlaybacks = 'api_rp';
const mpiApi_setPbPlaygraph = 'api_spp';
const mpiApi_setPbSilence = 'api_sps';
//const mpiApi_setPbVolume = 'api_spv';
//const mpiApi_setMetaDataChangeNotification = 'api_smdcn';
const mpiApi_queryBuffer = 'api_qb';
//const mpiApi_supplyBuffer = 'api_sb';
const mpiApi_respondNetworkError = 'api_rne';
const mpiApi_respondChunkRequest = 'api_rcr';
const mpiApi_respondSourceInfoRequest = 'api_rsir';

// Callback selector constants for message passing
const mpiCb_supplyBuffer = 'cb_sb';
//const mpiCb_supplyMixedBuffer = 'cb_smb';
const mpiCb_bufferQueryNotify = 'cb_bqn';
//const mpiCb_bufferQueryAbort = 'cb_bqa';
const mpiCb_netRequestSourceInfo = 'cb_nrsi';
const mpiCb_netRequestAudioChunk = 'cb_nrac';
const mpiCb_reportRenderProfiling = 'cb_rrp';
const mpiCb_reportError = 'cb_re';
const mpiCb_reportInfo = 'cb_ri';
const mpiCb_reportActiveTrackMetaData = 'cb_ratmd';

// Callback selector constant for worker specific messages
const workerCb_webAssemblyInitialized = "wcb_wai";

// Worker URL
const workerURL = `${process.env.PUBLIC_URL}/workers/web_playback.worker.js`;

export class PlaybackContext {
    constructor() {
        this.worker = null;
        this.created = false;
        this.cbMetaData = null;

        this.masterVolume = 1.0;        
        this.playbackQueues = [];
        this.playbackVolume = [];
        this.playbackPlaygraph = [];

        this.playbackQueried = [];
        this.pending = false;

        this.deferredApiCalls = [];
        this.apiInitialized = false;
    }
    
    // ========================================================================
    // Public interface
    reset(numPlaybacks) {
        //console.log('web_playback.reset');
        if (!this.apiInitialized) {
            if (this.worker === null) {
                this.worker = new Worker(workerURL);
                this.worker.onmessage = this.#handleWorkerEvent.bind(this);
            }
            this.deferredApiCalls.push(this.reset.bind(this, numPlaybacks));
            return;
        }
        if (this.created === false) {
            this.#createWebPlayback();
            this.created = true;
        }
        this.playbackQueues.forEach((queue) => queue.terminate());
        this.worker.postMessage({
            selector: mpiApi_resetPlaybacks,
            numPlaybacks: numPlaybacks
        });
        this.masterVolume = 1.0;
        this.playbackQueues = [];
        this.playbackVolume = [];
        this.playbackPlaygraph = [];
        this.playbackQueried = [];
        for (let i = 0; i < numPlaybacks; ++i) {
            this.playbackQueues.push(new AudioQueue(this.#handlePlaybackQueryNext.bind(this, i)));
            this.playbackVolume.push(1.0);
            this.playbackPlaygraph.push(null);
            this.playbackQueried.push(0);
        } 
    }

    setMetaDataChangeCallback(cb) {
        //console.log('web_playback.setMetaDataChangeCallback');
        this.cbMetaData = cb;
    }

    async start() {
        //console.log('web_playback.start');
        if (!this.apiInitialized) {
            if (this.worker === null) {
                this.worker = new Worker(workerURL);
                this.worker.onmessage = this.#handleWorkerEvent.bind(this);
            }
            this.deferredApiCalls.push(this.start.bind(this));
            return;
        }
        for (var i in this.playbackQueues) {
            await this.playbackQueues[i].start();
        }
    }

    numPlaybacks() {
        //console.log('web_playback.numPlaybacks');
        return this.playbackQueues.length;
    }

    async setPlaygraph(playbackIdx, playgraphId) {
        //console.log('web_playback.setPlaygraph(' + playbackIdx + ', ' + playgraphId + ')');
        if (!this.apiInitialized) {
            if (this.worker === null) {
                this.worker = new Worker(workerURL);
                this.worker.onmessage = this.#handleWorkerEvent.bind(this);
            }
            this.deferredApiCalls.push(this.setPlaygraph.bind(this, playbackIdx, playgraphId));
            return;
        }
        if (playbackIdx >= this.playbackQueues.length) {
            return;
        }
        if (playgraphId === this.playbackPlaygraph[playbackIdx]) {
            return;
        }
        if (playgraphId === null) {
            this.worker.postMessage({
                selector: mpiApi_setPbSilence,
                idx: playbackIdx
            });
        }
        else {
            const content = await getPlaygraph(playgraphId);
            if (content === null) {
                this.worker.postMessage({
                    selector: mpiApi_setPbSilence,
                    idx: playbackIdx
                });
            }
            else {
                this.worker.postMessage({
                    selector: mpiApi_setPbPlaygraph,
                    idx: playbackIdx,
                    content: content
                });
            }
        }
        this.playbackPlaygraph[playbackIdx] = playgraphId;
    }

    setMasterVolume(volume) {
        //console.log('web_playback.setMasterVolume');
        this.masterVolume = volume;
        this.playbackQueues.forEach((queue, i) => { 
            queue.setVolume(this.masterVolume * this.playbackVolume[i]);
        });
    }

    setPlaybackVolume(playbackIdx, volume) {
        //console.log('web_playback.setPlaybackVolume');
        if (playbackIdx >= this.playbackQueues.length) {
            return;
        }
        this.playbackVolume[playbackIdx] = volume;
        this.playbackQueues[playbackIdx].setVolume(this.playbackVolume[playbackIdx] * this.masterVolume);
    }

    // ========================================================================
    // Private interface
    #handleWorkerEvent(event) {
        //console.log("web_playback.handleWorkerEvent(" + event.data.selector + ')');
        switch(event.data.selector) {
            case workerCb_webAssemblyInitialized:
                this.#apiInitialized();
                break;
            case mpiCb_supplyBuffer:
                this.#handleSupplyBuffer(event);
                break;
            case mpiCb_bufferQueryNotify:
                this.#queryFinished();
                break;
            case mpiCb_netRequestAudioChunk:
                this.#handleNetRequestAudioChunk(event);
                break;
            case mpiCb_netRequestSourceInfo:
                this.#handleNetRequestSourceInfo(event);
                break;
            case mpiCb_reportRenderProfiling:
                this.#handleReportRenderProfiling(event);
                break;
            case mpiCb_reportError:
                this.#handleReportError(event);
                break;
            case mpiCb_reportInfo:
                this.#handleReportInfo(event);
                break;
            case mpiCb_reportActiveTrackMetaData:
                this.#handleReportActiveTrackMetaData(event);
                break;
            default:
                break;
        }
    }

    #handleSupplyBuffer(event) {
        //console.log('web_playback.handleSupplyBuffer');
        if (event.data.playbackIdx >= this.playbackQueues.length) {
            return;
        }
        this.playbackQueues[event.data.playbackIdx].insertBuffer(event.data.bufferView);
        this.playbackQueried[event.data.playbackIdx]--;
    }

    #handleNetRequestAudioChunk(event) {
        //console.log('web_playback.handleNetRequestAudioChunk(' + event.data.requestId + ')');
        getChunk(
            event.data.chunkId, 
            this.#netResponseAudioChunk.bind(this, event.data.requestId),
            this.#netResponseError.bind(this, event.data.requestId));
    }

    #handleNetRequestSourceInfo(event) {
        //console.log('web_playback.handleNetRequestSourceInfo(' + event.data.requestId + ')');
        getSource(
            event.data.sourceId,
            this.#netResponseSourceInfo.bind(this, event.data.requestId),
            this.#netResponseError.bind(this, event.data.requestId));
    }

    #handleReportRenderProfiling(event) {
        //const avgTime = event.data.accumulatedTime / event.data.numSamples;
        //const budgetUse = (avgTime / 0.04) * 100;
        //console.log(
        //    "render report: \n" + 
        //    "    timeDelta: " + event.data.timeDelta + "\n" +
        //    "    numSamples: " + event.data.numSamples + "\n" +
        //    "    accumulatedTime: " + event.data.accumulatedTime + "\n" +
        //    "    minTime: " + event.data.minTime + "\n" +
        //    "    maxTime: " + event.data.maxTime + "\n" +
        //    "    avgTime: " + avgTime + "\n" +
        //    "    budgetUse: " + budgetUse + "%"
        //);
    }

    #handleReportError(event) {
        //console.log('web_playback.handleReportError');
        console.error("Web playback error: " + event.data.message);
    }

    #handleReportInfo(event) {
        //console.log('web_playback.handleReportInfo');
        console.log("Web playback info: " + event.data.message);
    }

    #handleReportActiveTrackMetaData(event) {
        //console.log('web_playback.handleReportActiveTrackMetaData');
        if (this.cbMetaData !== null) {
            this.cbMetaData(event.data.playbackIdx, event.data.meta);
        }
    }

    #handlePlaybackQueryNext(playbackIdx) {
        //console.log('web_playback.handlePlaybackQueryNext');
        this.worker.postMessage({
            selector: mpiApi_queryBuffer,
            idx: playbackIdx
        });
        
        //if (playbackIdx >= this.playbackQueues.length) {
        //    return;
        //}
        //this.playbackQueried[playbackIdx]++;
        //this.#queryBuffer();
    }

    #queryFinished() {
        //console.log('web_playback.queryFinished');
        this.pending = false;
        this.#queryBuffer();
    }

    #queryBuffer() {
        //console.log('web_playback.queryBuffer');
        if (this.pending) {
            return;
        }
        let c = 0;
        for (let i = 0; i < this.playbackQueues.length; ++i) {
            if (this.playbackQueried[i] > 0) {
                ++c;
            }
        }
        if (c === this.playbackQueues.length) {
            this.pending = true;
            this.worker.postMessage({
                selector: mpiApi_queryBuffer
            });
        }
    }

    #createWebPlayback() {
        //console.log('web_playback.createWebPlayback');
        this.worker.postMessage({
            selector: mpiApi_create,
            cacheSizeLimit: ENGINE_CONFIG.cacheSizeLimit,
            cacheObjLimit: ENGINE_CONFIG.cacheObjLimit,
            internalQueueSize: 0,
            supplyMixed: false
        });
    }

    #netResponseError(requestId) {
        //console.log('web_playback.netResponseError(' + requestId + ')');
        this.worker.postMessage({
            selector: mpiApi_respondNetworkError,
            id: requestId
        });
    }

    #netResponseSourceInfo(requestId, content) {
        //console.log('web_playback.netResponseSourceInfo');
        this.worker.postMessage({
            selector: mpiApi_respondSourceInfoRequest,
            id: requestId,
            content: content
        });
    }

    #netResponseAudioChunk(requestId, buffer, endOfStream) {
        //console.log('web_playback.netResponseAudioChunk(' + requestId + ')');
        this.worker.postMessage({
            selector: mpiApi_respondChunkRequest,
            id: requestId,
            endOfStream: endOfStream,
            arrayBuffer: buffer
        }, 
        [buffer]);
    }

    #apiInitialized() {
        //console.log('web_playback.apiInitialized');
        this.apiInitialized = true;
        this.deferredApiCalls.forEach((call) => call());
        this.deferredApiCalls = [];
    }
}